import { Component, AfterViewInit, ViewChild, OnInit, OnChanges, Inject, LOCALE_ID }        from '@angular/core';
import { ModalController, NavParams}                                                        from '@ionic/angular';
import { CalendarComponent }                                                                from 'ionic2-calendar';
import { environment, modelos_de_datos, slidemenu_options }                                 from 'src/environments/environment';
import { GeneralService }                                                                   from 'src/app/services/general.service';
import { DepuracionService }                                                                from 'src/app/services/depuracion.service';
import { bbddRegistrosHorariosService }                                                     from 'src/app/services/bbdd-registros-horarios.service';
import { ToastService }                                                                     from 'src/app/services/toast.service';
import { EditarRegistroHorarioPage }                                                        from 'src/app/pages/editar-registro-horario/editar-registro-horario.page';

import * as moment from 'moment';

@Component({
  selector: 'app-editar-registros-horarios',
  templateUrl: './editar-registros-horarios.page.html',
  styleUrls: ['./editar-registros-horarios.page.scss', '../../../calendar.scss'],
})
export class EditarRegistrosHorariosPage implements AfterViewInit {

    prmtr_Proposito_Seleccionar_Area:any;
    prmtr_usuario:any;
    title_usuario:any; //para guardar la información que muestro en el title sin modificar prmtr_usuario ya que si 
                       //doy de alta 2 veces un usuario nuevo la segunda dicha información modificada de prmtr_usuario
                       //apareceria modificada en el formulario
    slidemenu_option_elegida: any;
    calendario_modificado: boolean; //para controlar si está habilitado o no el botón de guardar

    miVariableHora: any;

    //-----------------------------------------------
    //propiedades necesarias para el calendario
    str_Anio: string;
    str_Mes: string;
    int_Semana_del_mes: number;
    str_Semana_del_anio: string;
    str_Dia: string;
    str_DiaSemana: string;

    array_horas_seleccionadas: [];
    //You might have noticed the strange modalReady variable already. 
    //I couldn’t make the calendar work correctly when deployed to a device, since I assume the view wasn’t rendered correctly and something internally broke.
    //By hiding it until the view has finished loading we can easily fix this, and in reality you actually won’t even notice it!
    modalReady = false;
    eventSource = [];
    conf_calendario = environment.conf_calendario; 



    @ViewChild(CalendarComponent) obj_calendario_registros_horarios: CalendarComponent; 


    constructor(
        private DepuracionService: DepuracionService
        , private GeneralService: GeneralService
        , private ModalController: ModalController
        , private NavParams: NavParams
        , private bbddRegistrosHorariosService: bbddRegistrosHorariosService
        , private ToastService: ToastService
        , @Inject(LOCALE_ID) private locale: string
        ) { 

        moment.locale('es');

        this.prmtr_Proposito_Seleccionar_Area = this.NavParams.get('prmtr_Proposito_Seleccionar_Area');
        this.DepuracionService.console_log("this.prmtr_Proposito_Seleccionar_Area", this.prmtr_Proposito_Seleccionar_Area,  "editar registros horarios")

        this.prmtr_usuario = this.NavParams.get('prmtr_usuario');
        this.DepuracionService.console_log("this.prmtr_usuario", this.prmtr_usuario,  "editar registros horarios")
        this.title_usuario = {...this.prmtr_usuario}; //para "clonar" el objeto y que sea uno nuevo
        this.DepuracionService.console_log("this.title_usuario", this.title_usuario,  "editar registros horarios")

        //para buscar un elemento de un array por una propiedad de los objetos de dicho elemento, encontrado en...
        this.slidemenu_option_elegida = slidemenu_options.find(element => element.id === 'registros horarios');
        this.DepuracionService.console_log("this.slidemenu_option_elegida", this.slidemenu_option_elegida,  "editar registros horarios")
        this.calendario_modificado = false;

        this.conf_calendario.mode = "month"

        }


    ngAfterViewInit() {
        setTimeout(() => {
            this.modalReady = true;      
            }, 0);

        //para hacer pruebas
        //this.createRandomEvents()        

        }


    onCurrentDateChanged_registros_horarios(event){
        //cargo las actividades existentes en este mes
        //this.conf_calendario.currentDate = event;
        this.conf_calendario.currentDate2 = event; //OJO: tengo que usar esta para evitar que la anterior provoque una recarga.
        this.calendario_modificado = false;
        //this.conf_calendario.loadEvents();
        this.DepuracionService.console_log("dentro de onCurrentDateChanged_registros_horarios", event, "editar calendarios");
        this.DepuracionService.console_log("dentro de onCurrentDateChanged_registros_horarios", event, "mostrar registros horarios");
        this.DepuracionService.console_log("this.conf_calendario.mode", this.conf_calendario.mode, "editar calendarios");
        this.DepuracionService.console_log("dentro de onCurrentDateChanged_registros_horarios", "", "cargar registros duplicado")
        //console.log (event);
        var strAnio, strMes, strDia, strSemana, strDiaSemana
        //con las siguientes lineas lo obtento de forma "starndar"
        /*
        strAnio = event.getFullYear();
        strMes = event.getMonth() + 1; //ya que enero = 0
        //this.Dia = event.getDay(); // ¿¿ no se porque no devuelve el dia actual ?? en cambio uso...
        strDia = event.getDate(); 
        */
        //pero como quiero maquetarlo lo obtengo con moment
        strAnio = moment(event).format('YYYY');
        strMes = moment(event).format('MMMM');
        strSemana = moment(event).format('w'); //semana del año
        strDia = moment(event).format('D');
        //strDiaSemana = moment(event).format('ddd'); 
        //strDiaSemana = moment(event).format('dd'); 
        strDiaSemana = moment(event).format('dd').toUpperCase(); 

        //console.log ( moment(event).format('LLLL')); //para mosrtrar la fecha completa y ver que lo pone en castellano
        //para evitar el error "ExpressionChangedAfterItHasBeenCheckedError"
        //parentizo con settimeout pero entonces hay problemas de accesso a this, que soluciono con lo encontrado aqui...
        //...https://stackoverflow.com/questions/2130241/pass-correct-this-context-to-settimeout-callback
        /*
        setTimeout(function(){ 
            this.str_Anio = strAnio
            }, 2000, this); //añado este this final para que dentro del settimeout se accdeda al correcto
        */  
        //...este anterior no va pero el siguiente si que va...
        //setTimeout(() => { ...codigo aqui... }, 200);
        setTimeout(() => { 
            this.str_Anio = strAnio 
            this.str_Mes = strMes
            this.str_Semana_del_anio = strSemana

            this.int_Semana_del_mes = this.GeneralService.Semana_del_Mes (event)
            
            //setTimeout(function(){ 
                //console.log ("cambio al día... " + strDia)
                this.str_Dia = strDia
                this.str_DiaSemana = strDiaSemana
            //    }
            //    , 3000);
    
            }, 0); //con poner 0 es suficiente para evitar el ExpressionChangedAfterItHasBeenCheckedError


        this.Cargar_RegistrosHorarios (event)

        //
        }

    async onRangeChanged_registros_horarios(event) {
        this.DepuracionService.console_log("onRangeChanged_registros_horarios", event, "mostrar registros horarios");
        this.DepuracionService.console_log("onRangeChanged_registros_horarios > currentDate", this.conf_calendario.currentDate, "mostrar registros horarios");
        this.DepuracionService.console_log("onRangeChanged_registros_horarios > currentDate2", this.conf_calendario.currentDate2, "mostrar registros horarios");
        this.Cargar_RegistrosHorarios (this.conf_calendario.currentDate2)
        }


    onTimeSelected_registros_horarios (event) {
        this.GeneralService.Mostrar_Dia_Seleccionado(event, this.conf_calendario)
        };



    onEventSelected(event) {
        this.DepuracionService.console_log("onEventSelected", event, "editar registros horarios 2")
        this.AbrirModal_Editar_RegistroHorario(event);

        }


    next() {
        this.obj_calendario_registros_horarios.slideNext();
        }
    
    back() {
        this.obj_calendario_registros_horarios.slidePrev();
        }

    close(prmtrDismiss) {
        this.ModalController.dismiss(prmtrDismiss);
        }

    //prmtrFecha: indica la fecha respecto a la cual que busca el limite inferior y susperior.
    Cargar_RegistrosHorarios (prmtrFecha) {
        //let strDia = moment(prmtrFecha).format(environment.format_date)
        var fechas_limite = this.GeneralService.Fechas_Limite_segun_tipo_vista(prmtrFecha, this.conf_calendario.mode)    
        this.bbddRegistrosHorariosService.getRegistrosHorarios (null, this.prmtr_usuario.COD_USUARIO, fechas_limite.date_inicio, fechas_limite.date_fin).subscribe(result => {
            this.DepuracionService.console_log("Cargar_RegistrosHorarios", result, "editar registros horarios")
            //this.GeneralService.Cargar_Calendar_eventSource (this.eventSource, result.data)

            //para que en la vista tipo mes muestre los registros horarios
            var array_registroshorarios = result.data
            array_registroshorarios.forEach(function (value, index, array) {
                value.ES_REGISTRO_HORARIO_a_contabilizar = true
                });

            setTimeout(() => {                
                this.eventSource = this.GeneralService.Formatear_Array_Calendar_EventSource(array_registroshorarios);
                }, 0);
            

            });
        



        }

    ion_datetime_change(event) {
        this.DepuracionService.console_log("ion_datetime_change", event, "editar registros horarios 2")
        }


    async AbrirModal_Editar_RegistroHorario (prmtrRegistroHorario) {
        this.DepuracionService.console_log("AbrirModal_Editar_RegistroHorario", prmtrRegistroHorario, "editar registros horarios 2")

        const modal = await this.ModalController.create({
            component: EditarRegistroHorarioPage,
            cssClass: 'editar-registro-horario',
            //backdropDismiss: false
            componentProps: {
                'prmtr_registro_horario': prmtrRegistroHorario
                , 'prmtr_usuario': this.prmtr_usuario
                }
        });
        
        await modal.present();
        
        modal.onDidDismiss().then((result) => {
            this.DepuracionService.console_log("modal.onDidDismiss().then((result)", result, "editar registros horarios 2")
            if (!this.GeneralService.EstaVacia(result.data)) {
                this.ToastService.show("El registro horario ha sido actualizado correctamente")
                this.Cargar_RegistrosHorarios (result.data.fecha_inicio) 
                }
            });
        }
    

    AbrirModal_Nuevo_RegistroHorario() {
        var nuevo_registro_horario = {...modelos_de_datos.registro_horario}

        var date_start, date_end
        //date_start = new Date();
        //No pongo la fecha de hoy sino la fecha del día que ha permitido abrir esta ventana de nuevo REgistro Horario
        date_start = this.conf_calendario.currentDate2;
        date_end = moment(date_start).add(1, 'hours').format(); 
        date_end = new Date(date_end); 

        nuevo_registro_horario.COD_USUARIO = this.prmtr_usuario.COD_USUARIO
        nuevo_registro_horario.COD_AREA = this.prmtr_usuario.COD_AREA
        nuevo_registro_horario.COD_REGISTRO = "NUEVO"
        nuevo_registro_horario.COD_TIPO_DE_HORA = null //por defecto la que es null, es decir "Elige una opción"
        nuevo_registro_horario.FECHAHORA_INICIO_REGISTRO = date_start
        nuevo_registro_horario.FECHAHORA_FIN_REGISTRO = date_end
        nuevo_registro_horario.startTime = date_start
        nuevo_registro_horario.endTime =  date_end


        this.AbrirModal_Editar_RegistroHorario(nuevo_registro_horario);
        }


}
