/*
para controlar que no se acceda a una pagina sin haber hecho login sigo el tutorial..
(hay mas pero este es el mas claro que he encontrado y es importante porque no es sencillo)
https://www.freakyjolly.com/ionic-angular-guards-tutorial/#.X1tSKdRS-Uk
*/
/*
*NOTA abrir modal elegir area
para abrir la ventana modal de elegir area nada mas cargarse con motivos de depuración 
he probado los siguientes "ciclos" OnInit, AfterViewInit, AfterViewChecked
pero no me ha funcionado, incluso los 2 ultimos "AfterViewInit, AfterViewChecked" hacian que
chrome se colgara a ejecutarse miles de veces
import { Component, OnInit, AfterViewInit, AfterViewChecked } from '@angular/core';
*/

import { Component, Inject, LOCALE_ID }                 from '@angular/core';
import { Router }                                       from '@angular/router';
import { Platform, ModalController }                    from '@ionic/angular';
import { SplashScreen }                                 from '@ionic-native/splash-screen/ngx';
import { StatusBar }                                    from '@ionic-native/status-bar/ngx';
import { environment, slidemenu_options }               from 'src/environments/environment';
import { AuthenticationService }                        from 'src/app/services/authentication.service';
import { DepuracionService }                            from 'src/app/services/depuracion.service'
import { GeneralService }                               from 'src/app/services/general.service'
import { StorageService }                               from 'src/app/services/storage.service';
import { SeleccionarAreaPage }                          from 'src/app/pages/seleccionar-area/seleccionar-area.page';
import { InformesPage }                                 from 'src/app/pages/informes/informes.page';

//para configurar/usar onesignal uso la siguiente documentación
//...https://ionicframework.com/docs/native/onesignal
//...https://devdactic.com/push-notifications-ionic-onesignal/
//...https://como-programar.net/ionic/onesignal/
import { OneSignal }                                    from '@ionic-native/onesignal/ngx';

//añado la libreria moment tal como he encontrado aqui...
//...https://medium.com/@ferampe/angular-4-y-moment-js-cc6ceec00326
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

    slidemenu_options : any;
    nivel_usuario: number;
    nombre_nivel_de_acceso: string;

    constructor(
                @Inject(LOCALE_ID) private locale: string,
                private platform: Platform,
                private splashScreen: SplashScreen,
                private statusBar: StatusBar,
                private Router: Router,
                private ModalController: ModalController,
                private authenticationService: AuthenticationService,
                private DepuracionService: DepuracionService,
                private StorageService: StorageService,
                private GeneralService: GeneralService,
                private OneSignal: OneSignal

        ) {
        this.SlideMenu_Options();
        this.initializeApp();


        }


    initializeApp() {
        this.DepuracionService.console_log("dentro de initializeApp", null, "login");
        this.platform.ready().then(() => { 
            this.statusBar.styleDefault();
            this.splashScreen.hide();

            //siguiendo la documentación... https://ionicframework.com/docs/angular/platform con...
            //  this.platform.platforms()
            //obtengo que plataformas acepta la ejecución actual
            //if (this.platform.is('cordova')) { //la comento por ahora ya que quiero ver como funciona con todas las posibles plataformas
                this.Inicializar_OneSignal();
            //    }


            //-------------------------------------------------------------------------------------------------------------------------------------------------
            //PARA CONTROLAR NADA MAS CARGAR LA APP SI VOY AL LOGIN O AL MENU (en caso de estar logeado)
            //this.DepuracionService.console_log("controlo nada mas cargar la app si lo mando a login o al ya tener credenciales lo mando a menu", null, "login")
            //this.DepuracionService.console_log("control inicial del authState > isAuthenticated", this.authenticationService.isAuthenticated(), "login")
            
            //this.authenticationService.authState.subscribe(state => {
            //con la anterior liniea (que era lo que proponia el tutorial) se producia una doble ocurrencia ya que se "disparaba" 2 veces
            //la entrada en dicha condición asi que lo hago directamente mirando el storage...
            //NOTA: lo comento porque da los siugientes problemas
            //          - si actualizo desde home (y puede ocurrir cuando accedan desde web) no reobtiene los datos
            //          - cuando se instale en el punto de entrada del club hay que obligar a meter un nuevo usuario 
            //NOTA: si no esta comentada es que nos interesa por temas de depuracion
            this.StorageService.get('usuario').then((usuario) => {
                //this.DepuracionService.console_log("control inicial del authState > susbscribe > state", state, "login")
                //this.DepuracionService.console_log("control inicial del authState > susbscribe > this.authenticationService.isAuthenticated()"
                //                                    , this.authenticationService.isAuthenticated(), "login")
                var str_url = "login" 
                this.DepuracionService.console_log("antes de if (usuario)", usuario, "login");
                if (usuario) {
                    //this.DepuracionService.console_log("...tiene credenciales -> lo mando a home", null, "login")
                    this.DepuracionService.console_log("localstorage usuario", usuario.usuario, "environment usuario")
                    environment.usuario = {
                                            cod             : usuario.cod,
                                            usuario         : usuario.usuario,
                                            clave           : atob(usuario.clave),
                                            nombre_completo : usuario.nombre_completo,
                                            nivel           : usuario.nivel,
                                            nombre_nivel    : usuario.nombre_nivel,
                                            cod_area        : usuario.cod_area,
                                        }
                    str_url = "home"
                    } 
                this.DepuracionService.console_log("app.component > environment.usuario ", environment.usuario, "environment usuario")
                this.Router.navigate([str_url]);
                });
            
            //this.Router.navigate(["/login"]);//al haber comentado lo anterior tengo que indicar por que pagina quiero que empiece
            //this.Router.navigate(["/home"]);//para depuracion, sino habilitar la anterior linea NO VA
            });
        } // initializeApp() 


    //Añado una popiedad a cada una de las entradas del menu:
    //  - nivel_usuario: niveles de usuario a los cuales se permite acceder a este menu (cualquiera que tenga un nivel por debajo del indicado en esta propiedad) 
    //lo defino dentro del fichero environment.ts para que sea accesible desde todos sitios
    SlideMenu_Options()  {
        this.slidemenu_options = slidemenu_options
        
        }

    Ejecutar_SlideMenu_Option (prmtrOption) {
        this.DepuracionService.console_log("dentro de Ejecutar_SlideMenu_Option", prmtrOption.title, "modal area usuario")
        if (prmtrOption.url) {
            this.DepuracionService.console_log("cargamos la página..." + prmtrOption.url, "", "modal area usuario")
            this.Router.navigate([prmtrOption.url]);
            }
        //a veces no es posible hacerlo con eval por lo que en función del parametro .click_sin_eval ejecuto un codigo u otro
        else if (prmtrOption.click_sin_eval) {
            switch (prmtrOption.click_sin_eval) {
                case "seleccionar area": 
                    //*NOTA evitar Circular dependency detected
                    this.GeneralService.AbrirModal_Seleccionar_Area(SeleccionarAreaPage, prmtrOption.id)
                    break;
                case "informes": 
                    //*NOTA evitar Circular dependency detected
                    this.GeneralService.AbrirModal_Informes(InformesPage);
                    break;
                }
            }
        else if (prmtrOption.click){
            this.DepuracionService.console_log("ejecutamos la function ..." + prmtrOption.click, "", "modal area usuario")
            //aunque he encontrado un articulo que da una alternativa menos "matar moscas a cañonazos"
            //https://www.sitepoint.com/call-javascript-function-string-without-using-eval/
            //NO me complico y lo hago con eval
            eval(prmtrOption.click)
            }
        }

    Inicializar_OneSignal () {
        this.DepuracionService.console_log("Inicializar_OneSignal inicio (" + environment.APP_VERSION + ")", null, "onesignal")
        this.OneSignal.startInit(environment.ONESIGNAL_app_id, null);
        //como en web no funciona par ios, en vez de...
        //this.OneSignal.inFocusDisplaying(this.OneSignal.OSInFocusDisplayOption.InAppAlert);
        //pongo...

        this.OneSignal.inFocusDisplaying(this.OneSignal.OSInFocusDisplayOption.None);

        if (this.platform.is('cordova')) { 
            this.OneSignal.getIds().then((ids) => {
                this.DepuracionService.console_log("Inicializar_OneSignal getIds", JSON.stringify(ids), "onesignal")
                });

            this.OneSignal.handleNotificationReceived().subscribe(data => {
                let msg = data.payload.body; 
                let title = data.payload.title;
                let additionalData = data.payload.additionalData;
                let str_mensaje = title + "\n\n" + msg + "\n\n" + additionalData;
                this.DepuracionService.console_log("onesignal handleNotificationReceived", str_mensaje, "onesignal")
                });
            this.OneSignal.handleNotificationOpened().subscribe(data => {
                let additionalData = data.notification.payload.additionalData;
                this.DepuracionService.console_log("onesignal handleNotificationOpened", additionalData, "onesignal")
                });
            } //if (this.platform.is('cordova')) 
        
        this.OneSignal.endInit();
        this.DepuracionService.console_log("Inicializar_OneSignal fin (" + environment.APP_VERSION + ")", null, "onesignal")
        }




    //Leer *NOTA abrir modal elegir area
    /*
    ngOnInit() {
        console.log ("dentro de ngOnInit() ")
        //this.AbrirModal_Seleccionar_Area('usuarios')
        }

    ngAfterViewInit() {
        console.log ("dentro de ngAfterViewInit ")
        //this.AbrirModal_Seleccionar_Area('usuarios')
        }

    ngAfterViewChecked() {
        console.log ("dentro de ngAfterViewChecked ")
        //this.AbrirModal_Seleccionar_Area('usuarios')
        }
    */
}
