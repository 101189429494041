import { DepuracionService } from './../app/services/depuracion.service';
import { LOCALE_ID } from '@angular/core';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import * as moment from 'moment';
//2020_08_13
//muy bien explicado como funciona esto en...
//...https://www.joshmorony.com/production-development-environment-variables-in-ionic-angular/
//asi que elimino lo siguiente
/*
"fileReplacements": [
    {
      "replace": "src/environments/environment.ts",
      "with": "src/environments/environment.prod.ts"
    }
  ],
*/
//del fichero angular.json
//no obstante he dejado una copia de dicho fichero como angular_original.json
//Para luego usar lo aqui definido debo añadir la sigueitne importanción en el fichero que lo necesite...
//      import { environment } from '../../environments/environment';
//y ya se podrá acceder a cada una de las propiedades con...
//      environment.NOMBRE_DE_LA_PROPIEDAD

var tipo_servidor = "";
var url_servidor = "";
var es_produccion = false;
var location_HOST = window.location.host.toUpperCase();
var location_PATHNAME = window.location.pathname.toUpperCase();
var location_HREF = window.location.href.toUpperCase();
var strConsoleEleccionServidor = ""; //como no puedo utilizar DepuracionService.console_log..., uso esta vble
var strAlert = "window.location...\n" 
             + "...host: " + window.location.host + "\n" 
             + "...href: " + window.location.href + "\n" 
             + "...pathname: " + window.location.pathname + "\n";
//console.log(strAlert)
strConsoleEleccionServidor += strAlert;

//alert(location_HOST);
//alert(location_host + "\n" + location_host.indexOf("TEST."));
if (    location_HOST == "LOCALHOST:8100" || location_HOST == "PC" || ( location_HOST.indexOf("127.0.0.1:") > - 1)   ) {
    strConsoleEleccionServidor += "\n...local";
    url_servidor = 'http://pc2/labormapper'; 
    tipo_servidor = "dev";
    }
else if ( (location_HOST.indexOf("TEST.") > -1) || (location_PATHNAME.indexOf("REGISTROHORARIO.ERNESTOLOPEZ") > -1)
          || (location_HOST.indexOf("APPTEST.LABORMAPPER") > -1) ){ 
    strConsoleEleccionServidor += "\n...dev";
    //alert(location_HOST.indexOf("test."))
    //url_servidor = 'https://registrohorario.ernestolopez.com';         
    url_servidor = 'https://apptest.labormapper.com';         
    tipo_servidor = "test";
    }
//else if (location_PATHNAME.indexOf("APP.LABORMAPPER") > -1) {
else if (location_HREF.indexOf("//APP.LABORMAPPER") > -1) {
    strConsoleEleccionServidor += "\n...prod";
    //OJO con habilitar las siguientes líneas  
    url_servidor = 'https://apptest.labormapper.com';
    es_produccion = true;
    }
    
//para forzar a modo test    
//url_servidor = 'https://apptest.labormapper.com'; tipo_servidor = "test"; strConsoleEleccionServidor += "\n...pero fuerzo a test";

//para forzar a modo produccion
//url_servidor = ''; //es_produccion = true;  strConsoleEleccionServidor += "\n...PERO FUERZO A PRODUCCIÓN.";

var strAlert = "url_servidor: " + url_servidor + "\n" 
             + "tipo_servidor: " + tipo_servidor + "\n";
//console.log(strAlert)
strConsoleEleccionServidor += "\n" + strAlert;
//console.log("strConsoleEleccionServidor\n-------------------------\n", strConsoleEleccionServidor)
//alert("strConsoleEleccionServidor\n-------------------------\n\n" + strConsoleEleccionServidor)
    
//Incluyo tb en el environment la configuración de headers necesaria para hacer peticiones POST
//por ensayo-erro solo he encontado la siguietne forma
    /*
    //Con estas headers no consigo nada...
    //'Access-Control-Allow-Origin': '*'
    //'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT');
    //'Accept': 'application/json');
    //'content-type': 'application/json');
    //"Access-Control-Allow-Headers','accept: content-type');
    //
    //Solo lo consigo con 
    let options = {
        headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
        };
    */

var intAnioInicioApp = 2020;
var intAnioActualApp = new Date().getFullYear();
var arrayAniosVidaApp = [];
for (var i = intAnioInicioApp; i <= intAnioActualApp ; ++i) {
    arrayAniosVidaApp.push(i)
    }
//console.log ("arrayAniosVidaApp: ", arrayAniosVidaApp)

export const environment = {
    production: es_produccion
    , APP_NAME: 'Registro Horario'
    , APP_PREFIJO: 'RegHorario_' //ver services... StorageService (por ahora no uso este prefijo)
    , APP_VERSION: '0.0.11i' //está sin actualizar, utilizo la siguiente vble
    , APP_FECHA_ULTIMA_VERSION: '30/06/2021'
    , GOOGLE_ANALYTICS_ID: ''
    , URL_SERVIDOR: url_servidor
    , URL_JSON_ACCION: url_servidor + '/asp/ejecutar_ajax.asp?accion='
    , TIPO_SERVIDOR: tipo_servidor //para poder saber contra que bbdd estoy ejecutando la app.
    , ES_PRODUCCION: es_produccion
    , COD_CLIENTE: 1 //1 = RC Jolaseta
    , gbl_mostrar_console_log: true
    , ONESIGNAL_app_id: 'aa5bf362-c06f-4d61-a8f6-e0451d596145'
    , gbl_console_log_dato_a_mostrar: "onesignal"
                                        //"onesignal" "guardar registro horario" "cargar registros calendario" "mostrar registros horarios"
                                        //"ionpicker tipo de hora component" "ionpicker tipo de hora component recepcionar"
                                        //"editar tipo de hora" "consultas duplicadas" "Adaptar Fechas a Semana Actual"
                                        //"cargar registros duplicado" "borrar usuario" "registrar hora inicio > validacion"
                                        //"registrar hora inicio > calendario", "registrar hora inicio fechas", "calcular semana del anio"
                                        //"scroll tipos de hora" "validar tipos de hora", "manejar slides"
                                        //"mostrar Informe Computo de Horas" "configurar Informe Computo de Horas" "ver informes"
                                        //"editar registros horarios 2" "editar registros horarios" "Seleccionar Calendario Usuario o Area"
                                        //"registros calendario area", "guardar calendario", "guardar calendario 2"
                                        //"seleccionar horas en calendario" "editar calendarios" "obtener areas" "cache home"
                                        //"probar enviar y recibir tildes" "ver usuario"  "cargar usuarios de area"
                                        //"crear arbol areas" "modal area usuario" "fechas cargar registros"
                                        //"arreglar ExpressionChangedAfterItHasBeenCheckedError"
                                        //"seleccionar dia en semana" "ordenar horas y calendario"
                                        //"environment usuario" "login" "cargar registros" "cargar ultimo reg"
                                        //""
    , usuario: null // { 'cod': null, 'usuario': null, 'clave': null, 'nombre_completo': null  ...}, lo inicializo en "app.component.ts"
    //, http_post_options: {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
    //, http_post_options: {headers: {'Content-Type': 'application/json; charset=ISO-8859-1'}}
    //, http_post_options: {headers: {'Content-Type': 'application/json'}}
    , http_post_options: {headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=ISO-8859-1'}}
    , patterns: {
                nombre_apellido:    "[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*",
                telefono:           "[0-9]*",
                //email:              "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$", //encontrado en https://ionicthemes.com/tutorials/about/forms-and-validation-in-ionic NO es perfecta pero es la que mas se asemeja
                //email:              "^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$", //encontrado en https://forum.ionicframework.com/t/email-regex/102423/5
                //email:              "^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$", //encontrado en https://regexr.com/3e48o CASCA
                //email:              '/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/', //encontrado en https://emailregex.com/ NO FUNCIONA
                email:              "[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})", //https://stackoverflow.com/questions/42835533/how-can-i-make-validation-of-email-in-ionic-using-html5-js-or-angular-work/43407738 <-- ESTA ES LA QUE MEJOR FUNCIONA
                password:           "[0-9a-z-A-Z-]*",
                }
    , format_date: "YYYY-MM-DD"
    , format_hour: "HH:mm"
    , format_datehour: "YYYY-MM-DD HH:mm"
    , margen_minutos_coincidencia_con_calendario: 15
    , conf_calendario: {
        //mode: 'month',
        //mode: 'week',
        mode: 'day',
        currentDate: new Date(),
        currentDate2: new Date(), //creo esta para evitar utilizar currentDate, ya que al actualizarla provoca un "onCurrentDateChanged"
        //currentDate: new Date('2020-09-19'), //para pruebas
        locale: 'es-ES', //para que ponga en castellano todo lo posible
        startHour: "8",
        endHour: "23",
        step: "1", //lo pongo asi para intentar que redondeando las startTime y endTime a minutos exactos no ponga diferentes eventos como 2 tareas diferentes
                   //cuando su limite no son horas exactas, medias horas o cuartos de hora, pero no funciona
        startingDayWeek: "1",
        startingDayMonth:  "1",
        //los siguientes formatos los he extraido de... https://angular.io/api/common/DatePipe
        formatHourColumn: "HH",
        formatDayHeader_corto: "EEEEE", 
        formatWeekViewDayHeader_corto: "EEEEE - d"
        }
    , datetime_picker: {
        //value: new Date(),
        value: null, // esto "moment().format()" pondría la fecha actual por defecto pero lo dejo vacio para tener mas maniobrabiliddd
        titulo: "", //byern: para mostrar un texto cuando no hay fecha definida
        displayFormat: "YYYY-MMM-DD HH:mm",
        minuteValues: "0,15,30,45", 
        yearValues: arrayAniosVidaApp.join(","), 
        monthShortNames: "Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dic", 
        buttons: [
                  {text: 'Guardar',  handler: () => { console.log('DateTimePicker Guardado !') }  }, 
                  {text: 'Cerrar',   handler: () => { console.log('DateTimePicker Cerrado !'); /*return false; //con esto no se cierra*/ } }
                 ],
        cssClass: '', //es_modal
        formatear_value:function(){ 
            //console.log("formatear_value > this.value", this.value)
            //console.log("formatear_value > this.value == ''", (this.value == "") )
            //console.log("formatear_value > typeof(this.value)", typeof(this.value))
            let str_return;
            if ( moment(this.value).isValid() ) {
                str_return = moment(this.value).format(environment.format_datehour); 
                }
            else if (this.titulo != "") {
                str_return = this.titulo
                }
            else {
                str_return = "Seleccione una fecha-hora"
                }
            //console.log("formatear_value > str_return", str_return)
            //console.log("-------------------------------")
            return str_return
            },
        actualizar_value:function(event){ 
            let fechahora_elegida = event.year.text + "-" + ("00" + event.month.value).slice(-2) + "-" + event.day.text + " " + event.hour.text + ":" + event.minute.text + ""
            fechahora_elegida = moment(fechahora_elegida, environment.format_datehour).format();
            this.value = fechahora_elegida;
            //return fechahora_elegida;
            },
        }
    };


//defino los tipos de datos que se utilizaran a lo largo de la app para que esten centralizados y por tanto homogeneos
export const modelos_de_datos = {
    insert_update: {
        tipo_actualizacion: null //INSERT o UPDATE
        , nombre_tabla: null
        , nombre_campo_clave: null
        , valor_campo_clave: null // para cuando es tipo update saber que registro hay que actualizar
        , campos: {nombre_campo: null, valor_campo: null, tipo_campo:null}
        }
    , registro_de_hora_inicial: {hora_de_inicio: null, hora_de_fin: null, cod_tipo_de_hora: null}
    , registro_de_hora_final: {cod_registro_horario: null, hora_de_fin: null}
    //este usuario es diferente al que se utiliza para guardar la información del usuario logeado el que está en environment.usuario
    , usuario : {COD_USUARIO: null, NOMBRE_USUARIO: null, APELLIDO1_USUARIO: null, APELLIDO2_USUARIO: null, TELEFONO_USUARIO: null
                 , EMAIL_USUARIO: null, CLAVE_USUARIO: null, COD_AREA: null, COD_NIVEL_de_ACCESO: null, COD_CLIENTE: null}
    //extraido de "editar-registros-horarios.page.ts > async AbrirModal_Editar_RegistroHorario (prmtrRegistroHorario)"
    , registro_horario: { COD_AREA: null, COD_USUARIO: null, TIPO_REGISTRO: "REGISTRO HORARIO USUARIO", ORDEN_TIPO_REGISTRO: 1
                         , COD_REGISTRO: null, TITULO_REGISTRO: null, FECHAHORA_INICIO_REGISTRO: null, FECHAHORA_FIN_REGISTRO: null
                         , COD_TIPO_DE_HORA: null, TITULO_TIPO_DE_HORA: null, startTime: null, endTime: null, title: null
                         , allDay: null, HORAS: null, MINUTOS: null, SEGUNDOS: null, SEGUNDOS_TOTALES: null, ES_REGISTRO_HORARIO_a_contabilizar: true
                        }
    }




 //el 'id' tambien lo utilizo como 'class'
export const slidemenu_options = [
                                    {
                                        id            : "login",
                                        title         : "Identificación",
                                        url           : "/login",
                                        click         : "",
                                        icon          : "home-outline",
                                        nivel_usuario : 4  
                                    },
                                    {
                                        id            : "usuarios",
                                        title         : "Usuarios",
                                        url           : "",
                                        //click         : "this.GeneralService.AbrirModal_Seleccionar_Area('usuarios')", //OJO: el primer  parametro tiene que ser igual que el id
                                        click_sin_eval: "seleccionar area",
                                        icon          : "people-outline",
                                        nivel_usuario : 3  
                                    },
                                    {
                                        id            : "calendarios",
                                        title         : "Calendarios",
                                        url           : "",
                                        //click         : "this.GeneralService.AbrirModal_Seleccionar_Area('calendarios')", //OJO: el primer  parametro tiene que ser igual que el LOCALE_ID
                                        click_sin_eval: "seleccionar area",
                                        icon          : "calendar-outline",
                                        nivel_usuario : 3  
                                    },
                                    {
                                        id            : "registros horarios",
                                        title         : "Registros Horarios",
                                        url           : "",
                                        //click         : "this.GeneralService.AbrirModal_Seleccionar_Area('registros horarios')", //OJO: el primer  parametro tiene que ser igual que el id
                                        click_sin_eval: "seleccionar area",
                                        icon          : "log-in",
                                        nivel_usuario : 3  
                                    },
                                    {
                                        id            : "informes",
                                        title         : "Informes",
                                        url           : "",
                                        //click         : "this.GeneralService.AbrirModal_Informes()",
                                        click_sin_eval: "informes",
                                        icon          : "documents-outline",
                                        nivel_usuario : 3  
                                    },
                                    /*
                                    {
                                        id            : "pruebas",
                                        title         : "pruebas",
                                        url           : "/pruebas",
                                        click         : "",
                                        icon          : "",
                                        nivel_usuario : 1  
                                    },
                                    */
                                    //* NOTA option "invisible": añado este pero no será visible en el menú principal (nivel_usuario: 0) ya que solo lo quiero
                                    //                           para que se componga correctamente la cabecera de "Seleccionar Area"
                                    {
                                        id                  : "informe situación actual",
                                        title               : "Informe Situación Actual",
                                        url                 : "",
                                        click               : "",  //creo que esto no se llega a ejecutar
                                        //icon              : "documents-outline",
                                        icon                : "document-outline",
                                        icono_secundario    : "log-in",
                                        nivel_usuario       : 0  // <---- ver * NOTA option "invisible"
                                    },
                                    //* NOTA option "invisible"
                                    {
                                     id                  : "informe computo de horas",
                                     title               : "Informe Computo de Horas",
                                     url                 : "",
                                     click               : "",  //creo que esto no se llega a ejecutar
                                     //icon              : "documents-outline",
                                     icon                : "document-outline",
                                     icono_secundario    : "time-outline",
                                     nivel_usuario       : 0 // <---- ver * NOTA option "invisible"
                                 },

                                ]


//console.log(JSON.stringify(environment));
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
