import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams} from '@ionic/angular';
import { environment, slidemenu_options } from 'src/environments/environment';
import { bbddAreasService } from 'src/app/services/bbdd-areas.service'

import { GeneralService } from 'src/app/services/general.service';
import { DepuracionService } from 'src/app/services/depuracion.service';

import { UsuariosPage } from 'src/app/pages/usuarios/usuarios.page';

@Component({
  selector: 'app-seleccionar-area',
  templateUrl: './seleccionar-area.page.html',
  styleUrls: ['./seleccionar-area.page.scss'],
})
export class SeleccionarAreaPage implements OnInit {
    slidemenu_option_elegida: any;
    array_areas:any;
    prmtr_Proposito_Seleccionar_Area: string;

    constructor(private ModalController: ModalController
                , private NavParams: NavParams
                , private DepuracionService: DepuracionService
                , private GeneralService: GeneralService
                , private bbddAreasService: bbddAreasService
                ) {

        this.prmtr_Proposito_Seleccionar_Area = this.NavParams.get('prmtr_Proposito_Seleccionar_Area');


        this.Cargar_y_Formatear_Areas()

        //this.DepuracionService.console_log("slide menu", slidemenu_options , "editar calendarios")
        //para buscar un elemento de un array por una propiedad de los objetos de dicho elemento, encontrado en...
        //...https://www.codegrepper.com/code-examples/elixir/typescript+search+array+of+objects
        this.slidemenu_option_elegida = slidemenu_options.find(element => element.id === this.prmtr_Proposito_Seleccionar_Area);
        //this.DepuracionService.console_log("this.slidemenu_option_elegida", this.slidemenu_option_elegida , "editar calendarios")
        this.DepuracionService.console_log("this.slidemenu_option_elegida", this.slidemenu_option_elegida , "editar registros horarios")
        }

//para poder poner N elementos con ngFor utlizo lo encontrado en...
    //https://lishman.io/using-ngfor-to-repeat-n-times-in-angular
    arrayRepeat(n: number): number[] {
        return [...Array(n).keys()];
        }


    Cargar_y_Formatear_Areas () {
        this.DepuracionService.console_log("seleccionar-area.page.ts > environment", environment, "obtener areas")
        this.bbddAreasService.getAreas(environment.usuario.cod_area).subscribe(result => {
            var array_data = result.data;
            
            array_data = this.Maquetar_Areas_marcando_ultimas_ramas_verticales_y_siguiente_nivel (array_data)

            //array_data = this.Maquetar_Areas_marcando_lineas_verticales_sin_hijo_v0 (array_data);
            array_data = this.Maquetar_Areas_marcando_lineas_verticales_sin_hijo_v1 (array_data);
            

            this.array_areas = array_data;
            //this.array_areas = result.data;
            });
        
        }

    //señalo 2 condiciones para poder dibujar bien las "ramas"
    // - es_ultima_rama_vertical
    // - es_siguiente_nivel_superior
    //que junto con 
    // - es_ultima_rama_horizontal
    //nos permite tomar las decisiones necesarias en el css para casi todas las decisiones a la hora de dibujar las ramas
    Maquetar_Areas_marcando_ultimas_ramas_verticales_y_siguiente_nivel (prmtrArrayAreas) {
        var array_areas = prmtrArrayAreas;
        var array_areas = array_areas.map(function(item, index){ 
            //this.DepuracionService.console_log("array_areas length: " + array_areas.length, null, "crear arbol areas")
            
            item.es_ultima_rama_vertical = false;
            item.es_siguiente_nivel_superior = false;
            //si este es el último elemento o el siguiente elemento tiene una PROFUNDIDAD diferente lo señalo
            //para poder componer bien el dibujo de las ramas
            if (index + 1 ==  array_areas.length) {
                item.es_ultima_rama_vertical = true;
                item.es_siguiente_nivel_superior = true;
                }
            else {
                if (array_areas[index].PROFUNDIDAD != array_areas[index + 1].PROFUNDIDAD) {
                    item.es_ultima_rama_vertical = true;
                    }

                if (array_areas[index].PROFUNDIDAD > array_areas[index + 1].PROFUNDIDAD) {
                    item.es_siguiente_nivel_superior = true;
                    }
                }
            
            //this.DepuracionService.console_log("index: " + index, null, "crear arbol areas")
            this.DepuracionService.console_log("item " + index, item, "crear arbol areas")
            /*
            this.DepuracionService.console_log(index + ") " 
                                               + array_areas[index].NOMBRE_AREA + " "
                                               + "P: " + array_areas[index].PROFUNDIDAD + " "
                                               + "L: " + array_areas[index].LEVEL + " "
                                               + "es_ultima_rama_vertical: " + array_areas[index].es_ultima_rama_vertical + " "
                                               + "es_siguiente_nivel_superior: " + array_areas[index].es_siguiente_nivel_superior + " "
                                               , null, "crear arbol areas")
            */
            return item;
            }, this);

        return array_areas

        } //Maquetar_Areas_indicando_ultimas_ramas_verticales_y_siguiente_nivel (prmtrArrayAreas)

    //sólo nos falta borrar las liineas verticales de los ultimos elementos cuyas areas de mas nivel
    //no tienen subareas
    //para ello recorro al reves el array marcando dichas entradas
    Maquetar_Areas_marcando_lineas_verticales_sin_hijo_v0 (prmtrArrayAreas) {
        var array_areas = prmtrArrayAreas;
        var bln_salir_for = false;
        var int_ultima_PROFUNDIDAD = array_areas[array_areas.length-1].PROFUNDIDAD;
        this.DepuracionService.console_log("int_ultima_PROFUNDIDAD: " + int_ultima_PROFUNDIDAD, null, "crear arbol areas")
        for (var I = array_areas.length - 1; 
                I >= 0 && bln_salir_for == false; 
                I--) {
                //mando eliminar (junto con la clase "es_ultima_rama_horizontal" definida en el template)
                //las lineas verticales que pudiera haber al final y que no conducen a nada
                //incluida la linea que baja desde la ultima rama que tiene (int_ultima_PROFUNDIDAD > array_areas[I].PROFUNDIDAD)
                //array_areas[I].borrar_linea_vertical = true;
                array_areas[I]["borrar_linea_vertical"] = true;
                array_areas[I]["borrar_linea_vertical_nivel_" + (int_ultima_PROFUNDIDAD - 2)] = true; //el -2 es por ensayo-error

                if (int_ultima_PROFUNDIDAD > array_areas[I].PROFUNDIDAD) {
                    bln_salir_for = true;
                    }

                this.DepuracionService.console_log(I + ") ", array_areas[I], "crear arbol areas")
                }

            return array_areas;
            } // Maquetar_Areas_borrando_lineas_verticales_sin_hijo_v0


    //creo que es mas intuitivo...
    //  ...recorrer todas las ramas desde principio
    //  ...en aquellas ramos que esten marcadas como ultima rama vertical
    //       ...recorrer las siguientes ramas y si acaba en una con profundidad de numero mas bajo
    //          significa que no tiene hijos y por tanto hay que marcarlas
    //       ...volver a hacer el mismo recorrido marcando
    Maquetar_Areas_marcando_lineas_verticales_sin_hijo_v1 (prmtrArrayAreas) {
        var array_areas = prmtrArrayAreas;
        var bln_salir_for = false;
        var bln_son_lineas_verticales_sin_hijo = false;
        var int_J_ultima_linea_vertical_sin_hijo = null;
        //...recorrer todas las ramas desde el principio
        for (var I = 0; I < array_areas.length; ++I) {
            //...en aquellas ramos que esten marcadas como ultima rama vertical
            if (array_areas[I].es_ultima_rama_vertical) {
                //...recorrer las siguientes ramas y si acaba en una con profundidad de numero mas bajo
                //   significa que no tiene hijos y por tanto hay que marcarlas
                bln_salir_for = false;
                bln_son_lineas_verticales_sin_hijo = false;
                int_J_ultima_linea_vertical_sin_hijo = null;
                //J = I + 1 para que no se compare con si mismo
                //          y por esto mismo lo primero que comparo dentro es que no se haya excedido la longitud del array
                for (var J = I + 1; !bln_salir_for; ++J) {
                    //si hemos llegado al final y tiene la misma profundidad tb marcamos
                    if (J >= array_areas.length) { 
                        bln_salir_for = true; 
                        bln_son_lineas_verticales_sin_hijo = true;
                        int_J_ultima_linea_vertical_sin_hijo = J - 1; //añado el "- 1" para marcar tb la rama I que estamos estudiando
                        }
                    //en caso de que estemos en una rama de diferente profundidad ya podemos averiguar
                    //if (array_areas[I].PROFUNDIDAD != array_areas[J].PROFUNDIDAD) { 
                    //eso no es exacto, tiene que ser una rama de menor profundidad que sería lo que deje
                    //la linea vertical sin hijo
                    else if (array_areas[I].PROFUNDIDAD >= array_areas[J].PROFUNDIDAD) { 
                        bln_salir_for = true; 
                        //si la profundidad es 2 grados menor hay que marcarlas
                        //if (array_areas[I].PROFUNDIDAD >= array_areas[J].PROFUNDIDAD + 2) { 
                        //corrijo, si es menor ya hay que marcarlos ya que la linea no desemboca en un hijo
                        if (array_areas[I].PROFUNDIDAD > array_areas[J].PROFUNDIDAD) { 
                            bln_son_lineas_verticales_sin_hijo = true;
                            int_J_ultima_linea_vertical_sin_hijo = J - 1;
                            }
                        }
                    } // for (var J = I; !bln_salir_for; ++J)
                //en caso de bln_son_lineas_verticales_sin_hijo marcamos desde J hasta int_J_ultima_linea_vertical_sin_hijo
                if (bln_son_lineas_verticales_sin_hijo) {
                    for (var J = I; J <= int_J_ultima_linea_vertical_sin_hijo; ++J) {
                        let int_nivel_linea_a_borrar = array_areas[I].PROFUNDIDAD - 1
                        array_areas[J]["borrar_linea_vertical_nivel_" + int_nivel_linea_a_borrar] = true;
                        }
                    }

                } //if (array_areas[I].es_ultima_rama_vertical)

            this.DepuracionService.console_log(I + ") ", array_areas[I], "crear arbol areas")
            } // for (var I = 0; I < array_areas.length; ++I)

        return array_areas;
        } // Maquetar_Areas_borrando_lineas_verticales_sin_hijo_v1


    prueba2 () {
        console.log ("dentro de prueba2")
        }

    async AbrirModal_Usuarios(prmtrArea, prmtrObtenerSubAreas) {
        //console.log ("dentro de AbrirModal_Registrar_Hora_Inicio() ")
        this.DepuracionService.console_log("dentro de AbrirModal_Registrar_Hora_Inicio", null, "cargar usuarios de area");
        this.DepuracionService.console_log("prmtrArea",  prmtrArea , "cargar usuarios de area");
        this.DepuracionService.console_log("prmtrObtenerSubAreas",  prmtrObtenerSubAreas , "cargar usuarios de area");
        //Si prmtrArea.LEVEL == "1" significa que estamos intentando acceder al pripio usuario logeado
        //y eso no lo permitimos ya que un usaurio solo puede modificar los usuarios por debajo suyo.
        if (prmtrArea.LEVEL != '1' || prmtrObtenerSubAreas) {
            const modal = await this.ModalController.create({
                component: UsuariosPage,
                cssClass: 'usuarios'
                //, backdropDismiss: false //	If true, the modal will be dismissed when the backdrop is clicked.
                //para poder pasar "parametros" al modal...
                //encontrado en... https://medium.com/@david.dalbusco/how-to-declare-and-use-modals-in-ionic-v4-4d3f42ac30a3
                , componentProps: {
                    'prmtr_Area': prmtrArea
                    , 'prmtr_ObtenerSubAreas': prmtrObtenerSubAreas
                    , 'prmtr_Proposito_Seleccionar_Area': this.prmtr_Proposito_Seleccionar_Area
                  }
            });
            
            await modal.present();
            
            modal.onDidDismiss().then((result) => {
                this.DepuracionService.console_log ("se ha cerrado la ventana modal de usuarios", "", "cargar usuarios de area")
    
                });
            }


        
        }




    //cuando pinche en un area cargo sus usuarios
    //En un primer lugar habia creado esa funcion para mostrar los usuarios de cada area segun la eligiesen dentro del "arbol" de areas
    //pero finlamente he decidido saltar a otra pagina.
    Cargar_y_Formatear_Usuarios_OBSOLETA (prmtrArea) {
        this.DepuracionService.console_log("dentro de Cargar_y_Formatear_Usuarios", null, "cargar usuarios de area")
        prmtrArea.usuarios = true

        this.DepuracionService.console_log("prmtrArea", prmtrArea, "cargar usuarios de area")


        //para manipular elementos del dom...
        //...http://blog.enriqueoriol.com/2017/08/angular-dom-renderer.html

        //OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO 
        //Probar a hacerlo como indican aqui...
        //https://www.techiediaries.com/angular-elementref/


        //let text = this.Renderer2.createText("pruebaaaaaa");
        //this.Renderer2.appendChild(this.myButton.nativeElement, text);
          
        //this.prueba_modificar_DOM();

        }


    ngOnInit() {
        }

    close() {
        this.ModalController.dismiss();
        }


}
