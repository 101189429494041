//para crear un popover componetn lo he extraido de...
//https://www.liquidcms.ca/post/how-create-ionic-5-popover
//https://www.tutorialandexample.com/ionic-popover/
//https://ionicframework.com/docs/api/popover

import { Component, OnInit }                                from '@angular/core';
import { PopoverController, NavParams }                     from "@ionic/angular";
import { GeneralService }                                   from 'src/app/services/general.service';
import { DepuracionService }                                from 'src/app/services/depuracion.service';
import { bbddUsuariosService }                              from 'src/app/services/bbdd-usuarios.service'

@Component({
  selector: 'app-popover-borrar-usuario',
  templateUrl: './popover-borrar-usuario.component.html',
  styleUrls: ['./popover-borrar-usuario.component.scss'],
})
export class PopoverBorrarUsuarioComponent implements OnInit {

    prmtr_usuario:any;
    //puedo_borrar_el_usuario:boolean;
    data_prueba_ngif = 'dentro de popover';


    constructor(
        private PopoverController: PopoverController,
        private NavParams: NavParams,
        private DepuracionService: DepuracionService,
        private GeneralService: GeneralService,
        private bbddUsuariosService: bbddUsuariosService
        ) { 
            this.prmtr_usuario = this.NavParams.get('prmtr_usuario');
            this.DepuracionService.console_log("PopoverBorrarUsuarioComponent > prmtr_usuario", this.prmtr_usuario, "borrar usuario")

            
            }

    ngOnInit() {

        //NO SE porque pero en ngif no funciona asi que muestro y oculto aqui lo necesario
        //AL FINAL NO HACE FALTA porque inhabilito el boton en la pagina de usuario si tiene registro horario
        /*
        let str_botones_visibility = "hidden"
        let str_mensaje_visibility = "visible"
        let str_botones_display = "none"
        let str_mensaje_display = "block"
        if (this.GeneralService.EstaVacia(this.prmtr_usuario.FECHAHORA_INICIO_REGISTRO_HORARIO)) {
            str_botones_visibility = "visible"
            str_mensaje_visibility = "hidden"
            str_botones_display = "block"
            str_mensaje_display = "none"
            }
        //document.getElementById("botones_borrar_usuario").style.visibility = str_botones_visibility;
        //document.getElementById("mensaje_no_se_puede_borrar_usuario").style.visibility = str_mensaje_visibility;
        document.getElementById("botones_borrar_usuario").style.display = str_botones_display;
        document.getElementById("mensaje_no_se_puede_borrar_usuario").style.display = str_mensaje_display;
        */
        }

    close() {
        this.PopoverController.dismiss();
        }

    Borrar_Usuario() {
        this.DepuracionService.console_log("PopoverBorrarUsuarioComponent > Borrar_Usuario", this.prmtr_usuario, "borrar usuario")
        this.bbddUsuariosService.deleteUsuario(this.prmtr_usuario).subscribe(result => {
            this.DepuracionService.console_log("guardar_usuario > result", result, "ver usuario");
            if (result.resultado == "OK") {
                this.PopoverController.dismiss({resultado: 'OK'
                                                , usuario_borrado: this.prmtr_usuario
                                                , actualizar_listado_usuarios: true //como esto se propaga hasta la paginia de usuarios
                                                                                    //esta propiedad provoca que se recarge el listado de usuarios
                                                                                    //y asi ya no aparezca este recien borrado
                                                })
                }
            else {
                //this.ToastService.show("Se ha producido un error al guardar el usuario.")
                }
            
            });
        }

    }
