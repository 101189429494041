import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

import { DepuracionService } from 'src/app/services/depuracion.service';
import { GeneralService } from 'src/app/services/general.service';


/*
NOTA:
aunque quizas no hacia falta crear este servicio para utilizar el storage porque se 
podría llamar directamente al objeto Storage ya que está hecho lo mantengo por4que asi...
...puedo meter cualquier proceso antes o despues de cada llamada
...puedo cambiar facilmente de objeto con el que accedo al storage
...puedo añadir prefijo a las vbles y asi evitar colisiones 
   (esto creo yo que es innecesario ya que se almacenan dentro de una BBDD propia de cada app)
*/
/*
antes usaba lo encontrado aqui

extraido de https://www.techiediaries.com/ionic-storage-tutorial-example/

pero a partir de...
29_06_2021
...al cambiar de la app registrohorario a labormapper me encuentro en 
https://github.com/ionic-team/ionic-storage
que para angular el uso de este componente cambia, asi que sigo lo que indican en esa url
para hacerlo funcionar.

finalmente ha sidio necesario llamar a init() en constructor para inicializar el storage y
luego lo utilizo tal cual estaba anteriormente (ensayo-error)
porque hacerlo a través de una vble _storage tal como plantea "https://github.com/ionic-team/ionic-storage"
no he sido capaz de hacerlo funcionar.
Habra que observar si asi funciona

*/


@Injectable({
  providedIn: 'root'
})
export class StorageService {

    //private _storage: Storage | null = null;

    constructor(private Storage: Storage
                , private DepuracionService: DepuracionService
                ) { 
        this.init();
        }

    async init() {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        const storage = await this.Storage.create();
        //this._storage = storage;
        }

    //extraido de https://www.techiediaries.com/ionic-storage-tutorial-example/

    set(prmtrKey, prmtrValue){
        //this.Storage.ready().then(() => {
            this.Storage.set(prmtrKey, prmtrValue);
            //this._storage?.set(prmtrKey, prmtrValue);
        //    });
        }

    //para llamarlo, seria algo asi a...
    /*
    this.StorageService.get('name').then((value) => {
        console.log('Me: Hey, ' + value + '! You have a very nice name.');
        });
    */
    async get(prmtrKey){
        return await this.Storage.get(prmtrKey);
        }

    async remove(prmtrKey){
        return await this.Storage.remove(prmtrKey);
        }

    clear() {
        this.Storage.clear().then(() => {
        //console.log('all keys cleared');
        });


    }



    }
