import { bbddRegistrosHorariosService } from 'src/app/services/bbdd-registros-horarios.service';
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment, modelos_de_datos } from 'src/environments/environment';
import { DepuracionService } from 'src/app/services/depuracion.service';
import { GeneralService } from 'src/app/services/general.service';

//import * as iconvlite from 'iconv-lite'; //no se porque pero da casque

@Injectable({
  providedIn: 'root'
})
export class bbddUsuariosService {

    constructor(private HttpClient: HttpClient
                , private DepuracionService: DepuracionService
                , private GeneralService: GeneralService) { 
                
                }

        getUsuarios(prmtrCodUsuario, prmtrCodArea, prmtrObtenerSubAreas, prmtrCodNivel): Observable<any> {
            //function                consulta_Usuarios (   prmtrCodUsuario,        prmtrCodArea,        prmtrObtenerSubAreas,        prmtrCodNivel)
            var strFuncionServidor = 'consulta_Usuarios(' + prmtrCodUsuario + ',' + prmtrCodArea + ',' + prmtrObtenerSubAreas + ',' + prmtrCodNivel + ')'
            //var strUrl = this.GeneralService.Componer_URL_Servidor(strFuncionServidor, true)
            //this.DepuracionService.console_log("strUrl", strUrl, "cargar usuarios de area");
            //return this.HttpClient.get(strUrl);
            return this.GeneralService.Ejecutar_en_Servidor (strFuncionServidor, true, null); 
            }


        saveUsuario(prmtrObjUsuarioOriginal: any, prmtrObjFormulario: any): Observable<any> {
            var objUsuarioOriginal = prmtrObjUsuarioOriginal;

            let data = {
                tipo_actualizacion: "" //"" para que el sistema elija o "INSERT / UPDATE" para forzar
                , nombre_tabla: "TB_USUARIOS"
                , nombre_campo_clave: "COD_USUARIO"//hay que especificarlo para que devuelva el nuevo cod del registro que insertemos
                , valor_campo_clave: objUsuarioOriginal.COD_USUARIO //ahora no se usa asi que podemos comentarlo pero permitiría hacer una inserción con un cod determinado
                , campos: [
                    //{nombre_campo: "COD_USUARIO"                      , valor_campo: prmtrObjUsuarioOriginal.COD_USUARIO    , tipo_campo: "int"},
                    {nombre_campo: "NOMBRE_USUARIO"                   , valor_campo: prmtrObjFormulario.Nombre                  , tipo_campo: "string"},
                    {nombre_campo: "APELLIDO1_USUARIO"                , valor_campo: prmtrObjFormulario.Apellido1               , tipo_campo: "string"},
                    {nombre_campo: "APELLIDO2_USUARIO"                , valor_campo: prmtrObjFormulario.Apellido2               , tipo_campo: "string"},
                    {nombre_campo: "TELEFONO_USUARIO"                 , valor_campo: prmtrObjFormulario.Telefono                , tipo_campo: "string"},
                    {nombre_campo: "EMAIL_USUARIO"                    , valor_campo: prmtrObjFormulario.Email                   , tipo_campo: "string"},
                    {nombre_campo: "CLAVE_USUARIO"                    , valor_campo: prmtrObjFormulario.Password                , tipo_campo: "password"},
                    {nombre_campo: "COD_NIVEL_de_ACCESO"              , valor_campo: objUsuarioOriginal.COD_NIVEL_de_ACCESO     , tipo_campo: "int"},
                    {nombre_campo: "COD_AREA"                         , valor_campo: objUsuarioOriginal.COD_AREA                , tipo_campo: "int"},
                    {nombre_campo: "COD_CLIENTE"                      , valor_campo: environment.COD_CLIENTE                    , tipo_campo: "int"},
                    ]
                }
            this.DepuracionService.console_log("data", data, "ver usuario");

            //return this.HttpClient.get('http://pc/registrohorario/asp/ejecutar_ajax.asp?accion=tipos_de_hora(null)');
            //var strUrl = this.GeneralService.Componer_URL_Servidor ('Class_CrearConsultasBBDD_ACTUALIZAR_desde_JSON(null)', false)
            //this.DepuracionService.console_log("strUrl", strUrl, "ver usuario");
            //return this.HttpClient.post(strUrl, data, environment.http_post_options);
            return this.GeneralService.Ejecutar_en_Servidor ('Class_CrearConsultasBBDD_ACTUALIZAR_desde_JSON(null)', false, data);
            }

        deleteUsuario(prmtrObjUsuario: any): Observable<any> {

            let data = {
                tipo_actualizacion: "DELETE" 
                , nombre_tabla: "TB_USUARIOS"
                , nombre_campo_clave: "COD_USUARIO"//hay que especificarlo para que devuelva el nuevo cod del registro que insertemos
                , valor_campo_clave: prmtrObjUsuario.COD_USUARIO //ahora no se usa asi que podemos comentarlo pero permitiría hacer una inserción con un cod determinado
                //, campos: [] // En un DELETE no tienen sentido estas propiedades
                }
            this.DepuracionService.console_log("data", data, "ver usuario");

            //return this.HttpClient.get('http://pc/registrohorario/asp/ejecutar_ajax.asp?accion=tipos_de_hora(null)');
            //var strUrl = environment.URL_JSON_ACCION + 'Class_CrearConsultasBBDD_ACTUALIZAR_desde_JSON(null)'
            //var strUrl = this.GeneralService.Componer_URL_Servidor ('Class_CrearConsultasBBDD_ACTUALIZAR_desde_JSON(null)', false)
            //this.DepuracionService.console_log("strUrl", strUrl, "ver usuario");
            ////let data = [{campo1: 'valor a1', campo2: 12}, {campo1: 'valor 21', campo2: 22}, {campo1: 'valor 31', campo2: 32}];
            //return this.HttpClient.post(strUrl, data, environment.http_post_options);
            ////return this.HttpClient.get(strUrl);        
            return this.GeneralService.Ejecutar_en_Servidor ('Class_CrearConsultasBBDD_ACTUALIZAR_desde_JSON(null)', false, data) 
            }



            /*
            //todo esto erán pruebas cuando no habia arreglado el tema de las tildes y demas
            var miheaders = new Headers();
            miheaders.append('Content-Type', 'application/json; charset=UTF-8');
            return this.HttpClient.post(strUrl, data, { headers: miheaders }).map(res => res.json());

            //var miheaders = new HttpHeaders({"Content-Type": "application/json", "Accept": "application/json"})
            //return this.HttpClient.post(strUrl, data, {headers: miheaders});

            //'Accept-Charset': 'utf-8'
            //headers.append("Accept", 'application/json');

            var mis_headers
            mis_headers = {'Content-Type': 'application/x-www-form-urlencoded'} //funciona sin tildes
            //mis_headers = [{'Content-Type': 'application/x-www-form-urlencoded'}] //NO funciona
            //mis_headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=ISO-8859-1;'} //funciona sin tildes
            //mis_headers = {'Content-Type': 'application/x-www-form-urlencoded', 'Accept-Charset': 'utf-8'}  //funciona sin tildes 
            //mis_headers = {'Content-Type': 'application/x-www-form-urlencoded', 'Accept-Charset': 'ISO-8859-1'}  //funciona sin tildes 
            //mis_headers = {'Content-Type': 'application/x-www-form-urlencoded', 'Accept-Language': 'es-ES'}  //funciona sin tildes 
            //mis_headers = {'Content-Type': 'application/json', 'Accept': 'application/json'}
            //mis_headers = {'Content-Type': 'application/json'}
            //mis_headers = {'Content-Type': 'application/json; charset=ISO-8859-1'}
            //mis_headers = {'Content-Type': 'text/plain'} //funciona pero no puedo obtener la inf pasada
            //mis_headers = {'Content-Type': 'multipart/form-data'} //funciona pero no puedo obtener la inf pasada
            //mis_headers = {'Content-Type': 'application/xml'}  //funciona pero no puedo obtener la inf pasada
            
            //return this.HttpClient.post(strUrl, data, {headers: mis_headers});

            //console.log ("strUrl", strUrl)
            var datos_prueba 
            //datos_prueba = {campo1: "valor1", campo2: "válor2"}
            datos_prueba = prmtrObjFormulario

            strUrl = "http://pc/registrohorario/asp/ejecutar_ajax.asp?accion=prueba_enviar_y_recibir_Tildes_con_POST()"
            
            //console.log("iconvlite.enconde..." + iconvlite.encode("testžýáč", "cp852")); 
            //console.log("iconvlite.enconde..." + iconvlite.encode(JSON.stringify(datos_prueba), "ISO-8859-1")); 
            //iconvlite.encodingExists("us-ascii")

            //return this.HttpClient.post(strUrl, escape(JSON.stringify(datos_prueba)), {headers: mis_headers});
            return this.HttpClient.post(strUrl, datos_prueba, {headers: mis_headers});
            
            */
          




    }
