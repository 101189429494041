//obtengo como se usa de...
//https://www.freakyjolly.com/ionic-4-show-native-like-toast-messages-in-ionic-application-in-2-steps-without-any-plugin/#.X2HZ69RS-Uk


import { Injectable } from '@angular/core';

import { DepuracionService } from 'src/app/services/depuracion.service';
import { GeneralService } from 'src/app/services/general.service';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

    private obj_Toast: any;

    constructor(private DepuracionService: DepuracionService, 
                private GeneralService: GeneralService,
                private ToastController: ToastController,
            ) { 

        }

    show(prmtrMensaje) {
        //console.log ("estoy dentro toast show... " + prmtrMensaje)
        this.obj_Toast = this.ToastController.create({
            message: prmtrMensaje,
            duration: 2000,
            animated: true,
            position: 'bottom',
            //leaveAnimation: ''
            cssClass: 'toast', //lo he añadido en global.scss
            }).then((toastData) => {
                console.log(toastData);
                toastData.present();
                });
        }


    hide() {
        this.obj_Toast = this.ToastController.dismiss();
        }

}
