import { Component, OnInit, Inject, LOCALE_ID }                 from '@angular/core';
import { Router }                                               from '@angular/router';
import { ModalController, NavParams}                            from '@ionic/angular';
import { bbddUsuariosService }                                  from 'src/app/services/bbdd-usuarios.service'
import { GeneralService }                                       from 'src/app/services/general.service';
import { DepuracionService }                                    from 'src/app/services/depuracion.service';
import { UsuarioPage }                                          from 'src/app/pages/usuario/usuario.page';
import { EditarCalendarioPage }                                 from 'src/app/pages/editar-calendario/editar-calendario.page';
import { EditarRegistrosHorariosPage }                          from 'src/app/pages/editar-registros-horarios/editar-registros-horarios.page';
import { ConfigurarInformeComputoDeHorasPage }                  from 'src/app/pages/configurar-informe-computo-de-horas/configurar-informe-computo-de-horas.page'
import { environment, modelos_de_datos, slidemenu_options }     from 'src/environments/environment';



//añado la libreria moment tal como he encontrado aqui...
//...https://medium.com/@ferampe/angular-4-y-moment-js-cc6ceec00326
import * as moment from 'moment';


@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.page.html',
  styleUrls: ['./usuarios.page.scss'],
})
export class UsuariosPage implements OnInit {

    prmtr_Area: any;
    prmtr_ObtenerSubAreas: boolean;
    prmtr_Proposito_Seleccionar_Area: string;
    
    slidemenu_option_elegida: any;

    array_usuarios:any;

    usuario_nuevo: any;

    constructor(@Inject(LOCALE_ID) private locale: string
                , private ModalController: ModalController
                , private NavParams: NavParams
                , private DepuracionService: DepuracionService
                , private GeneralService: GeneralService
                , private Router: Router
                , private bbddUsuariosService: bbddUsuariosService
                ) { 

        //console.log(moment());
        //establezco la librería moment a español
        moment.locale('es');


        this.prmtr_Area = this.NavParams.get('prmtr_Area');
        this.prmtr_ObtenerSubAreas = this.NavParams.get('prmtr_ObtenerSubAreas');
        
        this.prmtr_Proposito_Seleccionar_Area = this.NavParams.get('prmtr_Proposito_Seleccionar_Area');
        this.slidemenu_option_elegida = slidemenu_options.find(element => element.id === this.prmtr_Proposito_Seleccionar_Area);
        
        
        this.usuario_nuevo = modelos_de_datos.usuario;
        this.usuario_nuevo.es_usuario_nuevo = true;
        this.usuario_nuevo.COD_AREA = this.prmtr_Area.COD_AREA;
        this.usuario_nuevo.COD_NIVEL_de_ACCESO = this.prmtr_Area.COD_NIVEL_de_ACCESO;


        this.DepuracionService.console_log("prmtrArea", this.prmtr_Area, "ver usuario")

        this.Cargar_y_Formatear_Usuarios(this.prmtr_Area, this.prmtr_ObtenerSubAreas)




        }

    ngOnInit() {
        }   



    Cargar_y_Formatear_Usuarios (prmtrArea, prmtrObtenerSubAreas) {
        /*
        "NOMBRE_AREA"                  "COD_AREA":                      "COD_AREA_PADRE"       "PROFUNDIDAD"      "LEVEL"     
        "es_ultima_rama_vertical"      "es_siguiente_nivel_superior"
        */
        //                       getUsuarios(prmtrCodUsuariom, prmtrCodArea         ,prmtrObtenerSubAreas, prmtrCodNivel)
        this.bbddUsuariosService.getUsuarios(null            , prmtrArea.COD_AREA   ,prmtrObtenerSubAreas, null).subscribe(result => {
            var array_data = result.data;               
            
            var array_data = array_data.map(function(item, index){ 
                //this.DepuracionService.console_log("array_areas length: " + array_areas.length, null, "crear arbol areas")
                if (!this.GeneralService.EstaVacia(item.FECHAHORA_INICIO_REGISTRO_HORARIO)) {
                    item.fechahora_inicio = moment(item.FECHAHORA_INICIO_REGISTRO_HORARIO).format("DD-MM-YYYY HH:mm") //OJO que este formato no es el standar
                    }
                if (!this.GeneralService.EstaVacia(item.FECHAHORA_FIN_REGISTRO_HORARIO)) {
                    item.fechahora_fin = moment(item.FECHAHORA_FIN_REGISTRO_HORARIO).format("DD-MM-YYYY HH:mm") //OJO que este formato no es el standar
                    }
                return item;
                }, this);    


            this.array_usuarios = array_data;
            });
        
        }


    //para enviar y recibir parametros entre paginas que no sean modales
    //https://edupala.com/ionic-angular-router-navigate/
    //https://ionicacademy.com/pass-data-angular-router-ionic-4/
    Abrir_Usuario_OBSOLETA(prmtrUsuario) {
        //let str_url = "/usuario/" + prmtrUsuario.COD_USUARIO //con esto casca ya que el "routing" no está preparado para parametros
        let str_url = "/usuario"
        this.DepuracionService.console_log("Abrir_Usuario(prmtrUsuario)" , prmtrUsuario, "ver usuario")

        //paso la información como  segundo parametro de...
        this.Router.navigate([str_url])
        /*
        this.Router.navigate([str_url], 
                             {
                                state: {
                                    prmtr_usuario: JSON.stringify(prmtrUsuario),
                                    }
                            });
        */
        }

    AbrirModal_Accion_sobre_Area_o_Usuario(prmtrObjetoUsuarioArea) {
        this.DepuracionService.console_log("this.prmtr_Proposito_Seleccionar_Area", this.prmtr_Proposito_Seleccionar_Area, "editar calendarios")
        this.DepuracionService.console_log("prmtrObjetoUsuarioArea", prmtrObjetoUsuarioArea, "editar calendarios")
        if (this.prmtr_Proposito_Seleccionar_Area == 'usuarios') {
            this.AbrirModal_Usuario(prmtrObjetoUsuarioArea)
            }

        if (this.prmtr_Proposito_Seleccionar_Area == 'calendarios') {
            this.AbrirModal_Editar_Calendario(prmtrObjetoUsuarioArea)
            }

        if (this.prmtr_Proposito_Seleccionar_Area == 'registros horarios') {
            this.AbrirModal_Editar_RegistrosHorarios(prmtrObjetoUsuarioArea)
            }

        if (this.prmtr_Proposito_Seleccionar_Area == 'informe computo de horas') {
            this.AbrirModal_Configurar_Informe_Computo_de_Horas (prmtrObjetoUsuarioArea)
            }
        }


    async AbrirModal_Usuario(prmtrUsuario) {

        //inicializo prmtrUsuario para evitar que si varias veces de alta un usuario coja los valores
        //de la vez anterior
        /*
        if (prmtrUsuario.es_usuario_nuevo) {
            //prmtrUsuario = this.GeneralService.Propiedades_a_null(prmtrUsuario)
            //lo anterior no sirve porque hay propiedades que deben tener un valor
            prmtrUsuario.es_usuario_nuevo = true;
            }
        */
        this.DepuracionService.console_log("prmtrUsuario", prmtrUsuario, "ver usuario")

        //console.log ("dentro de AbrirModal_Registrar_Hora_Inicio() ")
        const modal = await this.ModalController.create({
            component: UsuarioPage,
            cssClass: 'usuario'
            //, backdropDismiss: false //	If true, the modal will be dismissed when the backdrop is clicked.
            //para poder pasar "parametros" al modal...
            //encontrado en... https://medium.com/@david.dalbusco/how-to-declare-and-use-modals-in-ionic-v4-4d3f42ac30a3
            , componentProps: {
                'prmtr_usuario': prmtrUsuario
                , 'prmtr_Proposito_Seleccionar_Area': this.prmtr_Proposito_Seleccionar_Area
              }
        });
        
        await modal.present();
        
        modal.onDidDismiss().then((result: any) => {
            this.DepuracionService.console_log ("se ha cerrado la ventana modal de usuario", result, "ver usuario")

            if (!this.GeneralService.EstaVacia(result.data)) {
                if (result.data.actualizar_listado_usuarios == true) {
                    this.Cargar_y_Formatear_Usuarios (this.prmtr_Area, this.prmtr_ObtenerSubAreas);
                    }
                }
            });
        
        }

    async AbrirModal_Editar_Calendario (prmtrArea_o_Usuario) {
        this.DepuracionService.console_log("dentro de AbrirModal_Editar_Calendario", prmtrArea_o_Usuario, "editar calendarios")

        const modal = await this.ModalController.create({
            component: EditarCalendarioPage,
            cssClass: 'editar-calendario',
            //backdropDismiss: false
            componentProps: {
                'prmtr_area_o_usuario': prmtrArea_o_Usuario
                , 'prmtr_Proposito_Seleccionar_Area': this.prmtr_Proposito_Seleccionar_Area
                }
        });
        
        await modal.present();
        
        modal.onDidDismiss().then((result) => {
            });
        }


    async AbrirModal_Editar_RegistrosHorarios (prmtrUsuario) {
        this.DepuracionService.console_log("AbrirModal_Editar_RegistrosHorarios", prmtrUsuario, "editar registros horarios")

        const modal = await this.ModalController.create({
            component: EditarRegistrosHorariosPage,
            cssClass: 'editar-registros-horarios',
            //backdropDismiss: false
            componentProps: {
                'prmtr_usuario': prmtrUsuario
                , 'prmtr_Proposito_Seleccionar_Area': this.prmtr_Proposito_Seleccionar_Area
                }
        });
        
        await modal.present();
        
        modal.onDidDismiss().then((result) => {
            });
        }
    
    async AbrirModal_Configurar_Informe_Computo_de_Horas (prmtrUsuarioOArea) {
        this.DepuracionService.console_log("AbrirModal_Configurar_Informe_Computo_de_Horas > prmtrUsuarioOArea", prmtrUsuarioOArea, "configurar Informe Computo de Horas")
        this.DepuracionService.console_log("AbrirModal_Configurar_Informe_Computo_de_Horas > prmtr_Proposito_Seleccionar_Area", this.prmtr_Proposito_Seleccionar_Area, "configurar Informe Computo de Horas")

        const modal = await this.ModalController.create({
            component: ConfigurarInformeComputoDeHorasPage,
            cssClass: 'configurar-informe-computo-de-horas',
            //backdropDismiss: false
            componentProps: {
                'prmtr_usuario_o_area': prmtrUsuarioOArea
                , 'prmtr_Proposito_Seleccionar_Area': this.prmtr_Proposito_Seleccionar_Area
                }
        });
        
        await modal.present();
        
        modal.onDidDismiss().then((result) => {
            });
        }
    





    close() {
        this.ModalController.dismiss();
        }

    }
