import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment, modelos_de_datos } from 'src/environments/environment';
import { DepuracionService } from 'src/app/services/depuracion.service';
import { GeneralService } from 'src/app/services/general.service';

//añado la libreria moment tal como he encontrado aqui...
//...https://medium.com/@ferampe/angular-4-y-moment-js-cc6ceec00326
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class bbddRegistrosCalendariosService {

    constructor(private HttpClient: HttpClient
                , private DepuracionService: DepuracionService
                , private GeneralService: GeneralService
                , @Inject(LOCALE_ID) private locale: string) { 

        moment.locale('es');
        
        }



    getRegistrosCalendario (prmtrCodRegistroCalendario: number, prmtrCodArea:number, prmtrCodUsuario:number, prmtrHoraDesde:string, prmtrHoraHasta:string): Observable<any> {
        //function consulta_Registros_Calendario (prmtrCodClte, prmtrCodRegistroCalendario, prmtrCodArea, prmtrCodUsuario, prmtrFechaHoraDesde, prmtrFechaHoraHasta)
        //this.DepuracionService.console_log("dentro de getRegistrosCalendario", null, "cargar registros calendario")
        var strFuncionServidor = 'consulta_Registros_Calendario (' + environment.COD_CLIENTE + ', null, ' + prmtrCodArea  + ', ' + prmtrCodUsuario
                                                                   + ', "' + prmtrHoraDesde + '", "' + prmtrHoraHasta + '", prmtrJSONout)'
        //var strUrl = this.GeneralService.Componer_URL_Servidor(strFuncionServidor, true)
        //this.DepuracionService.console_log("strUrl", strUrl, "registros calendario area");
        //return this.HttpClient.get(strUrl);
        return this.GeneralService.Ejecutar_en_Servidor (strFuncionServidor, true, null);
        }
    
    //prmtrArea_o_Usuario: Podemos estar actualizando el calendario de un Area o de un Usuario 
    //prmtrRegistrosCalendario: Relación de Eventos (registros de calendario) que estamos guardando (vienen agrupados)
    //prmtrFechasLimite: Fecha con hora 00:00:00 del primer día y Fecha con hora 23:59:59 del último día que estamos modificando
    //                   necesario para borrar todolo que existia en esas fechas antes de guardar.
    save (prmtrArea_o_Usuario, prmtrRegistrosCalendario, prmtrFechasLimite): Observable<any> { 
        this.DepuracionService.console_log("dentro de bbdd-registros-calendarios.service.ts > save", prmtrRegistrosCalendario, "guardar calendario")
        let array_campos = []
        var date_1_inicio, date_1_fin;

        //Las sigueintes vbles contienen la configuración necesaria en función de si estamos actualizando el calendario de 
        //... un Area o un Usuario
        var str_nombre_COD_AREA_o_COD_USUARIO, int_COD_AREA_o_COD_USUARIO
        if (!this.GeneralService.EstaVacia(prmtrArea_o_Usuario.COD_USUARIO)) {
            str_nombre_COD_AREA_o_COD_USUARIO = "COD_USUARIO"
            int_COD_AREA_o_COD_USUARIO = prmtrArea_o_Usuario.COD_USUARIO
            }
        //if (!this.GeneralService.EstaVacia(prmtrArea_o_Usuario.COD_AREA)) {
        //La condición anterior no vale porque el usuario puede tener el campo COD_AREA, 
        //la que si vladría es...
        if (this.GeneralService.EstaVacia(prmtrArea_o_Usuario.COD_USUARIO)) {
            str_nombre_COD_AREA_o_COD_USUARIO = "COD_AREA"
            int_COD_AREA_o_COD_USUARIO = prmtrArea_o_Usuario.COD_AREA
            }

        prmtrRegistrosCalendario.forEach(element => {
            date_1_inicio = moment(element.startTime).format(environment.format_datehour);
            date_1_fin = moment(element.endTime).format(environment.format_datehour);
            array_campos.push([
                                {nombre_campo: "TITULO_REGISTRO_CALENDARIO"                         , valor_campo: ''                           , tipo_campo: "string"},
                                {nombre_campo: "FECHAHORA_INICIO_REGISTRO_CALENDARIO"               , valor_campo: date_1_inicio                , tipo_campo: "date"},
                                {nombre_campo: "FECHAHORA_FIN_REGISTRO_CALENDARIO"                  , valor_campo: date_1_fin                   , tipo_campo: "date"},
                                {nombre_campo: "COD_TIPO_DE_HORA"                                   , valor_campo: 1                            , tipo_campo: "int"},
                                {nombre_campo: "COD_CLIENTE"                                        , valor_campo: environment.COD_CLIENTE      , tipo_campo: "int"},
                                {nombre_campo: str_nombre_COD_AREA_o_COD_USUARIO                    , valor_campo: int_COD_AREA_o_COD_USUARIO   , tipo_campo: "int"},
                             ])
            });

        let data = {
            tipo_actualizacion: "INSERT" //INSERT o UPDATE
            , nombre_tabla: "TB_REGISTROS_CALENDARIOS"
            , nombre_campo_clave: "COD_REGISTRO_CALENDARIO"//hay que especificarlo para que devuelva el nuevo cod del registro que insertemos
            , COD_AREA_o_COD_USUARIO_nombre: str_nombre_COD_AREA_o_COD_USUARIO
            , COD_AREA_o_COD_USUARIO_valor: int_COD_AREA_o_COD_USUARIO
            , fecha_inicio: prmtrFechasLimite.date_inicio
            , fecha_fin: prmtrFechasLimite.date_fin
            , campos: array_campos
            }


        //var strUrl = this.GeneralService.Componer_URL_Servidor('prueba_RequestForm_Actualizar_Varios_Registros()', false);
        //var strUrl = this.GeneralService.Componer_URL_Servidor('Class_CrearConsultasBBDD_ACTUALIZAR_desde_JSON(null)', false);
        //No utilizo Class_CrearConsultasBBDD_ACTUALIZAR_desde_JSON ya que hace falta hacer algo mas, asi que...
        //var strUrl = this.GeneralService.Componer_URL_Servidor('Actualizar_TB_REGISTROS_CALENDARIOS()', false);
        //this.DepuracionService.console_log("strUrl", strUrl, "");
        ////let data = [{campo1: 'valor a1', campo2: 12}, {campo1: 'valor 21', campo2: 22}, {campo1: 'valor 31', campo2: 32}];
        //return this.HttpClient.post(strUrl, data, environment.http_post_options);
        return this.GeneralService.Ejecutar_en_Servidor ('Actualizar_TB_REGISTROS_CALENDARIOS()', false, data);

        //this.DepuracionService.console_log("antes de get", "", "");
        //return this.HttpClient.get(strUrl);        


        //this.DepuracionService.console_log("antes de post", "", "");
        /*
        var strUrl = environment.URL_JSON_ACCION + 'Registrar_Hora_de_Inicio()'
        let data = modelos_de_datos.registro_de_hora_inicial;
        data.hora_de_inicio = prmtrHoraInicio;
        data.hora_de_fin = null;
        data.cod_tipo_de_hora = (this.GeneralService.EstaVacia(prmtrTipoHora) == true)?null:prmtrTipoHora; //para que cod_tipo_de_hora por lo menos tenga null
        this.DepuracionService.console_log("data", data, "");
        */
        /*
        let data1 = {
            tipo_actualizacion: "INSERT" //INSERT o UPDATE
            , nombre_tabla: "TB_REGISTROS_CALENDARIOS"
            , nombre_campo_clave: "COD_REGISTRO_CALENDARIO"//hay que especificarlo para que devuelva el nuevo cod del registro que insertemos
            //, valor_campo_clave: null //ahora no se usa asi que podemos comentarlo pero permitiría hacer una inserción con un cod determinado
            , campos: [
                {nombre_campo: "CAMPO_1"                      , valor_campo: 1  , tipo_campo: "int"},
                {nombre_campo: "CAMPO_2"                      , valor_campo: 1  , tipo_campo: "int"},
                ]
            }

        var date_1_inicio = moment(Date()).format(environment.format_datehour + ':ss');
        var date_1_fin = moment(date_1_inicio).add(5, 'hours').format(environment.format_datehour + ':ss');

        var date_2_inicio = moment(date_1_inicio).add(1, 'days').format(environment.format_datehour + ':ss');
        var date_2_fin = moment(date_2_inicio).add(6, 'hours').format(environment.format_datehour + ':ss');

        let data2 = {
            tipo_actualizacion: "INSERT" //INSERT o UPDATE
            , nombre_tabla: "TB_REGISTROS_CALENDARIOS"
            , nombre_campo_clave: "COD_REGISTRO_CALENDARIO"//hay que especificarlo para que devuelva el nuevo cod del registro que insertemos
            , campos: [
                            [
                            {nombre_campo: "TITULO_REGISTRO_CALENDARIO"                         , valor_campo: 'prueba 1'               , tipo_campo: "string"},
                            {nombre_campo: "FECHAHORA_INICIO_REGISTRO_CALENDARIO"               , valor_campo: date_1_inicio            , tipo_campo: "date"},
                            {nombre_campo: "FECHAHORA_FIN_REGISTRO_CALENDARIO"                  , valor_campo: date_1_fin               , tipo_campo: "date"},
                            {nombre_campo: "COD_TIPO_DE_HORA"                                   , valor_campo: 1                        , tipo_campo: "int"},
                            {nombre_campo: "COD_CLIENTE"                                        , valor_campo: environment.COD_CLIENTE  , tipo_campo: "int"},
                            {nombre_campo: "COD_USUARIO"                                        , valor_campo: environment.usuario.cod  , tipo_campo: "int"},
                            ]
                            , [
                            {nombre_campo: "TITULO_REGISTRO_CALENDARIO"                         , valor_campo: 'prueba 2'                , tipo_campo: "string"},
                            {nombre_campo: "FECHAHORA_INICIO_REGISTRO_CALENDARIO"               , valor_campo: date_2_inicio             , tipo_campo: "date"},
                            {nombre_campo: "FECHAHORA_FIN_REGISTRO_CALENDARIO"                  , valor_campo: date_2_fin                , tipo_campo: "date"},
                            {nombre_campo: "COD_TIPO_DE_HORA"                                   , valor_campo: 1                         , tipo_campo: "int"},
                            {nombre_campo: "COD_CLIENTE"                                        , valor_campo: environment.COD_CLIENTE   , tipo_campo: "int"},
                            {nombre_campo: "COD_USUARIO"                                        , valor_campo: environment.usuario.cod   , tipo_campo: "int"},
                            ]
                      ]
            }
        return this.HttpClient.post(strUrl, data2, environment.http_post_options);
        */




        }




    } //export class bbddRegistrosCalendariosService
