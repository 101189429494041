/*
para controlar que no se acceda a una pagina sin haber hecho login sigo el tutorial..
(hay mas pero este es el mas claro que he encontrado y es importante porque no es sencillo)
https://www.freakyjolly.com/ionic-angular-guards-tutorial/#.X1tSKdRS-Uk
*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { DepuracionService } from 'src/app/services/depuracion.service'; 
import { ToastService } from 'src/app/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

    authState = new BehaviorSubject(false); //esto es un tipo raro que usan en todos los sitios que explican como
                                            //controlar si los usuarios estan logeados o no

    constructor(
        private HttpClient: HttpClient,
        private Router: Router,
        private DepuracionService: DepuracionService,
        private StorageService: StorageService,
        private Platform: Platform,
        private ToastService: ToastService,
        ) { 
            this.Platform.ready().then(() => {
                this.Inicializar_authState_Si_Esta_Logeado();
              });
        }

    //ifLoggedIn() {
    Inicializar_authState_Si_Esta_Logeado() {
        this.DepuracionService.console_log("dentro de Inicializar_authState_Si_Esta_Logeado", null, "login")
        this.StorageService.get('usuario').then((response) => {
            if (response) {
                this.authState.next(true);
                }
            });
        }

    //Login(prmtrUsuario: string, prmtrClave: string): Observable<any> {
    Login(prmtrUsuario: string, prmtrClave: string) {
        //paso a mandar el usuario-clave por post para mas seguridad
        var strUrl = environment.URL_JSON_ACCION + 'Login_v0(null, null)';
        this.DepuracionService.console_log("strUrl login", strUrl, "login")
        //return this.HttpClient.get(strUrl);
        //this.GeneralService.Login (this.usuario, this.clave).subscribe(result => {
        var data = {
                    usuario: prmtrUsuario,
                    clave: prmtrClave
                    }

        //this.ToastService.prueba()
        
        //console.log ("authentication.service.ts > login > strUrl", strUrl)
        //ejemplo de como se llamaraía con querystring
        //...http://pc/registrohorario/asp/ejecutar_ajax.asp?Accion=Login_v0('usuario','clave')
        //despues de comprobarlo ya lo haré con "post"
        //var strUrl = environment.URL_JSON_ACCION + 'Login_v0("' + prmtrUsuario + '","' + prmtrClave + '")';
        //this.HttpClient.get(strUrl).subscribe(result => {
        this.HttpClient.post(strUrl, data, environment.http_post_options).subscribe(result => {
            var obj_result:any = result; //para que no de error al acceder a result.data, ya que no puedo definir "result" como tipo "any"
            var str_ruta:string;
            //this.DepuracionService.console_log ("Login > result: ", result, "login")
            this.DepuracionService.console_log ("Login > result.data.length: ", obj_result.data.length, "login")

            //var obj_usuario:any; //hay que definirlo any para que no casque al asignar la primera propiedad
            //tampoco asi funciona, hay que definir sus propiedades
            var obj_usuario = {cod: null, usuario: null, clave: null, nombre_completo: null, nivel: null, nombre_nivel: null, cod_area: null}

            if (obj_result.data.length == 0) {
                //this.StorageService.set('usuario', '');
                //this.StorageService.set('clave', '');
                this.ToastService.show("El usuario-clave proporcionado no es valido.")
                
                str_ruta = '/login'
                }
            if (obj_result.data.length > 0) {
                this.authState.next(true);
                //this.StorageService.set('usuario', prmtrUsuario)
                //this.StorageService.set('clave', btoa(prmtrClave)) // codifico la clave para poner un poco mas complicado su "hackeo"
                                                                   // para decodificarla... atob(), ver ejemplo siguiente...
                                                                   // this.StorageService.get('clave').then((value) => {
                                                                   //     this.DepuracionService.console_log ("Login > clave: ", value, "login")
                                                                   //     this.DepuracionService.console_log ("Login > atob(clave): ", atob(value), "login")
                                                                   //     });
                obj_usuario.cod             = obj_result.data[0].COD_USUARIO;
                obj_usuario.usuario         = prmtrUsuario;
                obj_usuario.clave           = btoa(prmtrClave)
                obj_usuario.nombre_completo = obj_result.data[0].NOMBRE_USUARIO + ' ' 
                                              + obj_result.data[0].APELLIDO1_USUARIO + ' ' 
                                              + obj_result.data[0].APELLIDO2_USUARIO;
                obj_usuario.nivel           = obj_result.data[0].NIVEL_de_ACCESO
                obj_usuario.nombre_nivel    = obj_result.data[0].NOMBRE_NIVEL_de_ACCESO
                obj_usuario.cod_area        = obj_result.data[0].COD_AREA
                str_ruta = '/home';
                //aunque con las primeras pruebas que he realizado intentando conectarme con unha fecha y hora diferentes me lanza el error
                //strict-origin-when-cross-origin
                //que está explicado aqui...
                //https://www.google.com/search?q=strict-origin-when-cross-origin&oq=strict-origin-when-cross-origin&aqs=chrome..69i57.1142j0j7&sourceid=chrome&ie=UTF-8
                //y que dice "No se puede establecer una conexión privada con www.google.com porque la fecha y la hora de tu ordenador ... no son correctas"
                //obtengo la hora del servidor, mas seguridad aún.
                environment.conf_calendario.currentDate = new Date(obj_result.data[0].FECHAHORA_ACTUAL_SERVIDOR)
                environment.conf_calendario.currentDate2 = new Date(obj_result.data[0].FECHAHORA_ACTUAL_SERVIDOR)
                //environment.conf_calendario.currentDate = new Date()
                }
            
            environment.usuario = obj_usuario;

            this.DepuracionService.console_log("authentication.service > environment.usuario (" + Date() + ")", environment.usuario, "environment usuario")
            this.DepuracionService.console_log("cargo la pagina...", str_ruta, "environment usuario")

            this.StorageService.set('usuario', obj_usuario);

            //truco encontrado en...
            //...https://www.ionicanddjangotutorial.com/ionic-navigating-same-url/
            //para intentar forzar que se recarge la pagina hacia la que navegamos
            //this.Router.navigateByUrl(str_ruta, {skipLocationChange: true}).then(() => {
            //    this.Router.navigate([str_ruta]);
            //    });
            //this.DepuracionService.console_log("depues de truco para intentar recargar", null, "environment usuario")
            //Al final el problema era como recargaba la app en el navegador para probar
            //Tengo que actualizar en la pagina de login para que no se lie
            //habrá que fijarse en moviles a ver que tal funciona.
            this.Router.navigate([str_ruta]);

            });

        

        
        } //Login

    Logout() {
        //this.StorageService.set('usuario', null);//por si acaso, porque me ha parecido que el remove siguiente no va bien
        this.StorageService.remove('usuario').then(() => {
            environment.usuario = null;
            this.Router.navigate(['/login']);
            this.authState.next(false);
            });
        /*
        this.StorageService.remove('usuario').then(() => {
            this.StorageService.remove('clave').then(() => {
                this.Router.navigate(['/login']);
                this.authState.next(false);
                });
            });
        */
        }

    isAuthenticated() {
        let isAuthenticated_return = this.authState.value;
        //this.DepuracionService.console_log("this.authState", this.authState, "login")
        //this.DepuracionService.console_log("isAuthenticated_return", isAuthenticated_return, "login")
        return isAuthenticated_return;
        }


    }
