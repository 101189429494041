import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen }                                 from '@ionic-native/splash-screen/ngx';
import { StatusBar }                                    from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// hasta aqui son los import que vienen por defecto

//NOTA: este hace falta añadiro para que se puedan añadir modulos en el constructor de app.component.ts
import { HttpClientModule }                             from '@angular/common/http';

//para que funcione el siguiente modulo antes hay que hacer en el cmd "npm install @ionic/storage"
//ver ...https://www.npmjs.com/package/@ionic/storage
//    ...https://github.com/ionic-team/ionic-storage
import { IonicStorageModule }                           from '@ionic/storage-angular';

//segun...
//https://medium.com/@lsmod/ionic-forms-validation-get-started-with-reactive-form-b15cf814d506
//https://www.javatuto.com/angular/solution-for-no-provider-for-formbuilder/
//otras posible forma de hacer form control es https://www.pluralsight.com/guides/using-formbuilder-in-angular
//incluyo aqui lo siguiente...
//import { FormsModule, ReactiveFormsModule } from "@angular/forms";
//aqui se explica mejor
//https://jasonwatmore.com/post/2018/05/10/angular-6-reactive-forms-validation-example
//pero OJO, aunque ahi no está hay que poner los 2 siguientes elementos "FormsModule, ReactiveFormsModule"
import { FormsModule, ReactiveFormsModule }             from "@angular/forms";
/*
para controlar que no se acceda a una pagina sin haber hecho login sigo el tutorial..
(hay mas pero este es el mas claro que he encontrado y es importante porque no es sencillo)
https://www.freakyjolly.com/ionic-angular-guards-tutorial/#.X1tSKdRS-Uk
de ahí las siguietnes 2 lineas
*/

import { AuthGuard }   from './services/auth-guard.service';
import { AuthenticationService }                        from './services/authentication.service';
//import { ServiceWorkerModule }                          from '@angular/service-worker';
import { environment }                                  from '../environments/environment';
//import { PopoverBorrarUsuarioComponent }                from './components/popovers/popover-borrar-usuario/popover-borrar-usuario.component'

//* NOTA mostrar todo como en android
//para cambiar las configuraciones de "estilos generales", por ejemplo que se comporte como 
//ios o como android (aqui lo llama md, no se porque), cambio según explica aqui...
//...https://stackoverflow.com/questions/46705791/how-can-ionic-be-setup-to-render-in-the-ios-style-by-default
//...https://forum.ionicframework.com/t/how-to-set-the-whole-app-in-androids-style-even-if-on-ios-in-ionic/42504/15

import { OneSignal } from '@ionic-native/onesignal/ngx';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule 
            , FormsModule
            , ReactiveFormsModule
            , IonicModule.forRoot({
                mode:'md' //* NOTA mostrar todo como en android
                })
            , IonicStorageModule.forRoot()
            //, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
            //, PopoverBorrarUsuarioComponent
            ],
  providers: [
    StatusBar,
    SplashScreen,
    AuthGuard, 
    AuthenticationService, 
    OneSignal,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]

})
export class AppModule {}
