//para hacer las llamadas a BBDD implementado aqui he seguido el tutorial...
//...https://dcarvajal7.es/llamando-a-servicios-rest-desde-ionic-4/


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
    })
export class TiposDeHoraService {

    constructor(private http: HttpClient) { }


    getTiposDeHora(): Observable<any> {
        //return this.http.get('https://jsonplaceholder.typicode.com/albums');
        //return this.http.get('http://pc/registrohorario/asp/ejecutar_ajax.asp?accion=tipos_de_hora(null)');
        //llamando a una function creada exproceso para esta información
        //'return this.http.get(environment.URL_JSON_ACCION + 'tipos_de_hora_v0(null)');
        //llamando a una function genérica 
        return this.http.get(environment.URL_JSON_ACCION + 'Obtener_JSON_v0("consulta_Tipos_de_Hora(null)")');
        }
    
    /*
    getTipoDeHora(id): Observable<any> {
        return this.http.get('https://jsonplaceholder.typicode.com/albums/' + id);
        }
    */

    }
