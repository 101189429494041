/*
para controlar que no se acceda a una pagina sin haber hecho login sigo el tutorial..
(hay mas pero este es el mas claro que he encontrado y es importante porque no es sencillo)
https://www.freakyjolly.com/ionic-angular-guards-tutorial/#.X1tSKdRS-Uk
*/

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-guard.service';



const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
        canActivate: [AuthGuard] //para evitar que pueda entrar aqui si no esta logeado (ver url de arriba)
    },
    {
        path: 'tipos-de-hora',
        loadChildren: () => import('./pages/tipos-de-hora/tipos-de-hora.module').then( m => m.TiposDeHoraPageModule),
        canActivate: [AuthGuard] //para evitar que pueda entrar aqui si no esta logeado (ver url de arriba)
    },
    /*
    {
        path: 'tipo-de-hora',
        loadChildren: () => import('./pages/tipo-de-hora/tipo-de-hora.module').then( m => m.TipoDeHoraPageModule),
        canActivate: [AuthGuard] //para evitar que pueda entrar aqui si no esta logeado (ver url de arriba)
    },
    */
    {
        path: 'registrar-hora-inicio',
        loadChildren: () => import('./pages/registrar-hora-inicio/registrar-hora-inicio.module').then( m => m.RegistrarHoraInicioPageModule),
        canActivate: [AuthGuard] //para evitar que pueda entrar aqui si no esta logeado (ver url de arriba)
    },
    {
        path: 'registrar-hora-fin',
        loadChildren: () => import('./pages/registrar-hora-fin/registrar-hora-fin.module').then( m => m.RegistrarHoraFinPageModule),
        canActivate: [AuthGuard] //para evitar que pueda entrar aqui si no esta logeado (ver url de arriba)
    },
    {
        path: 'pruebas',
        loadChildren: () => import('./pages/pruebas/pruebas.module').then( m => m.PruebasPageModule)
    },
    {
        path: 'seleccionar-area',
        loadChildren: () => import('./pages/seleccionar-area/seleccionar-area.module').then( m => m.SeleccionarAreaPageModule)
    },
    {
        path: 'usuarios',
        loadChildren: () => import('./pages/usuarios/usuarios.module').then( m => m.UsuariosPageModule)
    },
    {
        path: 'usuario',
        loadChildren: () => import('./pages/usuario/usuario.module').then( m => m.UsuarioPageModule)
    },
  {
    path: 'editar-calendario',
    loadChildren: () => import('./pages/editar-calendario/editar-calendario.module').then( m => m.EditarCalendarioPageModule)
  },
  {
    path: 'editar-registros-horarios',
    loadChildren: () => import('./pages/editar-registros-horarios/editar-registros-horarios.module').then( m => m.EditarRegistrosHorariosPageModule)
  },
  {
    path: 'editar-registro-horario',
    loadChildren: () => import('./pages/editar-registro-horario/editar-registro-horario.module').then( m => m.EditarRegistroHorarioPageModule)
  },
  {
    path: 'informes',
    loadChildren: () => import('./pages/informes/informes.module').then( m => m.InformesPageModule)
  },
  {
    path: 'configurar-informe-computo-de-horas',
    loadChildren: () => import('./pages/configurar-informe-computo-de-horas/configurar-informe-computo-de-horas.module').then( m => m.ConfigurarInformeComputoDeHorasPageModule)
  },
  {
    path: 'informe-computo-de-horas',
    loadChildren: () => import('./pages/informe-computo-de-horas/informe-computo-de-horas.module').then( m => m.InformeComputoDeHorasPageModule)
  },
];


//console.log ("routes", JSON.stringify(routes, null, "\t"))


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    //mirando
    //...https://stackoverflow.com/questions/56052328/how-to-reload-a-page-in-ionic-4-using-router
    //he probado a añadir "onSameUrlNavigation: 'reload'" para que al identificarse un nuevo usuario recarge home pero no va
    //...RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
    //otra direccion interesante es
    //...https://www.damirscorner.com/blog/posts/20200214-ForcingReloadOfRootPageInIonic4.html

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
