
/*
para controlar que no se acceda a una pagina sin haber hecho login sigo el tutorial..
(hay mas pero este es el mas claro que he encontrado y es importante porque no es sencillo)
https://www.freakyjolly.com/ionic-angular-guards-tutorial/#.X1tSKdRS-Uk

en lo que atañe a este fichero se hacen unos cambios para que este servicio ahora sea un CanActivate
mirar la url anterior
*/


import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from './authentication.service';
//import { DepuracionService} from 'src/app/services/depuracion.service'


@Injectable(
    //{providedIn: 'root'}
    )
export class AuthGuard implements CanActivate {
    constructor(
        public authenticationService: AuthenticationService
        //, private DepuracionService: DepuracionService
        ) {

        }
    
    canActivate(): boolean {
        let canActivate_return = this.authenticationService.isAuthenticated();
        //this.DepuracionService.console_log("canActivate_return", canActivate_return, "login")
        return this.authenticationService.isAuthenticated();
        //return true; //para pruebas
        }
    
}