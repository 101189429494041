import { Component, AfterViewInit, ViewChild, OnInit, OnChanges, Inject, LOCALE_ID }        from '@angular/core';
import { ModalController, NavParams}                                                        from '@ionic/angular';
import { CalendarComponent }                                                                from 'ionic2-calendar';
import { environment, modelos_de_datos, slidemenu_options }                                 from 'src/environments/environment';
import { GeneralService }                                                                   from 'src/app/services/general.service';
import { DepuracionService }                                                                from 'src/app/services/depuracion.service';
import { bbddRegistrosCalendariosService }                                                  from 'src/app/services/bbdd-registros-calendarios.service';
import { ToastService }                                                                     from 'src/app/services/toast.service';

import * as moment from 'moment';

@Component({
  selector: 'app-editar-calendario',
  templateUrl: './editar-calendario.page.html',
  styleUrls: ['./editar-calendario.page.scss', '../../../calendar.scss'],
})
export class EditarCalendarioPage implements AfterViewInit {

    prmtr_area_o_usuario:any;
    title_area_o_usuario:any; //para guardar la información que muestro en el title sin modificar prmtr_usuario ya que si 
                              //doy de alta 2 veces un usuario nuevo la segunda dicha información modificada de prmtr_usuario
                              //apareceria modificada en el formulario
    slidemenu_option_elegida: any;

    calendario_modificado: boolean; //para controlar si está habilitado o no el botón de guardar
    son_registros_de_usuario: boolean;
    son_registros_de_area: boolean;
    semana_mostrada: string;

    //-----------------------------------------------
    //propiedades necesarias para el calendario

    str_Anio: string;
    str_Mes: string;
    int_Semana_del_mes: number;
    str_Semana_del_anio: string;
    str_Dia: string;
    str_DiaSemana: string;

    array_horas_seleccionadas: [];

    //You might have noticed the strange modalReady variable already. 
    //I couldn’t make the calendar work correctly when deployed to a device, since I assume the view wasn’t rendered correctly and something internally broke.
    //By hiding it until the view has finished loading we can easily fix this, and in reality you actually won’t even notice it!
    modalReady = false;

    eventSource = [];

    conf_calendario = environment.conf_calendario; 
    //lo hago de esta otra forma para intentar que sea independiente del de home
    //conf_calendario = {...environment.conf_calendario}; 
    //no utilizo lo anterior porque producia un mal funcionamiento (no cargaba los calendarios cuando me movia entre fechas)
    //que no me he parado a valorar
    

    @ViewChild(CalendarComponent) obj_calendario_calendario: CalendarComponent; 

    constructor(
        private DepuracionService: DepuracionService
        , private GeneralService: GeneralService
        , private ModalController: ModalController
        , private NavParams: NavParams
        , private bbddRegistrosCalendariosService: bbddRegistrosCalendariosService
        , private ToastService: ToastService
        , @Inject(LOCALE_ID) private locale: string
        ) { 

        moment.locale('es');


        this.DepuracionService.console_log("constructor > this.conf_calendario.currentDate2", this.conf_calendario.currentDate2, "cargar registros calendario")

        this.prmtr_area_o_usuario = this.NavParams.get('prmtr_area_o_usuario');
        this.DepuracionService.console_log("this.prmtr_area_o_usuario", this.prmtr_area_o_usuario,  "editar calendarios")
        this.title_area_o_usuario = {...this.prmtr_area_o_usuario}; //para "clonar" el objeto y que sea uno nuevo
        this.DepuracionService.console_log("this.title_area_o_usuario", this.title_area_o_usuario,  "editar calendarios")

        //para buscar un elemento de un array por una propiedad de los objetos de dicho elemento, encontrado en...
        this.slidemenu_option_elegida = slidemenu_options.find(element => element.id === 'calendarios');

        this.calendario_modificado = false;

        //señalo si los registros mostrados en el calendario son de usuario o de area
        this.son_registros_de_usuario = false;
        this.son_registros_de_area = false;

        this.conf_calendario.mode = "week";
        this.conf_calendario.currentDate2 = null //para intentar diferenciar de cuando se esta entrando la primera vez de cuando se está navegando entre fechas

        }

    ngAfterViewInit() {
        setTimeout(() => {
            this.modalReady = true;      
            }, 0);

        //para hacer pruebas
        //this.createRandomEvents()        

        //this.Cargar_Calendario(null); //no hace falta ya que se llama en onCurreDateChanged

        
        }

    onViewTitleChanged(title) {
        //this.viewTitle = title;
        }

    //aprovecho este evento de calendar para gestionar que horas son las que pertenecen al calendario actual.
    //según se clicke sobre una hora se selecciona/deselecciona para el calendario actual.

    onCurrentDateChanged_calendario(event){
        this.DepuracionService.console_log("dentro de onCurrentDateChanged_calendario", event, "consultas duplicadas")
        this.DepuracionService.console_log("dentro de onCurrentDateChanged_calendario", event, "cargar registros calendario")
        //cargo las actividades existentes en este mes
        var this_currentDate2_anterior, this_currentDate2_actual, mes_anterior, mes_actual, semana_anterior, semana_actual
        var hace_falta_recargar = false

        //console.log ("0) this.conf_calendario.currentDate2", this.conf_calendario.currentDate2)
        this_currentDate2_anterior = this.conf_calendario.currentDate2
        this.conf_calendario.currentDate2 = event;
        //console.log ("1) this.conf_calendario.currentDate2", this.conf_calendario.currentDate2)
        this_currentDate2_actual = this.conf_calendario.currentDate2

        //this.DepuracionService.console_log("0) this.conf_calendario.currentDate2", this.conf_calendario.currentDate2, "consultas duplicadas")
        this.calendario_modificado = false;
        //this.conf_calendario.loadEvents();
        this.DepuracionService.console_log("dentro de onCurrentDateChanged_calendario", event, "editar calendarios");
        this.DepuracionService.console_log("this.conf_calendario.mode", this.conf_calendario.mode, "editar calendarios");
        this.DepuracionService.console_log("dentro de onCurrentDateChanged_calendario", "", "cargar registros duplicado")
        //console.log (event);
        var strAnio, strMes, strDia, strSemana, strDiaSemana
        //con las siguientes lineas lo obtento de forma "starndar"
        
        //strAnio = event.getFullYear();
        //strMes = event.getMonth() + 1; //ya que enero = 0
        ////this.Dia = event.getDay(); // ¿¿ no se porque no devuelve el dia actual ?? en cambio uso...
        //strDia = event.getDate(); 
        
        //pero como quiero maquetarlo lo obtengo con moment
        strAnio = moment(event).format('YYYY');
        strMes = moment(event).format('MMMM');
        strSemana = moment(event).format('w'); //semana del año
        strDia = moment(event).format('D');
        //strDiaSemana = moment(event).format('ddd'); 
        //strDiaSemana = moment(event).format('dd'); 
        strDiaSemana = moment(event).format('dd').toUpperCase(); 

        //console.log ( moment(event).format('LLLL')); //para mosrtrar la fecha completa y ver que lo pone en castellano
        //para evitar el error "ExpressionChangedAfterItHasBeenCheckedError"
        //parentizo con settimeout pero entonces hay problemas de accesso a this, que soluciono con lo encontrado aqui...
        //...https://stackoverflow.com/questions/2130241/pass-correct-this-context-to-settimeout-callback
        //setTimeout(function(){ 
        //    this.str_Anio = strAnio
        //    }, 2000, this); //añado este this final para que dentro del settimeout se accdeda al correcto
        //...este anterior no va pero el siguiente si que va...
        //setTimeout(() => { ...codigo aqui... }, 200);
        setTimeout(() => { 
            this.str_Anio = strAnio 
            this.str_Mes = strMes
            this.str_Semana_del_anio = strSemana
            this.int_Semana_del_mes = this.GeneralService.Semana_del_Mes (event);
            
            //setTimeout(function(){ 
                //console.log ("cambio al día... " + strDia)
                this.str_Dia = strDia
                this.str_DiaSemana = strDiaSemana
            //    }
            //    , 3000);
            //this.DepuracionService.console_log("2) this.conf_calendario.currentDate2", this.conf_calendario.currentDate2, "consultas duplicadas")
            }, 0); //con poner 0 es suficiente para evitar el ExpressionChangedAfterItHasBeenCheckedError

        //this.DepuracionService.console_log("llamada 1 a this.Cargar_Calendario", null, "consultas duplicadas")
        this.DepuracionService.console_log("1) this.conf_calendario.currentDate2", this.conf_calendario.currentDate2, "consultas duplicadas")


        //ojo, con lo siguiente se carga el calendario incluso si no he cambiado a una fecha diferente a la vista en la que estamos
        //por ejemplo, 
        //si estoy en el martes de una semana y pulso el jueves la vista no varia pero se recarga
        //solo debiera producirse si, en este caso, se está cambiando a otra semana
        //lo controlo asi...
        mes_anterior = moment(this_currentDate2_anterior).format('MMMM');
        mes_actual = moment(this_currentDate2_actual).format('MMMM');
        semana_anterior = moment(this_currentDate2_anterior).format('w');
        semana_actual = moment(this_currentDate2_actual).format('w');
        this.DepuracionService.console_log("this_currentDate2_anterior", this_currentDate2_anterior, "cargar registros calendario")
        this.DepuracionService.console_log("this_currentDate2_actual", this_currentDate2_actual, "cargar registros calendario")
        this.DepuracionService.console_log("mes_anterior", mes_anterior, "cargar registros calendario")
        this.DepuracionService.console_log("mes_actual", mes_actual, "cargar registros calendario")
        this.DepuracionService.console_log("semana_anterior", semana_anterior, "cargar registros calendario")
        this.DepuracionService.console_log("semana_actual", semana_actual, "cargar registros calendario")
        if (this.conf_calendario.mode == "month") {hace_falta_recargar = (mes_anterior != mes_actual)}
        if (this.conf_calendario.mode == "week") {hace_falta_recargar = (semana_anterior != semana_actual)}
        this.DepuracionService.console_log("hace_falta_recargar", hace_falta_recargar, "cargar registros calendario")
        //falta de controlar cuando se carga por primera vez, para ello inicializo this.currentDate2 a null en constructor
        //asi la primera vez tb es diferente y lo carga
        if (hace_falta_recargar) {
            this.Cargar_Calendario (event)
            }
        

        //
        }

    async onRangeChanged_calendario(event) {
        this.DepuracionService.console_log("dentro de onRangeChanged_calendario", event, "cargar registros calendario")
        this.Cargar_Calendario (this.conf_calendario.currentDate2)
        }



    onTimeSelected_calendario(event) {  
        console.log ("this.conf_calendario", this.conf_calendario)  
        if (this.conf_calendario.mode == "week") {
            console.log ("antes de this.Des_Seleccionar_Hora (event)")  
            this.Des_Seleccionar_Hora (event)
            }
        
        }

    Des_Seleccionar_Hora (prmtrEvent) {
        //this.event.startTime = new Date(ev.selectedTime);
        //this.DepuracionService.console_log("onTimeSelected", ev, "seleccionar horas en calendario")
        var date = prmtrEvent.selectedTime;
        var startTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), 0 );
        var endTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + 1, 0 );
        var events = [...this.eventSource]; //para "clonar" el array y que sea uno nuevo
        var event = {
            title: '',
            startTime: startTime,
            endTime: endTime,
            allDay: false,
            };

        //para buscar un elemento de un array por una propiedad de los objetos de dicho elemento, encontrado en...
        //var bln_hora_ya_seleccionada = events.find(element => element.startTime === event.startTime);
        //es mas legible y rastreable para mi hacerlo con la nomenclatura "clasica"
        // method (return element > 0). 
        /*
        var obj_hora_ya_seleccionada = events.find(function (element) { 
            return element.startTime.getTime() === event.startTime.getTime(); //para comparar fechas hay que convertirlas a numero primero con ".getTime()"
            }); 
        var bln_hora_ya_seleccionada = !this.GeneralService.EstaVacia(obj_hora_ya_seleccionada);
        */
        //directametne borro el elemento si existe igual y comparo si ahora la longitud de events es diferente para saber si he borrado
        var events_length = events.length;
        events = events.filter(element => element.startTime.getTime() !== event.startTime.getTime());
        //si tiene la misma longitud que antes del filter significa que no he borrado ningún elemento y por tanto hay que añadirlo
        //no entiendo muy bien esta ezplicación pero será ensayo-error
        if (events_length == events.length) {
            events.push(event);
            }
            
        setTimeout(() => {                
            this.eventSource = events;
            }, 0);

        this.calendario_modificado = true;

        this.DepuracionService.console_log("this.eventSource", this.eventSource, "seleccionar horas en calendario")
        this.DepuracionService.console_log("this.eventSource.length", this.eventSource.length, "seleccionar horas en calendario")
        }
    

    next() {
        this.obj_calendario_calendario.slideNext();
        }
    
    back() {
        this.obj_calendario_calendario.slidePrev();
        }

    close(prmtrDismiss) {
        this.ModalController.dismiss(prmtrDismiss);
        }

    //prmtrFecha: indica la fecha respecto a la cual que busca el limite inferior y susperior.
    Cargar_Calendario (prmtrFecha) {
        this.DepuracionService.console_log("dentro de Cargar_Calendario (" + prmtrFecha + ")", null, "cargar registros calendario")
        var fechas_limite = this.GeneralService.Fechas_Limite_segun_tipo_vista(prmtrFecha, this.conf_calendario.mode)    
        var registros, registros_area, registros_usuario, registros_desagrupados;
        var int_COD_AREA, int_COD_USUARIO
        if (!this.GeneralService.EstaVacia(this.prmtr_area_o_usuario.COD_USUARIO)) {
            //prmtr_area_o_usuario es USUARIO
            int_COD_AREA = this.prmtr_area_o_usuario.COD_AREA
            int_COD_USUARIO = this.prmtr_area_o_usuario.COD_USUARIO
            }
        else {
            //prmtr_area_o_usuario es AREA
            int_COD_AREA = this.prmtr_area_o_usuario.COD_AREA
            }

        
        //getRegistrosCalendario (prmtrCodRegistroCalendario: number, prmtrCodArea:number, prmtrCodUsuario:number, prmtrHoraDesde:string, prmtrHoraHasta:string)
        this.bbddRegistrosCalendariosService.getRegistrosCalendario(null, int_COD_AREA, int_COD_USUARIO, fechas_limite.date_inicio, fechas_limite.date_fin).subscribe(result => {
            this.DepuracionService.console_log("dentro de this.bbddRegistrosCalendariosService.getRegistrosCalendario ", result.datos_extra , "consultas duplicadas")
            //--------------------------------------------------------
            //recibo tanto los registros de USUARIO como los de CALENDARIO, primero miro a ver si ha de los primeros y sino uso los de AREA
            //2021_02_05: ya NO se reciben ambos tipos de registros sino que se discrimina en el servidor, asi que modifico lo siguiente...
            /*
            registros = result.data
            registros_area = registros.filter(element => element.TIPO_REGISTRO == 'REGISTRO CALENDARIO AREA');
            registros_usuario = registros.filter(element => element.TIPO_REGISTRO == 'REGISTRO CALENDARIO USUARIO');
            if (registros_usuario.length > 0) {
                registros = registros_usuario
                this.son_registros_de_usuario = true
                this.son_registros_de_area = false
                }
            //OJO, hay que poner el else , para que predomine el calendario de usuario si están los 2 definidos.
            else if (registros_area.length > 0) {
                registros = registros_area
                this.son_registros_de_usuario = false
                this.son_registros_de_area = true
                }
            */
            //...por...
            registros = result.data
            this.DepuracionService.console_log("result.datos_extra ", result.datos_extra , "Adaptar Fechas a Semana Actual")
            if (result.datos_extra.bln_hay_registros_usuario) {
                this.son_registros_de_usuario = true
                this.son_registros_de_area = false
                }
            //OJO, hay que poner el else , para que predomine el calendario de usuario si están los 2 definidos.
            else if (result.datos_extra.bln_hay_registros_area) {
                this.son_registros_de_usuario = false
                this.son_registros_de_area = true
                }

            //obtengo la semana que se está mostrando si no es la actual
            this.semana_mostrada = "";
            if (
                (!result.datos_extra.bln_hay_registros_usuario_semana_actual && result.datos_extra.bln_hay_registros_usuario_semana_anterior)
                ||
                (!result.datos_extra.bln_hay_registros_usuario && !result.datos_extra.bln_hay_registros_area_semana_actual && result.datos_extra.bln_hay_registros_area_semana_anterior)
                ) {
                    this.semana_mostrada = "es una semana anterior";
                    //element.startTime = new Date(str_start_time);
                    //int_dia_de_semana = moment(element.startTime).isoWeekday();
                    //str_dia = moment(environment.conf_calendario.currentDate2).day(int_dia_de_semana).format("YYYY-MM-DD");
                    //str_hora = moment(element.startTime).format("HH:mm:ss");
                    this.DepuracionService.console_log("num semana anterior", result.datos_extra.int_num_semana_con_registros_a_obtener, "Adaptar Fechas a Semana Actual")
                    this.DepuracionService.console_log("fecha semana anterior", moment().week(result.datos_extra.int_num_semana_con_registros_a_obtener-1), "Adaptar Fechas a Semana Actual")
                    let fecha_semana_anterior = moment().week(result.datos_extra.int_num_semana_con_registros_a_obtener-1); //el "-1" es ensayo error
                    let lunes_fecha_semana_anterior = moment(fecha_semana_anterior).day(1);
                    this.semana_mostrada = moment(lunes_fecha_semana_anterior).format("DD-MM-YY")
                    }


            this.DepuracionService.console_log("this.son_registros_de_usuario", this.son_registros_de_usuario , "Adaptar Fechas a Semana Actual")
            this.DepuracionService.console_log("this.son_registros_de_area", this.son_registros_de_area , "Adaptar Fechas a Semana Actual")

            registros = this.GeneralService.Adaptar_Fechas_Registros_Calendario_a_Semana_Actual (registros)

            //--------------------------------------------------------
            //ahora ya puedo desagruparlos y mostrarlos en el calendario
            this.DepuracionService.console_log("registros agrupados", registros, "guardar calendario")
            registros_desagrupados = this.Desagrupar_Registros(registros);
            this.DepuracionService.console_log("1) registros_desagrupados()", registros_desagrupados, "guardar calendario")
            this.DepuracionService.console_log("registros_desagrupados [0]", registros_desagrupados[0], "guardar calendario 2")
            //lo meto dentro del sisguiente setTimeOut porque no se porque no se cargaba
            //setTimeout(() => {
            //    this.eventSource = registros_desagrupados;
            //    }, 0);
            //this.GeneralService.Cargar_Calendar_eventSource (this.eventSource, registros_desagrupados)
            setTimeout(() => {                
                this.eventSource = registros_desagrupados;
                }, 0);
            //this.createRandomEvents();

            
            });
        }

    Guardar_Calendario () {
        this.DepuracionService.console_log("dentro de Guardar_Calendario()", null, "guardar calendario")
        //this.DepuracionService.console_log("this.eventSource", this.eventSource, "guardar calendario")
        var registros_agrupados = this.Agrupar_Registros();
        this.DepuracionService.console_log("registros_agrupados", registros_agrupados, "guardar calendario")
        var fechas_limite = this.GeneralService.Fechas_Limite_segun_tipo_vista(this.conf_calendario.currentDate2, this.conf_calendario.mode)
        this.DepuracionService.console_log("fechas_limite", fechas_limite, "guardar calendario")
        
        this.bbddRegistrosCalendariosService.save(this.prmtr_area_o_usuario, registros_agrupados, fechas_limite).subscribe(result => {
            this.DepuracionService.console_log("save > result", result, "guardar calendario")
            this.calendario_modificado = false;
            //si estamos...
            //...en un calendario tipo usuario
            //...se ha quedado vacio
            //debemos cargar el posible calendario de area que le corresponda
            //if (
            //    !this.GeneralService.EstaVacia(this.prmtr_area_o_usuario.COD_USUARIO) 
            //    &&
            //    this.eventSource.length == 0
            //    ) {
                this.DepuracionService.console_log("llamada 2 a this.Cargar_Calendario", null, "consultas duplicadas")
                this.Cargar_Calendario(this.conf_calendario.currentDate2)
            //    }
            this.ToastService.show("El calendario ha sido guardado correctamente")
            });
        
        }

    Agrupar_Registros () {
        this.DepuracionService.console_log("dentro de Agrupar_Registros()", null, "guardar calendario")
        var registros = [...this.eventSource]; //para "clonar" el array y que sea uno nuevo

        //recorro todos los registros (que están separados por horas) y los agrupo en horas consecutivas
        var registros_agrupados = []
        //registros.forEach(function (registr, index, array) {
        var index, index_inicio_grupo, index_fin_grupo, registro_agrupado
        //comparo todos los registros (excepto el último, de ahí el "- 2") con el siguiente para saber si está agrupado
        index_inicio_grupo = 0; index_fin_grupo = 0;
        for (index = 0; index <= registros.length - 2; ) {
            this.DepuracionService.console_log("registros[" + index + "]", registros[index], "guardar calendario")
            if (registros[index].endTime.getTime() === registros[index + 1].startTime.getTime()) {
                index_fin_grupo += 1;
                }
            else {
                registro_agrupado = {
                    title: '',
                    startTime: registros[index_inicio_grupo].startTime,
                    endTime: registros[index_fin_grupo].endTime,
                    allDay: false,
                    }
                index_inicio_grupo = index + 1
                index_fin_grupo    = index + 1
                }
            index += 1;
            
            if (index === index_inicio_grupo) {
                registros_agrupados.push(registro_agrupado);
                this.DepuracionService.console_log("registr_agrupado [" + (registros_agrupados.length - 1) + "]", registro_agrupado, "guardar calendario")
                }
            } // for
        //this.DepuracionService.console_log("index_inicio_grupo", index_inicio_grupo, "guardar calendario")
        //this.DepuracionService.console_log("index_fin_grupo", index_fin_grupo, "guardar calendario")
        //agrego el último grupo de enventos...
        if (registros.length > 0 ) {
            registro_agrupado = {
                title: '',
                startTime: registros[index_inicio_grupo].startTime,
                endTime: registros[index_fin_grupo].endTime,
                allDay: false,
                }
                registros_agrupados.push(registro_agrupado)
            }

        this.DepuracionService.console_log("registro_agrupado  [" + (registros_agrupados.length - 1) + "] (ultimo)", registro_agrupado, "guardar calendario")
        return registros_agrupados;
        }

    //los registros vienen con una hora de inicio y otra de fin qe no tienen porque ser consecutivas
    //con esta función divido dichas entradas en 1 por cada hora
    Desagrupar_Registros (prmtrRegistrosAgrupados) {
        this.DepuracionService.console_log("dentro de Desagrupar_Registros()", null, "guardar calendario")
        //var registros = [...this.eventSource]; //para "clonar" el array y que sea uno nuevo
        var registros_agrupados = this.GeneralService.Formatear_Array_Calendar_EventSource (prmtrRegistrosAgrupados) 
        //recorro todos los registros (que están separados por horas) y los agrupo en horas consecutivas
        var registros_desagrupados = []
        //registros.forEach(function (registr, index, array) {
        //comparo todos los registros (excepto el último, de ahí el "- 2") con el siguiente para saber si está agrupado
        var index, int_hora_inicio, int_hora_fin, int_hora_actual, date_startTime, date_endTime
        registros_agrupados.forEach(element => {
            this.DepuracionService.console_log("registros_agrupados > element ", element , "guardar calendario")
            int_hora_inicio = moment(element.startTime).hour()
            int_hora_fin = moment(element.endTime).hour()
            for (index = int_hora_inicio; index < int_hora_fin; index += 1) {
                int_hora_actual = (index - int_hora_inicio)
                //ncesario hacer la siguiente asignación de vbles en vez de ponerlo directamente en la creación de "registro_desagrupado"
                //prqe se liaba
                date_startTime = moment(element.startTime).add( int_hora_actual, "hours")
                date_endTime = moment(element.startTime).add( (int_hora_actual + 1), "hours")
                date_startTime = new Date(date_startTime)
                date_endTime = new Date(date_endTime)
                let registro_desagrupado = {
                    title: '',
                    startTime: date_startTime,
                    endTime: date_endTime,
                    allDay: false,
                    }
                registros_desagrupados.push(registro_desagrupado)
                this.DepuracionService.console_log("registro_desagrupado ", registro_desagrupado , "guardar calendario")        
                }
            });
        //this.DepuracionService.console_log("0 registros_desagrupados ", registros_desagrupados , "guardar calendario")
        return registros_desagrupados;
        }







    //adapto esta función para que me cree eventos en la semana en la que estoy que es cuando me interesa probar
    createRandomEvents() {
        var events = [];

        this.DepuracionService.console_log("dentro de createRandomEvents", null, "guardar calendario")
        //averiguo la fecha de inicio de la semana en la que estamos y la de fin
        var fechas_limite = this.GeneralService.Fechas_Limite_segun_tipo_vista(null, "week")
        this.DepuracionService.console_log("fechas_limite", fechas_limite, "guardar calendario")
        /*
        for (var i = 0; i < 100; i += 1) {
            var date = new Date(fechas_limite.date_inicio);
            var startDay = Math.floor(Math.random() * 7);
            //var endDay = Math.floor(Math.random() * 2) + startDay;
            //var endDay = startDay;
            var startHour = 8 + Math.floor(Math.random() * 14); //14 horas a partir de las 8 de la mañana
          
            var startTime = new Date(date.getFullYear(), date.getMonth(), date.getDate() + startDay, startHour    , 0 );
            var endTime =   new Date(date.getFullYear(), date.getMonth(), date.getDate() + startDay, startHour + 1, 0 );
            events.push({
                title: '',
                startTime: startTime,
                endTime: endTime,
                allDay: false,
                });
          
            } //for
        */
        //en vez de crear horas sueltas creo 2 grupos de horas en cada día para que sea mas real
        var date_Fecha_Inicial = new Date(fechas_limite.date_inicio)
        var date_Fecha_Fin = new Date(fechas_limite.date_fin)
        var int_Dia_Fecha_Inicial = date_Fecha_Inicial.getDate()
        var int_Dia_Fecha_Fin = date_Fecha_Fin.getDate()
        //int_Dia_Fecha_Fin - 2: para evitar sabados y domingos
        for (var i = int_Dia_Fecha_Inicial; i <= int_Dia_Fecha_Fin - 2; i += 1) {
            var date = new Date(fechas_limite.date_inicio);
            //var startHour = 8 + Math.floor(Math.random() * 3); //3 horas a partir de las 8 de la mañana
            //var intNumHoras = Math.floor(Math.random() * 8)
            //--------------------------------------------------
            //añado N horas POR LA MAÑANA
            var startHour = 8 + this.GeneralService.Numero_Aleatorio_entre_2_numeros(0, 2) //3 horas a partir de las 8 de la mañana
            var intNumHoras = this.GeneralService.Numero_Aleatorio_entre_2_numeros(4, 8)
            //añado intNumHoras consecutivas
            for (var j = 0; j < intNumHoras; j += 1) {
                var startTime = new Date(date.getFullYear(), date.getMonth(), i, startHour + j    , 0 );
                var endTime = new Date(  date.getFullYear(), date.getMonth(), i, startHour + j + 1, 0 );
                events.push({
                    title: '',
                    startTime: startTime,
                    endTime: endTime,
                    allDay: false,
                    });
                }

            //--------------------------------------------------
            //añado N horas POR LA TARDE
            if (intNumHoras < 6) {
                var startHour = endTime.getHours() + this.GeneralService.Numero_Aleatorio_entre_2_numeros(1, 3) //entre 1 y 3 horas mas tarde que el turno de mañana
                var intNumHoras = this.GeneralService.Numero_Aleatorio_entre_2_numeros(2, 4)
                //añado intNumHoras consecutivas
                for (var j = 0; j < intNumHoras; j += 1) {
                    var startTime = new Date(date.getFullYear(), date.getMonth(), i, startHour + j    , 0 );
                    var endTime = new Date(  date.getFullYear(), date.getMonth(), i, startHour + j + 1, 0 );
                    this.DepuracionService.console_log("createRandonEvents > startTime ", startTime , "guardar calendario 2")
                    this.DepuracionService.console_log("createRandonEvents > endTime ", endTime , "guardar calendario 2")
                    events.push({
                        title: '',
                        startTime: startTime,
                        endTime: endTime,
                        allDay: false,
                        });
                    }
                }

          
            } //for

        //lo meto dentro del sisguiente setTimeOut porque no se porque no se cargaba
        setTimeout(() => {
            this.eventSource = events;
            }, 0);
        //this.DepuracionService.console_log("this.eventSource", this.eventSource, "guardar calendario")
      } // createRandomEvents()

    removeEvents() {
        this.eventSource = [];
        }


}
