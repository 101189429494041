import { Component, OnInit, Inject, LOCALE_ID }                                             from '@angular/core';
import { ModalController, NavParams, PickerController}                                      from '@ionic/angular';
import { PickerOptions }                                                                    from "@ionic/core";
import { environment, modelos_de_datos, slidemenu_options }                                 from 'src/environments/environment';
import { GeneralService }                                                                   from 'src/app/services/general.service';
import { DepuracionService }                                                                from 'src/app/services/depuracion.service';
import { bbddRegistrosHorariosService }                                                     from 'src/app/services/bbdd-registros-horarios.service';
import { ToastService }                                                                     from 'src/app/services/toast.service';




import * as moment from 'moment';

@Component({
  selector: 'app-editar-registro-horario',
  templateUrl: './editar-registro-horario.page.html',
  styleUrls: ['./editar-registro-horario.page.scss'],
})
export class EditarRegistroHorarioPage implements OnInit {

    prmtr_registro_horario:any;
    prmtr_usuario:any;
    slidemenu_option_elegida: any;

    registro_horario:any; //para contener el registro horario modificado

    array_datepicker_columns: any;
    
    boton_guardar_disabled:boolean;
    
    cod_tipo_de_hora_seleccionado: number;
    str_tipo_de_hora_seleccionado: string;



    constructor(
        private DepuracionService: DepuracionService
        , private GeneralService: GeneralService
        , private ModalController: ModalController
        , private NavParams: NavParams
        , private ToastService: ToastService
        , private PickerController: PickerController
        , private bbddRegistrosHorariosService: bbddRegistrosHorariosService
        ) { 
        moment.locale('es');

        this.prmtr_registro_horario = this.NavParams.get('prmtr_registro_horario');
        this.prmtr_usuario = this.NavParams.get('prmtr_usuario');
        this.slidemenu_option_elegida = slidemenu_options.find(element => element.id === 'registros horarios');

        this.DepuracionService.console_log("constructor > prmtr_registro_horario", this.prmtr_registro_horario, "editar registros horarios 2")
        this.DepuracionService.console_log("constructor > prmtr_usuario", this.prmtr_usuario, "editar registros horarios 2")

        //Formateo las fechas para mostrarlas como me interesa 
        let dateFechaHoraInicio = this.prmtr_registro_horario.FECHAHORA_INICIO_REGISTRO
        let dateFechaHoraFin = this.prmtr_registro_horario.FECHAHORA_FIN_REGISTRO
        this.prmtr_registro_horario.fecha_inicio = moment(dateFechaHoraInicio).format(environment.format_date)
        this.prmtr_registro_horario.hora_inicio = moment(dateFechaHoraInicio).format(environment.format_hour + ":ss")
        this.prmtr_registro_horario.fecha_fin = moment(dateFechaHoraFin).format(environment.format_date)
        this.prmtr_registro_horario.hora_fin = moment(dateFechaHoraFin).format(environment.format_hour + ":ss")

        //utilizo esta nueva vble para poder tener independiente el original (prmtr...) de lo que estoy manipulando
        this.registro_horario = {...this.prmtr_registro_horario};
        this.cod_tipo_de_hora_seleccionado = this.registro_horario.COD_TIPO_DE_HORA
        this.str_tipo_de_hora_seleccionado = this.registro_horario.TITULO_TIPO_DE_HORA

        this.boton_guardar_disabled = true;


        }

    ngOnInit() {
        }

    close(prmtrDismiss) {
        this.ModalController.dismiss(prmtrDismiss);
        }


    async ionPicker_Hora_Inicio_y_Hora_Fin(prmtrHoraInicioSeleccionada, prmtrHoraFinSeleccionada) {
        var array_horas_a_intervalos, array_horas_a_intervalos_inicio, array_horas_a_intervalos_fin
        var options_horas_a_intervalos_inicio, options_horas_a_intervalos_fin
        var str_hora_inicio, str_hora_fin, int_indice_hora_inicio, int_indice_hora_fin

        //-----------------------------------------------------------------------------
        // Creamos las options de horas de inicio para primera columna del ionPicker
        //this.horas_a_intervalos = this.GeneralService.Rango_de_Horas_a_intervalos_v0("08:00", 15, "minutes")
        array_horas_a_intervalos = this.GeneralService.Rango_de_Horas_a_intervalos_v2(environment.conf_calendario.startHour, environment.conf_calendario.endHour, 0, "minutes")
        this.DepuracionService.console_log("ionPicker_Hora_Inicio_y_Hora_Fin > array_horas_a_intervalos", array_horas_a_intervalos, "editar registros horarios 2")

        array_horas_a_intervalos_inicio = [...array_horas_a_intervalos]
        //inserto la hora de inicio y de fin que tenia 
        str_hora_inicio = moment(prmtrHoraInicioSeleccionada, environment.format_hour + ":ss").format(environment.format_hour)
        //añado la hora de inicio que esta actualmente solo si no existe ya en el array
        if (array_horas_a_intervalos_inicio.indexOf(str_hora_inicio) == -1) {
            array_horas_a_intervalos_inicio.push(str_hora_inicio)
            }
        //ordeno los elementos del array
        array_horas_a_intervalos_inicio = array_horas_a_intervalos_inicio.sort();
        //this.DepuracionService.console_log("constructor > array_horas_a_intervalos_inicio", array_horas_a_intervalos_inicio, "editar registros horarios 2")
        options_horas_a_intervalos_inicio = [];
        //una opción poara poder poner un titulo a cada columna sería añadir un valor inicial...
        //options_horas_a_intervalos.push({text: "inicio", value: "inicio2"})..., pero no me convence
        //
        array_horas_a_intervalos_inicio.forEach(function (valor, indice, array) {
            if (str_hora_inicio == valor) {int_indice_hora_inicio = indice}
            options_horas_a_intervalos_inicio.push({
                                                text: valor, value: valor
                                                //, selected: true, disabled: false
                                                })
            }, this);

        //-----------------------------------------------------------------------------
        // Creamos las options de horas de fin para segunda columna del ionPicker
        array_horas_a_intervalos_fin = [...array_horas_a_intervalos]
        //inserto la hora de inicio y de fin que tenia 
        str_hora_fin = moment(prmtrHoraFinSeleccionada, environment.format_hour + ":ss").format(environment.format_hour)
        //añado la hora de fin que esta actualmente solo si no existe ya en el array
        if (array_horas_a_intervalos_fin.indexOf(str_hora_fin) == -1) { 
            array_horas_a_intervalos_fin.push(str_hora_fin)
            }

        //ordeno los elementos del array
        array_horas_a_intervalos_fin = array_horas_a_intervalos_fin.sort();
        //this.DepuracionService.console_log("constructor > array_horas_a_intervalos_fin", array_horas_a_intervalos_fin, "editar registros horarios 2")
        options_horas_a_intervalos_fin = [];
        //una opción poara poder poner un titulo a cada columna sería añadir un valor inicial...
        //options_horas_a_intervalos.push({text: "inicio", value: "inicio2"})
        //..., pero no me convence
        array_horas_a_intervalos_fin.forEach(function (valor, indice, array) {
                if (str_hora_fin == valor) {int_indice_hora_fin = indice}
                options_horas_a_intervalos_fin.push({
                                                    text: valor, value: valor
                                                    //, selected: true, disabled: false
                                                    })
            }, this);

        //NOTA:
        //para que no ocurra el bug...
        // https://github.com/ionic-team/ionic-framework/issues/17664
        //creo una copia de las options para la segunda columnna
        //var options_horas_a_intervalos2 = [...options_horas_a_intervalos_inicio]; //para "clonar" el array y que sea uno nuevo
        //con esto no funciona asi que utiliza lo que habia indicado la solución de "belkka" en la url mencionada
        //options_horas_a_intervalos_inicio = JSON.parse(JSON.stringify(options_horas_a_intervalos_inicio));
        //options_horas_a_intervalos_fin = JSON.parse(JSON.stringify(options_horas_a_intervalos_fin));
        //...ahora ya no hace falta porque he creado 2 vbles "options_horas_a_intervalos_XXX" diferentes

        this.array_datepicker_columns = [
            {
                name: 'hora_inicio',
                cssClass: 'class_hora_inicio',
                //options: [{text: 'a', value: 'a'}, {text: 'b', value: 'b'}, {text: 'c', value: 'c'}, {text: 'd', value: 'd'}]
                options: options_horas_a_intervalos_inicio,
                selectedIndex: int_indice_hora_inicio,
                prefix: 'inicio', //aparece a la izquierda de la columna
            },
            {
                name: 'hora_fin',
                cssClass: 'class_hora_fin',
                //options: [{text: '1', value: '1'}, {text: '2', value: '2'}, {text: '3', value: '3'}, {text: '4', value: '4'}]
                options: options_horas_a_intervalos_fin,
                selectedIndex: int_indice_hora_fin, 
                prefix: 'fin', //aparece a la izquierda de la columna
            },
            ];

        let configuracion_picker = {
            buttons: [
                {
                    text:'Actualizar',
                    role: 'save',
                    handler:(value:any) => {
                        //console.log(value);
                        //console.log ("typeof(this)...", typeof(this))
                        let int_hora_inicio = parseInt(value.hora_inicio.text.split(":")[0]);
                        let int_minuto_inicio = parseInt(value.hora_inicio.text.split(":")[1]);
                        let int_hora_fin = parseInt(value.hora_fin.text.split(":")[0]);
                        let int_minuto_fin = parseInt(value.hora_fin.text.split(":")[1]);
                        let int_segundos = 0;
                        let str_hora_inicio = ("00" + int_hora_inicio).slice(-2)
                        let str_minuto_inicio = ("00" + int_minuto_inicio).slice(-2)
                        let str_hora_fin = ("00" + int_hora_fin).slice(-2)
                        let str_minuto_fin = ("00" + int_minuto_fin).slice(-2)
                        let str_segundos = "00";


                        this.registro_horario.FECHAHORA_INICIO_REGISTRO = moment(this.registro_horario.FECHAHORA_INICIO_REGISTRO).set({hour: int_hora_inicio, minute: int_minuto_inicio, second: int_segundos});
                        this.registro_horario.FECHAHORA_FIN_REGISTRO    = moment(this.registro_horario.FECHAHORA_FIN_REGISTRO   ).set({hour: int_hora_fin   , minute: int_minuto_fin   , second: int_segundos});
                        this.registro_horario.startTime                 = this.registro_horario.FECHAHORA_INICIO_REGISTRO
                        this.registro_horario.endTime                   = this.registro_horario.FECHAHORA_INICIO_REGISTRO
                        this.registro_horario.hora_inicio               = str_hora_inicio + ":" + str_minuto_inicio + ":" + str_segundos;
                        this.registro_horario.hora_fin                  = str_hora_fin + ":" + str_minuto_fin + ":" + str_segundos;
                        //this.registro_horario = 

                        this.Establecer_boton_guardar_disabled();
                        }
                },
                {
                    text: "Cerrar",
                    role: 'cancel'
                },
                ],
            cssClass: 'hora_inicio_y_fin_registro_horario es_modal',
            columns: this.array_datepicker_columns // NOTA IMPORTANTE 
                                                   // definir columns con una vble "this." para que se pueda acceder dentro del handler a this
            };
        
        let picker = await this.PickerController.create(configuracion_picker);
        picker.present()

        //getColumn(name: string) => Promise<PickerColumn | undefined>
        //const column_hora_inicio = await picker.getColumn('hora_inicio');

        }

    Establecer_boton_guardar_disabled () {
        this.registro_horario.COD_TIPO_DE_HORA = this.cod_tipo_de_hora_seleccionado;

        this.boton_guardar_disabled = (
            this.GeneralService.EstaVacia(this.cod_tipo_de_hora_seleccionado) //en este caso se muestra la opción "seleccione una opción"
            ||
            (
                this.prmtr_registro_horario.hora_inicio == this.registro_horario.hora_inicio
                &&
                this.prmtr_registro_horario.hora_fin == this.registro_horario.hora_fin
                &&
                this.prmtr_registro_horario.COD_TIPO_DE_HORA == this.registro_horario.COD_TIPO_DE_HORA
            )

            )
        }


    Recepcionar_ionpicker_cod_tipo_de_hora (prmtrCodTipoDeHora_recibido) {
        //this.DepuracionService.console_log("Recepcionar_ionpicker_cod_tipo_de_hora > prmtrCodTipoDeHora_recibido", prmtrCodTipoDeHora_recibido, "ionpicker tipo de hora component recepcionar")
        this.cod_tipo_de_hora_seleccionado = prmtrCodTipoDeHora_recibido
        this.Establecer_boton_guardar_disabled();
        }
    Recepcionar_ionpicker_str_tipo_de_hora (prmtrStrTipoDeHora_recibido) {
        //this.DepuracionService.console_log("Recepcionar_ionpicker_cod_tipo_de_hora > prmtrStrTipoDeHora_recibido", prmtrStrTipoDeHora_recibido, "ionpicker tipo de hora component recepcionar")
        this.str_tipo_de_hora_seleccionado = prmtrStrTipoDeHora_recibido
        }

    Guardar_RegistroHorario (prmtrRegistroHorario) {
        this.DepuracionService.console_log("Guardar_RegistroHorario", prmtrRegistroHorario, "editar registros horarios 2")
        //let str_fechahora_inicio = prmtrRegistroHorario.fecha_inicio + " " + prmtrRegistroHorario.hora_inicio
        //let str_fechahora_fin = prmtrRegistroHorario.fecha_fin + " " + prmtrRegistroHorario.hora_fin
        //this.bbddRegistrosHorariosService.saveActualizarRegistroHorario (prmtrRegistroHorario.COD_REGISTRO, str_fechahora_inicio, str_fechahora_fin).subscribe(result => {
        this.bbddRegistrosHorariosService.saveActualizarRegistroHorario (prmtrRegistroHorario).subscribe(result => {
            this.DepuracionService.console_log("Guardar_RegistroHorario", result, "editar registros horarios 2")
            //this.GeneralService.Cargar_Calendar_eventSource (this.eventSource, result.data)
            setTimeout(() => {                
                //this.eventSource = this.GeneralService.Formatear_Array_Calendar_EventSource(result.data);
                }, 0);
            

            });

        this.ModalController.dismiss(prmtrRegistroHorario);
        

        }



}
