import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment, modelos_de_datos } from 'src/environments/environment';
import { DepuracionService } from 'src/app/services/depuracion.service';
import { GeneralService } from 'src/app/services/general.service';

@Injectable({
  providedIn: 'root'
})
export class bbddAreasService {

    constructor(private http: HttpClient
        , private DepuracionService: DepuracionService
        , private GeneralService: GeneralService
        ) { 
            
            }

    getAreas(prmtrCodAreaPadre: any): Observable<any> {
        //this.DepuracionService.console_log("getAreas > prmtrCodAreaPadre", prmtrCodAreaPadre, "obtener areas")
        //this.DepuracionService.console_log("environment", environment, "obtener areas")
        var strCodAreaPadre:any = prmtrCodAreaPadre;
        if (this.GeneralService.EstaVacia(strCodAreaPadre)) {
            strCodAreaPadre = "null"
            }
        let strFuncionServidor = 'consulta_Areas_jerarquicamente (' + strCodAreaPadre + ')'
        //var strUrl = this.GeneralService.Componer_URL_Servidor(strFuncionServidor, true)
        //this.DepuracionService.console_log("strUrl", strUrl, "modal area usuario");
        //return this.http.get(strUrl);
        return this.GeneralService.Ejecutar_en_Servidor (strFuncionServidor, true, null) 
        }
}
