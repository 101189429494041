import { Injectable }                   from '@angular/core';
import { HttpClient }                   from '@angular/common/http';
import { ModalController }              from '@ionic/angular';
import { Observable }                   from 'rxjs';
import { environment }                  from 'src/environments/environment';


//* NOTA DepuracionService
//lo siguiente genera un error por importación ciclica ya que una llama a la otra
//todavía no he descubierto como poder hacerlo para evitarlo y como por ahora puedo pasar sin
//usar aqui la de depuración pues no le doy mas vueltas.
//import { DepuracionService } from './depuracion.service'; 
//quizas aqui plantean una solución...
//...https://stackoverflow.com/questions/37108459/is-it-possible-to-inject-a-service-inside-another-service-and-vice-versa-at-the


import * as moment from 'moment';


@Injectable(
    {providedIn: 'root'}
    )
export class GeneralService {
    //Para poder llamara  una función de este propio servicio aqui NO sirve con lo encontrado aqui
    //https://stackoverflow.com/questions/19842669/calling-a-function-in-angularjs-service-from-the-same-service
    //ya que eso es para versiones anteriores, lo que hay que hacer es declarar las funciones de algun tipo (any vale)
    //y así ya se puede acceder al resto de funciones (ensayo-error)

    constructor(private HttpClient: HttpClient
                , private ModalController: ModalController
                ) { 
        moment.locale('es');               

        }

    //Obtengo la hora del servidor para evitar "picarescas" en local
    getFechaHoraActual(): Observable<any> {
        var strUrl = environment.URL_JSON_ACCION + 'FechaHora_Actual()';
        return this.HttpClient.get(strUrl);
        }

    getNombreBBDD(): Observable<any> {
        var strUrl = environment.URL_JSON_ACCION + 'Nombre_BBDD()';
        //console.log ("strUrl: ", strUrl)
        return this.HttpClient.get(strUrl);
        }

    getCharSetYCodePage(): Observable<any> {
        var strUrl = environment.URL_JSON_ACCION + 'CharSet_y_CodePage()';
        //console.log ("strUrl: ", strUrl)
        return this.HttpClient.get(strUrl);
        }

 

    EstaVacia(prmtrVble: any): boolean {
        var varReturn:boolean;
        //var strAlert = "";
        //strAlert +=  "prmtrVble: " + prmtrVble + "\n";
        //strAlert +=  "typeof(prmtrVble): " + typeof(prmtrVble) + "\n\n";
        var varReturn1 = (typeof (prmtrVble) == "undefined");
        //por si acaso no está definida la vble, para que no casque con las siguientes comprobaciones
        //pero aun así no lo consigo porque al no estár definida la vble casca cuanto intento pasarla a 
        //esta funcion en la que estamos -> EstaVacia
        if (varReturn1 == false) {
            varReturn1 = (prmtrVble == null) || (prmtrVble == undefined)
            }
        //strAlert +=  "(prmtrVble == null): " + (prmtrVble == null) + "\n";
        //strAlert +=  "(prmtrVble == undefined): " + (prmtrVble == undefined) + "\n";
        //strAlert +=  "(typeof(prmtrVble) == 'undefined'): " + (typeof(prmtrVble) == "undefined") + "\n";
        //strAlert +=  "varReturn1: " + varReturn1 + "\n\n";
        var varReturn2 = false;
    
        if (varReturn1 == false) {
            if (typeof (prmtrVble) == "string") {
                varReturn2 = (prmtrVble.trim().toLowerCase() == "null")
                                || (prmtrVble.trim().toLowerCase() == "undefined")
                                || (prmtrVble.trim() == "");
                }
                //strAlert +=  "(prmtrVble == 'null'): " + (prmtrVble == "null") + "\n";
                //strAlert +=  "(prmtrVble == 'undefined'): " + (prmtrVble == "undefined") + "\n";
                //strAlert +=  "(prmtrVble == ''): " + (prmtrVble == "") + "\n";
                //strAlert +=  "varReturn2: " + varReturn2 + "\n\n";		
            } // if (varReturn1 == true)
    
        //para controlar que si un array tiene 0 elementos devuelva vacia = true
        var varReturn3 = false;
        if (varReturn1 == false && varReturn2 == false) {
            if (typeof (prmtrVble) == "object") { //array = object
                varReturn3 = (prmtrVble.length == 0)
                }
            } 
        varReturn = varReturn1 || varReturn2 || varReturn3;
        //strAlert +=  "varReturn: " + varReturn + "\n";
        //alert(strAlert);
        return varReturn;
        }
    
    Propiedades_a_null (prmtrObject: any) {
        for (var key in prmtrObject ) {
            prmtrObject[key] = null;
            }
        return prmtrObject;
        }

        

    Componer_URL_Servidor (prmtrFunctionServidor, prmtrObtenerJSONGeneral) {
        var strUrl, strUrlAux
        //ejemplo de lo que quiero obtener...
        strUrlAux = prmtrFunctionServidor
        //si puede ser tratada de forma general (casi siempre) lo encapsulo con una función de servidor que lo hace
        if (prmtrObtenerJSONGeneral) {
            strUrlAux = strUrlAux.replace(/"/g, '""'); //sustituyo todas las " por "", necesario para que se ejecute bien en el servidor
            strUrlAux = 'Obtener_JSON_v0("' + strUrlAux + '")'
            }
        strUrl = environment.URL_JSON_ACCION + strUrlAux;
        return strUrl
        }


    Ejecutar_en_Servidor (prmtrFunctionServidor, prmtrObtenerJSONGeneral, prmtrData) {
        var strUrl = this.Componer_URL_Servidor (prmtrFunctionServidor, prmtrObtenerJSONGeneral)
        //var * NOTA DepuracionService
        //this.DepuracionService.console_log("strUrl", strUrl, "");
        //console.log("Ejecutar_en_Servidor > strUrl", strUrl);

        var objReturn:any;
        if (this.EstaVacia(prmtrData)) {
            objReturn =  this.HttpClient.get(strUrl);
            }
        else  {
            objReturn = this.HttpClient.post(strUrl, prmtrData, environment.http_post_options)
            }
        return objReturn;
        }



    //en vez de hacer esto podría utilizar el evento onRangeCahnged que devuelve como parametro justo la información que me interesa y podría guardarla como propiedades
    //de this.conf_calenario para utilizarlo cuando me interese
    //...por ahora utilizo esta función, mas que nada porque ya está implementada e integrada
    Fechas_Limite_segun_tipo_vista (prmtrFecha, prmtrTipoVista) {
        var strFecha = (this.EstaVacia(prmtrFecha))?undefined:prmtrFecha; //para que se ponga la fecha actual si viene vacia
        strFecha = moment(strFecha).format(environment.format_date);
        var primera_date_mes = moment(strFecha).startOf('month').format(environment.format_date);
        var ultima_date_mes = moment(strFecha).endOf('month').format(environment.format_date);
        var primera_date_mes_segun_calendario = moment(primera_date_mes + ' 00:00:00').startOf('week').format(environment.format_datehour + ':ss');
        var ultima_date_mes_segun_calendario = moment(ultima_date_mes + ' 23:59:59').endOf('week').format(environment.format_datehour + ':ss');
        //pero esta no sirve (aunque sería lo correcto en un calendario) porque ionic2-calendar muestra
        //de 6 en 6 semanas asi que le sumo 5 semanas a la primera
        var primer_dia_ultima_semana_segun_calendar2ionic = moment(primera_date_mes).add(5, 'weeks').format(environment.format_date);
        var ultima_date_mes_segun_calendar2ionic = moment(primer_dia_ultima_semana_segun_calendar2ionic + ' 00:00:00').endOf('week').format(environment.format_datehour + ':ss');

        var primera_date_semana = moment(strFecha + ' 00:00:00').startOf('week').format(environment.format_datehour + ':ss');
        var ultima_date_semana = moment(strFecha + ' 23:59:59').endOf('week').format(environment.format_datehour + ':ss');

        var str_dia = moment(strFecha).format(environment.format_date);
        var primera_date_dia = moment(str_dia + ' 00:00:00').format(environment.format_datehour + ':ss');
        var ultima_date_dia = moment(str_dia + ' 23:59:59').format(environment.format_datehour + ':ss');
        /*
        console.log ("strFecha: " + strFecha)
        console.log ("prmtrTipoVista: " + prmtrTipoVista)
        console.log ("------------------------------------------------")
        console.log ("primera_date_mes: " + primera_date_mes)
        console.log ("primera_date_mes_calendario: " + primera_date_mes_segun_calendario)
        console.log ("ultima_date_mes: " + ultima_date_mes)
        console.log ("ultima_date_mes_segun_calendario: " + ultima_date_mes_segun_calendario)
        console.log ("primer_dia_ultima_semana_segun_calendar2ionic: " + primer_dia_ultima_semana_segun_calendar2ionic)
        console.log ("ultima_date_mes_segun_calendar2ionic: " + ultima_date_mes_segun_calendar2ionic)
        console.log ("")
        console.log ("primera_date_semana: " + primera_date_semana)
        console.log ("ultima_date_semana: " + ultima_date_semana)
        console.log ("")
        console.log ("primera_date_dia: " + primera_date_dia)
        console.log ("ultima_date_dia: " + ultima_date_dia)
        */
        var obj_return = {
            date_actual: strFecha,
            tipo_vista: prmtrTipoVista,
            date_inicio: null,
            date_fin: null
            };

        if (prmtrTipoVista == "day") {
            obj_return.date_inicio = primera_date_dia;
            obj_return.date_fin = ultima_date_dia;
            }

        if (prmtrTipoVista == "week") {
            obj_return.date_inicio = primera_date_semana;
            obj_return.date_fin = ultima_date_semana;
            }

        if (prmtrTipoVista == "month") {
            obj_return.date_inicio = primera_date_mes_segun_calendario;
            obj_return.date_fin = ultima_date_mes_segun_calendar2ionic;
            }

        return obj_return;

        }

    //encontrado en...https://stackoverflow.com/questions/4959975/generate-random-number-between-two-numbers-in-javascript
    Numero_Aleatorio_entre_2_numeros (min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min);
        }

    //Para que me devuelva un array con horas:minutos desde un inicio hasta un fin a los intervalos que indieque
    //obtenido de ...https://stackoverflow.com/questions/36125038/generate-array-of-times-as-strings-for-every-x-minutes-in-javascript
    //la función original se llama...
    //  ...timelineLabels (desiredStartTime, interval, period) {
    //prmtrPeriodo: hours, minutes,...
    Rango_de_Horas_a_intervalos_v0_OBSOLETA (prmtrFechaHoraInicio, prmtrIntervalo: number, prmtrPeriodo) {
        const periodsInADay = moment.duration(1, 'day').as(prmtrPeriodo);
        var formato_hora = 'HH:mm'; //podria poner environment.format_hour, pero asi es mas "independiente"
        
        const arrayIntervalos = [];
        const startTimeMoment = moment(prmtrFechaHoraInicio, formato_hora); 
        for (let i = 0; i <= periodsInADay; i += prmtrIntervalo) {
            startTimeMoment.add(i === 0 ? 0 : prmtrIntervalo, prmtrPeriodo);
            arrayIntervalos.push(startTimeMoment.format(formato_hora));
            }
        return arrayIntervalos;
        };
    //el anterior es el "orignal" muestra todas las horas de un día a intervalos desde prmtrFechaHoraInicio hasta que 
    //da la vuelta al dia y vuelve a mostrar ese día, pero yo necesito...

    //Muestro los intervalos entre 2 fechas
    Rango_de_Horas_a_intervalos_v1_NO_FUNCIONA (prmtrFechaHoraInicio, prmtrFechaHoraFin, prmtrIntervalo: number, prmtrPeriodo) {
        //const periodsInADay = moment.duration(1, 'day').as(prmtrPeriodo);
        var formato_hora = 'HH:mm'; //podria poner environment.format_hour, pero asi es mas "independiente"
        
        const arrayIntervalos = [];
        const hora_inicio = moment(prmtrFechaHoraInicio, formato_hora); 
        const hora_fin = moment(prmtrFechaHoraFin, formato_hora); 
        var   hora_actual = hora_inicio;
        //for (let i = hora_inicio; hora_actual != hora_fin; +i) {
        for (let i = 0; i <= 10; +i) {
            hora_actual = moment(hora_actual).add(prmtrIntervalo, prmtrPeriodo)
            //hora_inicio.add(i === 0 ? 0 : prmtrIntervalo, prmtrPeriodo);
            //arrayIntervalos.push(hora_actual.format(formato_hora));

            }
        return arrayIntervalos;
        };
    //como no consigo hacerlo a la primera utilizo otra mas sencilla pero mas rápida de desarrollar que he encontrado en la misma url
    Rango_de_Horas_a_intervalos_v2 (prmtrFechaHoraInicio, prmtrFechaHoraFin, prmtrIntervalo: number, prmtrPeriodo) {
        const formato_hora_minutos = 'HH:mm'; //podria poner environment.format_hour, pero asi es mas "independiente"
        const arrayIntervalos = [];
        const hora_inicio = moment(prmtrFechaHoraInicio, "HH:mm").hours(); 
        const hora_fin = moment(prmtrFechaHoraFin, "HH:mm").hours(); 
        for (let hour = hora_inicio; hour <= hora_fin; hour++) {
            arrayIntervalos.push(moment({ hour }).format(formato_hora_minutos));
            if (hour != hora_fin) {
                //arrayIntervalos.push(moment({ hour, minute: 15}).format(formato_hora_minutos));
                arrayIntervalos.push(moment({ hour, minute: 30}).format(formato_hora_minutos));
                //arrayIntervalos.push(moment({ hour, minute: 45}).format(formato_hora_minutos));
                }

            }
        return arrayIntervalos;
        }





          


    //el objeto calendar2-ionic necesita que "startTime" y "endTime" sean de tipo Date, con la siguietne
    //función lo controlo
    Formatear_Array_Calendar_EventSource (prmtrArrayCalendarEventSource: any) {
        //console.log ("dentro de Formatear_Array_Calendar_EventSource ")
        var that = this;
        var arr_calendar_eventsource

        /*
        var i: number;
        for (i = 0; i < prmtrArrayCalendarEventSource.length; i++) {
            prmtrArrayCalendarEventSource[i].startTime = new Date(prmtrArrayCalendarEventSource[i].startTime);
            prmtrArrayCalendarEventSource[i].endTime = new Date(prmtrArrayCalendarEventSource[i].endTime);
          }
        */
        //para poder acceder a this dentro de ".map", hago lo que indican en...
        //https://stackoverflow.com/questions/30148827/this-is-undefined-inside-map-function-reactjs
        //arr_calendar_eventsource = prmtrArrayCalendarEventSource.map((item) => { //esta opción no funciona
        arr_calendar_eventsource = prmtrArrayCalendarEventSource.map(function(item){ 
            //como puede haber ocasiones que "startTime" y "endTime" estén vacias lo controlo y uso 
            //los campos orginales de la consulta de la BBDD si es necesario
            //console.log ("item.endtime: ", item.endTime)
            //console.log ("item.FECHAHORA_FIN_REGISTRO: ", item.FECHAHORA_FIN_REGISTRO)
            if (this.EstaVacia(item.startTime) ) {
                item.startTime = new Date(item.FECHAHORA_INICIO_REGISTRO);
                }
            else {
                item.startTime = new Date(item.startTime);
                }
            
            if (this.EstaVacia(item.endTime) ) {
                item.endTime = new Date(item.FECHAHORA_FIN_REGISTRO);
                }
            else {
                item.endTime = new Date(item.endTime);
                }

            if (this.EstaVacia(item.title) && !this.EstaVacia(item.TITULO_TIPO_DE_HORA)  ) {
                item.title = item.TITULO_TIPO_DE_HORA;
                }


            return item;
            }, this);

        return arr_calendar_eventsource;
        }


    //como cuando cargamos el this.eventSource para alimentar al obj calendar hay que...
    //...parentizar la carga de eventSource con un setTimeout creo una función general que no me obligue a buscar dicho código
    //...convertir las fechas a date
    //...(quizaas haya algo mas a futuro)
    Cargar_Calendar_eventSource_NO_CONSIGO_HACERLA_FUNCIONAR (prmtrEventSource, prmtrArray) {
        var arrayEventos = this.Formatear_Array_Calendar_EventSource(prmtrArray);
        setTimeout(() => {
            prmtrEventSource = arrayEventos;
            }, 0);
        }

    /*
    prueba_modificar_vble_de_pagina(prmtrVariable): Observable<any> {
        prmtrVariable = "modificada"
        return prmtrVariable
        }
    //public vble_de_pagina : Observable<any> = new Observable<any>();
    
    prueba_modificar_vble_de_pagina(prmtrVariable): Observable<any> {
        console.log("prueba_modificar_vble_de_pagina > prmtrVariable", prmtrVariable)
        prmtrVariable = "modificada"
        //this.vble_de_pagina
        setTimeout(() => {
            prmtrVariable = "modificada2"
            return prmtrVariable
            }, 0);
            //return prmtrVariable
            //var strUrl = environment.URL_JSON_ACCION + 'CharSet_y_CodePage()';
            //console.log ("strUrl: ", strUrl)
            //return this.http.get(strUrl);

        }
    */


    //*NOTA evitar Circular dependency detected
    async AbrirModal_Seleccionar_Area (prmtrSeleccionarAreaPage, prmtrPropositoSeleccionarArea) {
        //console.log ("dentro de AbrirModal_Registrar_Hora_Inicio() ")
        const modal = await this.ModalController.create({
            component: prmtrSeleccionarAreaPage,
            cssClass: 'seleccionar-area'
            //, backdropDismiss: false //	If true, the modal will be dismissed when the backdrop is clicked.
            //encontrado en... https://medium.com/@david.dalbusco/how-to-declare-and-use-modals-in-ionic-v4-4d3f42ac30a3
            //para poder pasar "parametros" al modal...
            , componentProps: {
                'prmtr_Proposito_Seleccionar_Area': prmtrPropositoSeleccionarArea
                }
            });
        
        await modal.present();
        
        modal.onDidDismiss().then((result) => {
            //this.DepuracionService.console_log ("se ha cerrado la ventana modal de seleccion de area usuario", "", "modal area usuario")
            //this.DepuracionService.console_log ("result", result, "modal area usuario")
            });
        
        }
    //*NOTA evitar Circular dependency detected
    //para evitarlo paso la Page que quiero abrir como parametro siguiendo lo que explican en...
    //...https://www.damirscorner.com/blog/posts/20191011-OpeningIonic4ModalsFromACommonService.html
    //esto hay que hacerlo cuando estamos llamadno a un "AbrirModal_..." definido dentro de un servicio (o eso me ha parecido observar)
    async AbrirModal_Informes (prmtrInformesPage: any) {
        //console.log ("dentro de AbrirModal_Registrar_Hora_Inicio() ")
        const modal = await this.ModalController.create({
            component: prmtrInformesPage,
            cssClass: 'informes'
            //, backdropDismiss: false //	If true, the modal will be dismissed when the backdrop is clicked.
            //encontrado en... https://medium.com/@david.dalbusco/how-to-declare-and-use-modals-in-ionic-v4-4d3f42ac30a3
            //para poder pasar "parametros" al modal...
            //, componentProps: {
            //    'prmtr_Proposito_Seleccionar_Area': prmtrPropositoSeleccionarArea
            //    }
            });
        
        await modal.present();
        
        modal.onDidDismiss().then((result) => {
            //this.DepuracionService.console_log ("se ha cerrado la ventana modal de informes", result, "ver informes")
            });
        
        }

    //para redondear una fechahora al cuarto de hora que tenga mas cercano, obtenido en...
    //...https://stackoverflow.com/questions/4968250/how-to-round-time-to-the-nearest-quarter-hour-in-javascript
    Redondear_FechaHora_a_cuartos_de_hora (prmtrFechaHora) {
        var timeToReturn = new Date(prmtrFechaHora);
        timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
        timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
        timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
        return timeToReturn;
        }



    //jpara obtener la semana del mes en la wque estamos uso lo encontrado en...
    //...https://stackoverflow.com/questions/36569633/how-to-get-week-of-the-month-from-date-in-moment-js
    //ojo, tal como se indica aqui..
    //...https://momentjs.com/guides/#/warnings/add-inverted-param/
    //hay que cambiar el orden de los pareametros de subtract
    Semana_del_Mes (prmtrFecha) {
        var int_return: number;
        var date_dia = (this.EstaVacia(prmtrFecha))?new Date():prmtrFecha;
        var int_dia:any = moment(date_dia).format("D");
        //var date_ultimo_dia_mes_anterior = moment().subtract(prmtrDia + 1, 'days') --> NO VA
        var int_semana_del_anio:number = moment(date_dia).isoWeek() + 1;
        var date_ultimo_dia_mes_anterior = moment(date_dia).subtract(int_dia, 'days').toDate()
        var date_primer_dia_mes_actual = moment(date_dia).subtract( (int_dia - 1), 'days').toDate()
        //var int_ultima_semana_del_anio_mes_anterior:number = moment(moment().subtract(prmtrDia, 'days')).isoWeek()
        var int_semana_del_ultimo_dia_mes_anterior:number = moment(date_ultimo_dia_mes_anterior).isoWeek()
        var int_semana_del_primer_dia_mes_actual:number = moment(date_primer_dia_mes_actual).isoWeek()
        //genero el siguiente ajuste encontrado por puro ensayo-error
        //var int_incremento = (int_semana_del_ultimo_dia_mes_anterior == int_semana_del_primer_dia_mes_actual)?0:0;
        var int_incremento = int_semana_del_ultimo_dia_mes_anterior - int_semana_del_primer_dia_mes_actual; //ensayo-error
        //var int_ultima_semana_del_mes_anterior:number = moment().isoWeek()
        
        //lo que me daban las urles antes indicadas era...
        //int_return = moment().isoWeek() - moment().subtract(prmtrDia - 1, 'days').isoWeek() + 1;
        //...pero iba mal con la primera semana del año asi que lo hago desde 0 a ver si lo comprendo y puedo corregir...
        //int_return = int_semana_del_anio - moment().subtract(prmtrDia - 1, 'days').isoWeek() + 1;
        /*
        console.log("")
        console.log("date_dia", date_dia + " ...dia: " + int_dia)
        console.log("moment(date_dia).month()", moment(date_dia).month())
        console.log("date_ultimo_dia_mes_anterior", date_ultimo_dia_mes_anterior + " ...int_semana_del_ultimo_dia_mes_anterior " + int_semana_del_ultimo_dia_mes_anterior)
        console.log("date_primer_dia_mes_actual", date_primer_dia_mes_actual + " ...int_semana_del_primer_dia_mes_actual " + int_semana_del_primer_dia_mes_actual)
        console.log("int_semana_del_anio", int_semana_del_anio)
        //console.log("int_semana_del_ultimo_dia_mes_anterior", int_semana_del_ultimo_dia_mes_anterior)
        //console.log("int_semana_del_primer_dia_mes_actual", int_semana_del_primer_dia_mes_actual)
        console.log("int_incremento", int_incremento)
        */
        //ensayo-error, al comparar con el isoWeek() del anterior mes se lia en este primer mes del año, asi que lo arreglo aqui...
        if (moment(date_dia).month() == 0 ) {
            if (int_semana_del_anio > 6) {int_semana_del_anio = 1} 
            int_return = int_semana_del_anio
            }
        else {
            int_return = int_semana_del_anio - int_semana_del_ultimo_dia_mes_anterior + int_incremento
            }

        //console.log("int_return", int_return)
        return int_return;
        }
    

    //con esta funcion cmabio las propiedades startTime y endTime para que señalen a la semana actual
    //trasladando cada dia de la semana al que corresponda de esta y manteninedo las horas
    Adaptar_Fechas_Registros_Calendario_a_Semana_Actual (prmtrRegistros) {
        //this.DepuracionService.console_log("dentro de Adaptar_Fechas_Registros_Calendario_a_Semana_Actual()", prmtrRegistros, "Adaptar Fechas a Semana Actual")
        //var registros = [...this.eventSource]; //para "clonar" el array y que sea uno nuevo
        //recorro todos los registros (que están separados por horas) y los agrupo en horas consecutivas

        //la fecha actual de servidor...
        //environment.conf_calendario.currentDate

        var registros = prmtrRegistros
        var int_dia_de_semana, str_dia, str_hora, str_start_time, str_end_time
        registros.forEach(element => {

            //Adapto las fechas solo de los registros tipo calendario
            if (element.TIPO_REGISTRO == "REGISTRO CALENDARIO USUARIO" || element.TIPO_REGISTRO == "REGISTRO CALENDARIO AREA") { 
                //* NOTA DepuracionService
                //this.DepuracionService.console_log("0) registros_agrupados > element ", element , "Adaptar_Fechas_a_Adaptar Fechas a Semana ActualSemana_Actual")
                int_dia_de_semana = moment(element.startTime).isoWeekday();
                str_dia = moment(environment.conf_calendario.currentDate2).day(int_dia_de_semana).format("YYYY-MM-DD");
                str_hora = moment(element.startTime).format("HH:mm:ss");
                //this.DepuracionService.console_log("registros_agrupados > element > hora", str_hora, "Adaptar Fechas a Semana Actual")
                //this.DepuracionService.console_log("registros_agrupados > element > dia de la semana", int_dia_de_semana, "Adaptar Fechas a Semana Actual")
                //this.DepuracionService.console_log("registros_agrupados > element > dia de esta semana", str_dia, "Adaptar Fechas a Semana Actual")
                //
                str_start_time = str_dia + " " + str_hora
                //* NOTA DepuracionService
                //this.DepuracionService.console_log("registros_agrupados > element > str_start_time", str_start_time, "Adaptar Fechas a Semana Actual")
                element.startTime = new Date(str_start_time);
                
                int_dia_de_semana = moment(element.endTime).isoWeekday();
                str_dia = moment(environment.conf_calendario.currentDate2).day(int_dia_de_semana).format("YYYY-MM-DD");
                str_hora = moment(element.endTime).format("HH:mm:ss");
                str_end_time = str_dia + " " + str_hora
                //* NOTA DepuracionService
                //this.DepuracionService.console_log("registros_agrupados > element > str_end_time", str_end_time, "Adaptar Fechas a Semana Actual")
                element.endTime = new Date(str_end_time);
                //* NOTA DepuracionService
                //this.DepuracionService.console_log("1) registros_agrupados > element ", element , "Adaptar Fechas a Semana Actual")
                }
            


            });

        //this.DepuracionService.console_log("0 registros_desagrupados ", registros_desagrupados , "guardar calendario")
        return registros;
        }


    Mostrar_Dia_Seleccionado (prmtrEvent, prmtrThisConfCalendar) {
        //console.log('Selected time: ' + event.selectedTime + ', hasEvents: ' + (event.events !== undefined && event.events.length !== 0));
        //this.DepuracionService.console_log("dentro de onTimeSelected", null, "seleccionar dia en semana");
        //this.DepuracionService.console_log("event", event, "seleccionar dia en semana");
        //para que al seleccionar un día en la vista tipo semana me lleve a ese día debo indicarlo a continuación
        //pero antes controlo que...
        //...no se haya pulsado en el mismo día en el que estmos para evitar una recarga innecesaria
        var str_Dia_Calendar_CurrentDate = moment(prmtrThisConfCalendar.currentDate).format('YYYY-MM-DD')
        var str_Dia_Event_SelectedTime = moment(prmtrEvent.selectedTime).format('YYYY-MM-DD')
        //this.DepuracionService.console_log("str_Dia_Calendar_CurrentDate"   , str_Dia_Calendar_CurrentDate  , "seleccionar dia en semana");
        //this.DepuracionService.console_log("str_Dia_Event_SelectedTime"     , str_Dia_Event_SelectedTime    , "seleccionar dia en semana");
        
        if (moment(prmtrThisConfCalendar.currentDate).format('YYYY-MM-DD') != moment(prmtrEvent.selectedTime).format('YYYY-MM-DD') ) {
            //esto produce un error de tipo "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked"
            //preguntado aqui...https://github.com/twinssbc/Ionic2-Calendar/issues/570
            //parece que no influye en el funcionamiento de la app pero a ver si se puede solucionar
            prmtrThisConfCalendar.currentDate = prmtrEvent.selectedTime; //...provoco el salto al día que he clicado
            prmtrThisConfCalendar.currentDate2 = prmtrEvent.selectedTime; //...NO provoco el salto al día que he clicado
            //prmtrThisConfCalendar.currentDate = new Date(str_Dia_Event_SelectedTime); 
            }
        //...que no estemos ya en modo día (quizas sea redundante ya que con la anterior condición se evita, pero no pide pan.)
        if (prmtrThisConfCalendar.mode != 'day') {
            prmtrThisConfCalendar.mode = 'day'; 
            }
        
        }

}
