import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { GeneralService } from './general.service';

@Injectable(
    {providedIn: 'root'}
    )
export class DepuracionService {

    constructor(private GeneralService: GeneralService) {
        //environment.gbl_mostrar_console_log = true
        //si la siguiente vble no está vacia, solo muestra los "console_log" cuyo tercer parametro sea igual a ese
        //environment.gbl_console_log_dato_a_mostrar = ""
        //environment.gbl_console_log_dato_a_mostrar = "asignar_ble"
        //environment.gbl_console_log_dato_a_mostrar = "path_device"
        //environment.gbl_console_log_dato_a_mostrar = "conectar"
        
        }


    prueba () {
        console.log ("estoy dentro de prueba_Dentro_de_Depuracion_js")
        alert("estoy dentro de prueba_Dentro_de_Depuracion_js")
        //console_log("estoy dentro de prueba_Dentro_de_Depuracion_js")
        }



    console_log (prmtr_str_vble: string, prmtr_valor_vble: any, prmtr_dato_a_mostrar: string) {
        var str_console_log = ""
        var valor_vble = prmtr_valor_vble;
        //var str_linea = "--------------------------------------------------"
        var bln_mostrar_console_log = environment.gbl_mostrar_console_log
        //console.log ("environment.gbl_mostrar_console_log: " + environment.gbl_mostrar_console_log)
        //console.log ("environment.gbl_console_log_dato_a_mostrar: " + environment.gbl_console_log_dato_a_mostrar)
        if (bln_mostrar_console_log && !this.GeneralService.EstaVacia(environment.gbl_console_log_dato_a_mostrar)) {
            bln_mostrar_console_log = (environment.gbl_console_log_dato_a_mostrar == prmtr_dato_a_mostrar)
                                       ||
                                       this.GeneralService.EstaVacia(prmtr_dato_a_mostrar) //2021_04_30: para que se muestre cuando "prmtr_dato_a_mostrar" este vacio
            }

        //console.log(JSON.stringify(environment));

        //console.log (typeof( eval(prmtr_str_vble) ));
        //console.log ("bln_mostrar_console_log: " + bln_mostrar_console_log)
        if (bln_mostrar_console_log) {
            //console.log(str_linea)
            console.log(" ")
            str_console_log += prmtr_str_vble 
            
            if (this.GeneralService.EstaVacia(valor_vble)) { 
                console.log (str_console_log)
                }
            else {
                
                //console.log("typeof... " + typeof(vble_console_log))
                if (typeof(valor_vble) == "object") {
                    //valor_vble = JSON.stringify(valor_vble)
                    valor_vble = JSON.stringify(valor_vble, null, "\t")
                    console.log (str_console_log + ": " )
                    console.log (valor_vble)
                    }
                else {
                    if (this.GeneralService.EstaVacia(valor_vble)) {
                        valor_vble = "undefined"
                        }
                    console.log (str_console_log + ": " + valor_vble)
                    }

                
                }
            //console.log(str_linea)
            }
        }



}
