import { Component, ViewChild, OnInit, Inject, LOCALE_ID }      from '@angular/core';
import { ModalController, NavParams, PickerController}          from '@ionic/angular';
import { GeneralService }                                       from 'src/app/services/general.service';
import { DepuracionService }                                    from 'src/app/services/depuracion.service';
import { environment, modelos_de_datos, slidemenu_options }     from 'src/environments/environment';
import { InformeComputoDeHorasPage }                            from 'src/app/pages/informe-computo-de-horas/informe-computo-de-horas.page'
import { TiposDeHoraService }                                   from 'src/app/services/bbdd-tipos-de-hora.service';

import * as moment from 'moment';

@Component({
  selector: 'app-configurar-informe-computo-de-horas',
  templateUrl: './configurar-informe-computo-de-horas.page.html',
  styleUrls: ['./configurar-informe-computo-de-horas.page.scss'],
})
export class ConfigurarInformeComputoDeHorasPage implements OnInit {

    prmtr_usuario_o_area:any;
    prmtr_Proposito_Seleccionar_Area: string;

    slidemenu_option_elegida: any;
    
    iondatetimepicker_inicio_options: any;
    iondatetimepicker_fin_options: any;

    //array_datepicker_columns: any;
    //ionpicker_tipos_de_horas_options: any;
    cod_tipo_de_hora_seleccionado: number = null;
    str_tipo_de_hora_seleccionado: string

    boton_mostrarinforme_disabled: boolean = true;

    //Debido a que si meto directamente el tag <ion-datetime...> se desmaqueta sigo el tutorial...
    //    https://blog.jarmemori.es/tech/2016/09/14/display_ionic_datetime_picker_programmatically.html
    //que permite evitarlo.
    @ViewChild('datePicker_inicio') datePicker_inicio;
    @ViewChild('datePicker_fin') datePicker_fin;

    constructor(@Inject(LOCALE_ID) private locale: string
        , private ModalController: ModalController
        , private NavParams: NavParams
        , private PickerController: PickerController
        , private DepuracionService: DepuracionService
        , private GeneralService: GeneralService
        , private TiposDeHoraService: TiposDeHoraService
        ) { 
            moment.locale('es');

            this.prmtr_usuario_o_area = this.NavParams.get('prmtr_usuario_o_area');
            this.prmtr_Proposito_Seleccionar_Area = this.NavParams.get('prmtr_Proposito_Seleccionar_Area');

            this.slidemenu_option_elegida = slidemenu_options.find(element => element.id === this.prmtr_Proposito_Seleccionar_Area);

            this.DepuracionService.console_log ("constructor > this.prmtr_Proposito_Seleccionar_Area", this.prmtr_Proposito_Seleccionar_Area, "configurar Informe Computo de Horas")
            this.DepuracionService.console_log ("constructor > this.prmtr_usuario_o_area"            , this.prmtr_usuario_o_area, "configurar Informe Computo de Horas")


            this.ionDateTimePicker_Inicio_y_Fin_inicializar();

            //this.array_datepicker_columns = []
            //this.ionpicker_tipos_de_horas_options = [] //hay que inicializarlo para que lo encuentre para cargarlo

            //ver * NOTA traslado de ionpicker_Tipos_de_Horas... a un componente
            //this.ionPicker_Tipos_de_Horas_cargar_array_options(0); //por defecto cargamos la option 0 = TODOS


        } // constructor

    ngOnInit() {
        } // ngOnInit

    close(prmtrDismiss) {
        this.ModalController.dismiss(prmtrDismiss);
        }

    ionDateTimePicker_Inicio_y_Fin_inicializar () {
        //aplico .format() para que me devuelva el formato ISO (ISO 8601) que funcion en el datetimepicker
        var dateAhora = moment().format();
        var dateAyer = moment().subtract(1, 'd').format();

        this.iondatetimepicker_inicio_options = {...environment.datetime_picker};
        this.iondatetimepicker_inicio_options.value = dateAyer //establezco una fecha para uqe haya algo cargado y no tener que controlarlo
        this.iondatetimepicker_inicio_options.cssClass = "es_modal"
        //this.iondatetimepicker_inicio_options.buttons[0].text = "Guardar Fecha-Hora Inicial" //asi podemos cambiar el texto de los botones
        this.iondatetimepicker_inicio_options.buttons = [
                {
                text: 'Establecer Inicio',  
                handler: (event) => { 
                    this.DepuracionService.console_log ("guardar fecha inicial", event, "configurar Informe Computo de Horas")
                    this.iondatetimepicker_inicio_options.actualizar_value(event);
                    }  
                }, 
                {
                text: 'Cerrar',   
                handler: () => { 
                    this.DepuracionService.console_log ("datetime picker cerrado", null, "configurar Informe Computo de Horas")
                    /*return false; //con esto no se cierra*/ 
                    } 
                }
            ]

        this.iondatetimepicker_fin_options = {...environment.datetime_picker};
        this.iondatetimepicker_fin_options.value = dateAhora //establezco una fecha para uqe haya algo cargado y no tener que controlarlo
        //this.iondatetimepicker_fin_options.buttons[0].text = "Guardar Fecha-Hora Inicial" //asi podemos cambiar el texto de los botones
        this.iondatetimepicker_fin_options.cssClass = "es_modal"
        this.iondatetimepicker_fin_options.buttons = [
            {
            text: 'Establecer Fin',  
            role: 'save',
            //cssClass: 'cssGuardar', //no funciona
            //class: 'cssGuardar2', //no funciona
            handler: (event) => { 
                this.DepuracionService.console_log ("guardar fecha final", event, "configurar Informe Computo de Horas")
                this.iondatetimepicker_fin_options.actualizar_value(event);
                }  
            }, 
            {
            text: 'Cerrar',   
            role: 'cancel',
            handler: () => { 
                this.DepuracionService.console_log ("datetime picker cerrado", null, "configurar Informe Computo de Horas")
                /*return false; //con esto no se cierra*/ 
                } 
            }
            ]
    }



    Recepcionar_ionpicker_cod_tipo_de_hora (prmtrCodTipoDeHora_recibido) {
        //this.DepuracionService.console_log("Recepcionar_ionpicker_cod_tipo_de_hora > prmtrCodTipoDeHora_recibido", prmtrCodTipoDeHora_recibido, "ionpicker tipo de hora component recepcionar")
        this.cod_tipo_de_hora_seleccionado = prmtrCodTipoDeHora_recibido
        this.Establecer_boton_mostrarinforme_disabled()
        }
    Recepcionar_ionpicker_str_tipo_de_hora (prmtrStrTipoDeHora_recibido) {
        //this.DepuracionService.console_log("Recepcionar_ionpicker_cod_tipo_de_hora > prmtrStrTipoDeHora_recibido", prmtrStrTipoDeHora_recibido, "ionpicker tipo de hora component recepcionar")
        this.str_tipo_de_hora_seleccionado = prmtrStrTipoDeHora_recibido
        }


    //async AbrirModal_Informe_Computo_de_Horas(prmtrInformeComputoDeHorasPage) {
    async AbrirModal_Informe_Computo_de_Horas() {
        //console.log ("dentro de AbrirModal_Registrar_Hora_Inicio() ")
        const modal = await this.ModalController.create({
            //component: prmtrInformeComputoDeHorasPage,
            component: InformeComputoDeHorasPage,
            cssClass: 'informe-computo-de-horas', 
            //, backdropDismiss: false //	If true, the modal will be dismissed when the backdrop is clicked.
            componentProps: {
                'prmtr_usuario_o_area': this.prmtr_usuario_o_area
                , 'prmtr_Proposito_Seleccionar_Area': this.prmtr_Proposito_Seleccionar_Area
                , 'prmtr_fecha_inicio': this.iondatetimepicker_inicio_options.formatear_value()
                , 'prmtr_fecha_fin': this.iondatetimepicker_fin_options.formatear_value()
                , 'prmtr_cod_tipo_de_hora': this.cod_tipo_de_hora_seleccionado
                , 'prmtr_str_tipo_de_hora': this.str_tipo_de_hora_seleccionado
                }
        });
        
        await modal.present();

        //para depurar
        //var obj_FechasLimite = this.GeneralService.Fechas_Limite_segun_tipo_vista (this.conf_calendario.currentDate, this.conf_calendario.mode)
        //this.DepuracionService.console_log("obj_FechasLimite", obj_FechasLimite, "fechas cargar registros")

        modal.onDidDismiss().then((result) => {

            });
        }


        Establecer_boton_mostrarinforme_disabled () {
            console.log ("dentro de Establecer_boton_mostrarinforme_disabled")
            console.log ("this.cod_tipo_de_hora_seleccionado: " + this.cod_tipo_de_hora_seleccionado)
            this.boton_mostrarinforme_disabled = false
            if (this.GeneralService.EstaVacia(this.cod_tipo_de_hora_seleccionado)) {
                this.boton_mostrarinforme_disabled = true
                }
            }



}





   /*
    * NOTA traslado de ionpicker_Tipos_de_Horas... a un componente
    las funciones que antes se usaban solo aqui ahora se usan en mas sitios asi que las comento y las traslado al componente
    ionpicer-tipos-de-hora.component


    ionPicker_Tipos_de_Horas_cargar_array_options (prmtrCodTipoDeHora) {
        this.TiposDeHoraService.getTiposDeHora().subscribe(result => {
            var array_result: any[] = [];
            //console.log (result)
            if (result.resultado == "ok") {
                this.ionpicker_tipos_de_horas_options = [];
                //agrego una primera opción que permita hacer una busqueda sobre todos los tipos de hora
                array_result.push({COD_TIPO_DE_HORA: 0, TITULO_TIPO_DE_HORA: "TODOS"});
                array_result = array_result.concat(result.data);
                //cargo las opciones obteneidas de la base de datos
                array_result.forEach(function (value:any, index, array) {
                    this.ionpicker_tipos_de_horas_options.push({
                        text: value.TITULO_TIPO_DE_HORA,
                        value: value.COD_TIPO_DE_HORA
                        })
                    if (value.COD_TIPO_DE_HORA == prmtrCodTipoDeHora) {this.str_tipo_de_hora_seleccionado = value.TITULO_TIPO_DE_HORA}
                    }, this);
                console.log ("array_result", array_result)
                } // if (result.resultado == "ok")

            }); //this.TiposDeHoraService.getTiposDeHora().subscribe
            //var options_tipos_de_horas: [any];
        }


    async ionPicker_Tipos_de_Horas_abrir (prmtrCodTipoDeHora) {
        console.log ("dentro de ionPicker_Tipos_de_Horas_abrir")
        var array_columns: any;

        var intCodTipoDeHora = (this.GeneralService.EstaVacia(prmtrCodTipoDeHora))?0:prmtrCodTipoDeHora;
        var int_selected_index = this.ionpicker_tipos_de_horas_options.findIndex(option => option.value === intCodTipoDeHora)

        array_columns = [
            {
                name: 'tipo_de_hora',
                cssClass: 'class_tipo_de_hora',
                //options: [{text: 'a', value: 'a'}, {text: 'b', value: 'b'}, {text: 'c', value: 'c'}, {text: 'd', value: 'd'}]
                options: this.ionpicker_tipos_de_horas_options,
                selectedIndex: int_selected_index,
                //prefix: 'tipo de hora', //aparece a la izquierda de la columna
            }
            ];

        //NOTA
        //para evitar que la segunda vez que se muestren las options estas aparezcan "amontonadas"
        //ver...https://github.com/ionic-team/ionic-framework/issues/17664
        array_columns = JSON.parse(JSON.stringify(array_columns));
        //OJO: hay que hacerlo sonbre el array_columns y no sobre la siguietne vble configuracion_picker 
        //     como pone en algún ejemplo porque sino no se ejecuta el handler


        let configuracion_picker = {
            buttons: [
                {
                    text:'Establecer Tipo',
                    role: 'save',
                    handler:(value:any) => {
                        //console.log(value);
                        this.cod_tipo_de_hora_seleccionado = value.tipo_de_hora.value;
                        this.str_tipo_de_hora_seleccionado = value.tipo_de_hora.text;
                        }
                },
                {
                    text: "Cerrar",
                    role: 'cancel'
                },
                ],
            cssClass: 'es_modal',
            columns: array_columns // NOTA IMPORTANTE 
                                    // definir columns con una vble "this." para que se pueda acceder dentro del handler a this
            };
        


        //let picker = await this.PickerController.create(configuracion_picker);
        let picker = await this.PickerController.create(configuracion_picker);
        picker.present()
        }
    */
    