import { Injectable }                                       from '@angular/core';
import { HttpClient, HttpHeaders }                          from '@angular/common/http';
import { Observable }                                       from 'rxjs';
//import { FormBuilder, FormGroup, FormControl, Validators }  from "@angular/forms"; // don't forget to import FormGroup
import { environment, modelos_de_datos }                    from 'src/environments/environment';
import { DepuracionService }                                from 'src/app/services/depuracion.service';
import { GeneralService }                                   from 'src/app/services/general.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class bbddRegistrosHorariosService {

    constructor(private HttpClient: HttpClient
                , private DepuracionService: DepuracionService
                , private GeneralService: GeneralService
                //, private FormBuilder: FormBuilder
                ) { 
    
        }



    getRegistrosHorarios(prmtrCodRegistroHorario: number, prmtrCodUsuario:number, prmtrHoraDesde:string, prmtrHoraHasta:string): Observable<any> {
        //OJO (2020_09_30): este cambio a componer la url de la siguiente forma no esta probada para esta consulta
        //function consulta_Registros_Horarios (prmtrCodClte, prmtrCodArea, prmtrCodUsuario, prmtrFechaHoraDesde, prmtrFechaHoraHasta, prmtrCodTipoDeHora, prmtrObtenerAcumulados)
        var strFuncionServidor = 'consulta_Registros_Horarios (' + environment.COD_CLIENTE + ', null, ' + prmtrCodUsuario 
                                                                 + ', "' + prmtrHoraDesde + '", "' + prmtrHoraHasta + '", null, false)'
        //var strUrl = this.GeneralService.Componer_URL_Servidor(strFuncionServidor, true)
        //this.DepuracionService.console_log("getRegistrosHorarios > strUrl", strUrl, "cargar registros");
        //return this.HttpClient.get(strUrl);
        return this.GeneralService.Ejecutar_en_Servidor (strFuncionServidor, true, null);
        }

    getRegistrosHorariosAcumulados(prmtrCodArea: number, prmtrCodUsuario:number, prmtrFechaHoraDesde:string, prmtrFechaHoraHasta:string, prmtrCodTipoDeHora: number): Observable<any> {
        
        let intCodArea = (this.GeneralService.EstaVacia(prmtrCodUsuario))?prmtrCodArea:"null";
        let intCodUsuario = (this.GeneralService.EstaVacia(prmtrCodUsuario))?"null":prmtrCodUsuario;
        let strCodtipoDeHora = (this.GeneralService.EstaVacia(prmtrCodTipoDeHora) || prmtrCodTipoDeHora == 0)?"null":prmtrCodTipoDeHora;

        //function consulta_Registros_Horarios (prmtrCodClte, prmtrCodArea, prmtrCodUsuario, prmtrFechaHoraDesde, prmtrFechaHoraHasta, prmtrCodTipoDeHora, prmtrObtenerAcumulados)
        var strFuncionServidor = 'consulta_Registros_Horarios (' + environment.COD_CLIENTE + ', ' + intCodArea + ',' + intCodUsuario
                                                                 + ', "' + prmtrFechaHoraDesde + '", "' + prmtrFechaHoraHasta + '", ' + strCodtipoDeHora + ', true)'
        //var strUrl = this.GeneralService.Componer_URL_Servidor(strFuncionServidor, true)
        //this.DepuracionService.console_log("getRegistrosHorariosAcumulados > strUrl", strUrl, "mostrar Informe Computo de Horas");
        //return this.HttpClient.get(strUrl);
        return this.GeneralService.Ejecutar_en_Servidor (strFuncionServidor, true, null);
        }

    //prmtrFormGroup lo pongo como any porque si lo pongo como FormGroup da un casque y si lo pongo como FormBuilder, aunque funciona no hay concordancia entre lo que paso
    //y lo que espera la función en ese parametro
    saveRegistrarHoraInicio(prmtrHoraInicio:string, prmtrTipoHora: number, prmtrFormGroup: any): Observable<any> {
          //2020_12_30:
        //el parametro prmtrFormGroup me da toda la información dentro de prmtrFormGroup.value
        //si no esta definido utilizo solo los 2 primeros parametros tal como estaba hecho anteriormente (para mantener retrocompatibilidad, por si acaso)
        var strFechaHoraInicio, strFechaHoraFin, intTipoHora, strObservaciones, intCodUsuarioSustituido

        if (this.GeneralService.EstaVacia(prmtrFormGroup)) {
            strFechaHoraInicio = prmtrHoraInicio
            intTipoHora = prmtrTipoHora
            }
        else {
            //si se ha indicado una fecha de hora de inicio (no todos los tipos de hora lo permiten) diferente a la hora en la que 
            //se inicio el registro de hora lo contemplo, sino establezco la fecha que viene como parametro prmtrHoraInicio
            if (this.GeneralService.EstaVacia(prmtrFormGroup.value.formControl_FechaHoraInicio)) {
                strFechaHoraInicio = prmtrHoraInicio
                }
            else {
                strFechaHoraInicio = moment(prmtrFormGroup.value.formControl_FechaHoraInicio).format(environment.format_datehour)
                }
            //si se ha indicad una hora de fin (no todos los tipos de hora lo permiten) la pongo
            if (!this.GeneralService.EstaVacia(prmtrFormGroup.value.formControl_FechaHoraFin)) {
                strFechaHoraFin = moment(prmtrFormGroup.value.formControl_FechaHoraFin).format(environment.format_datehour)
                }
            
            intTipoHora = JSON.parse(prmtrFormGroup.value.formControl_TiposDeHora).cod
            strObservaciones = prmtrFormGroup.value.formControl_Observaciones
            intCodUsuarioSustituido = prmtrFormGroup.value.formControl_SustitucionDe
            
            }


        let data = {
            tipo_actualizacion: "INSERT" //INSERT o UPDATE
            , nombre_tabla: "TB_REGISTROS_HORARIOS"
            , nombre_campo_clave: "COD_REGISTRO_HORARIO"//hay que especificarlo para que devuelva el nuevo cod del registro que insertemos
            //, valor_campo_clave: null //ahora no se usa asi que podemos comentarlo pero permitiría hacer una inserción con un cod determinado
            , campos: [
                {nombre_campo: "COD_USUARIO"                        , valor_campo: environment.usuario.cod    , tipo_campo: "int"},
                {nombre_campo: "COD_CLIENTE"                        , valor_campo: environment.COD_CLIENTE    , tipo_campo: "int"},
                {nombre_campo: "COD_TIPO_DE_HORA"                   , valor_campo: intTipoHora                , tipo_campo: "int"},
                {nombre_campo: "FECHAHORA_INICIO_REGISTRO_HORARIO"  , valor_campo: strFechaHoraInicio         , tipo_campo: "date"},
                ]
            }
        if (!this.GeneralService.EstaVacia(strFechaHoraFin)) {
            data.campos.push(
                {nombre_campo: "FECHAHORA_FIN_REGISTRO_HORARIO"     , valor_campo: strFechaHoraFin             , tipo_campo: "date"},
                )
            }
        if (!this.GeneralService.EstaVacia(strObservaciones)) {
            data.campos.push(
                {nombre_campo: "OBSERVACIONES_REGISTRO_HORARIO"     , valor_campo: strObservaciones             , tipo_campo: "string"},
                )
            }

        //no se porque llega intCodUsuarioSustituido = "" pero lo controlo a continuación
        //alert("intCodUsuarioSustituido: " + intCodUsuarioSustituido)
        if (intCodUsuarioSustituido != 'NADIE' && !this.GeneralService.EstaVacia(intCodUsuarioSustituido)) {
            //si no es 'NADIE' tiene un formato JSON que trato AQUI, a continuación...
            intCodUsuarioSustituido = JSON.parse(prmtrFormGroup.value.formControl_SustitucionDe).cod
            data.campos.push(
                {nombre_campo: "COD_USUARIO_SUSTITUIDO"             , valor_campo: intCodUsuarioSustituido      , tipo_campo: "int"},
                )
            }


        //return this.HttpClient.get('http://pc/registrohorario/asp/ejecutar_ajax.asp?accion=tipos_de_hora(null)');
        //var strUrl = this.GeneralService.Componer_URL_Servidor('Class_CrearConsultasBBDD_ACTUALIZAR_desde_JSON(null)', false);
        //this.DepuracionService.console_log("strUrl", strUrl, "");
        //return this.HttpClient.post(strUrl, data, environment.http_post_options); // <- esta es la linea buena
        return this.GeneralService.Ejecutar_en_Servidor ('Class_CrearConsultasBBDD_ACTUALIZAR_desde_JSON(null)', false, data) 
        }

    saveRegistrarHoraFin(prmtrCodRegistroHorario: number, prmtrHoraFin:string): Observable<any> {
        //return this.HttpClient.get('http://pc/registrohorario/asp/ejecutar_ajax.asp?accion=tipos_de_hora(null)');
        //var strUrl = environment.URL_JSON_ACCION + 'Registrar_Hora_de_Fin()'
        var strUrl = environment.URL_JSON_ACCION + 'Class_CrearConsultasBBDD_ACTUALIZAR_desde_JSON(null)'
        this.DepuracionService.console_log("strUrl", strUrl, "");

        //this.radioPrueba.val

        //this.DepuracionService.console_log("antes de post", "", "");
        /*
        let data = modelos_de_datos.registro_de_hora_final;
        data.cod_registro_horario = prmtrCodRegistroHorario;
        data.hora_de_fin = prmtrHoraFin;
        this.DepuracionService.console_log("data", data, "");
        */
        /*
        let data = modelos_de_datos.insert_update;
        data.tipo_actualizacion = "UPDATE"
        data.nombre_tabla = "TB_REGISTROS_HORARIOS"
        data.nombre_campo_clave = "COD_REGISTRO_HORARIO"
        data.valor_campo_clave = prmtrCodRegistroHorario
        //let campo = modelos_de_datos.insert_update.campos
        let campos = [{nombre_campo: "aa", valor_campo: "bb", tipo_campo: "cc"}]
        data.campos = campos;
        //data.campos = [{                        nombre_campo: ""                        }]
        */

        let data = {
            tipo_actualizacion: "UPDATE" //INSERT o UPDATE
            , nombre_tabla: "TB_REGISTROS_HORARIOS"
            , nombre_campo_clave: "COD_REGISTRO_HORARIO"
            , valor_campo_clave: prmtrCodRegistroHorario // para cuando es tipo update saber que registro hay que actualizar
            , campos: [
                //{nombre_campo: "COD_USUARIO"                      , valor_campo: environment.usuario.cod  , tipo_campo: "int"},
                {nombre_campo: "FECHAHORA_FIN_REGISTRO_HORARIO"   , valor_campo: prmtrHoraFin             , tipo_campo: "date"},
                //{nombre_campo: "TITULO_REGISTRO_HORARIO"          , valor_campo: "prueba 2"               , tipo_campo: "string"},
                {nombre_campo: "COD_CLIENTE"                      , valor_campo: environment.COD_CLIENTE  , tipo_campo: "int"},
                ]
            , campos_retorno: "COD_REGISTRO_HORARIO, FECHAHORA_INICIO_REGISTRO_HORARIO, FECHAHORA_FIN_REGISTRO_HORARIO, COD_USUARIO, COD_CLIENTE"
            }

        //let data = [{campo1: 'valor a1', campo2: 12}, {campo1: 'valor 21', campo2: 22}, {campo1: 'valor 31', campo2: 32}];
        return this.HttpClient.post(strUrl, data, environment.http_post_options);
        //this.DepuracionService.console_log("antes de get", "", "");
        //return this.HttpClient.get(strUrl);        
        }




    //saveActualizarRegistroHorario(prmtrCodRegistroHorario: number, prmtrHoraInicio:string, prmtrHoraFin:string): Observable<any> {
    saveActualizarRegistroHorario(prmtrRegistroHorario: any): Observable<any> {
        let str_fechahora_inicio = prmtrRegistroHorario.fecha_inicio + " " + prmtrRegistroHorario.hora_inicio
        let str_fechahora_fin = prmtrRegistroHorario.fecha_fin + " " + prmtrRegistroHorario.hora_fin

        this.DepuracionService.console_log("saveRegistrarHoraFin", prmtrRegistroHorario, "guardar registro horario")
        let str_cod_registro = prmtrRegistroHorario.COD_REGISTRO 
        let str_tipo_actualizacion = "UPDATE"
        if (str_cod_registro == "NUEVO") {
            //str_cod_registro = ""
            str_tipo_actualizacion = "INSERT"
            }

        let data = {
            tipo_actualizacion: str_tipo_actualizacion //INSERT o UPDATE
            , nombre_tabla: "TB_REGISTROS_HORARIOS"
            , nombre_campo_clave: "COD_REGISTRO_HORARIO"//hay que especificarlo para que devuelva el nuevo cod del registro que insertemos
            , valor_campo_clave: str_cod_registro
            , campos: [
                {nombre_campo: "FECHAHORA_INICIO_REGISTRO_HORARIO"  , valor_campo: str_fechahora_inicio                     , tipo_campo: "date"},
                {nombre_campo: "FECHAHORA_FIN_REGISTRO_HORARIO"     , valor_campo: str_fechahora_fin                        , tipo_campo: "date"},
                {nombre_campo: "COD_TIPO_DE_HORA"                   , valor_campo: prmtrRegistroHorario.COD_TIPO_DE_HORA    , tipo_campo: "int"},
                ]
            }

        if (str_tipo_actualizacion == "INSERT") {
            data.campos.push({nombre_campo: "COD_CLIENTE"           , valor_campo: environment.COD_CLIENTE                  , tipo_campo: "int"},
                                {nombre_campo: "COD_USUARIO"        , valor_campo: prmtrRegistroHorario.COD_USUARIO         , tipo_campo: "int"}
                            )
            }

        this.DepuracionService.console_log("saveRegistrarHoraFin", data, "guardar registro horario")


        //return this.HttpClient.get('http://pc/registrohorario/asp/ejecutar_ajax.asp?accion=tipos_de_hora(null)');
        //var strUrl = this.GeneralService.Componer_URL_Servidor('Class_CrearConsultasBBDD_ACTUALIZAR_desde_JSON(null)', false);
        //this.DepuracionService.console_log("strUrl", strUrl, "");
        //return this.HttpClient.post(strUrl, data, environment.http_post_options);
        //                         Ejecutar_en_Servidor (prmtrFunctionServidor                                 , prmtrObtenerJSONGeneral, prmtrData)
        return this.GeneralService.Ejecutar_en_Servidor ('Class_CrearConsultasBBDD_ACTUALIZAR_desde_JSON(null)', false                  , data) 
        }



    }