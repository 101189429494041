import { Component, ViewChild, OnInit, Inject, LOCALE_ID }      from '@angular/core';
import { ModalController, NavParams}                            from '@ionic/angular';
import { GeneralService }                                       from 'src/app/services/general.service';
import { DepuracionService }                                    from 'src/app/services/depuracion.service';
import { environment, modelos_de_datos, slidemenu_options }     from 'src/environments/environment';
import { bbddRegistrosHorariosService }                         from 'src/app/services/bbdd-registros-horarios.service';

import * as moment from 'moment';

@Component({
  selector: 'app-informe-computo-de-horas',
  templateUrl: './informe-computo-de-horas.page.html',
  styleUrls: ['./informe-computo-de-horas.page.scss'],
})
export class InformeComputoDeHorasPage implements OnInit {

    prmtr_usuario_o_area:any;
    prmtr_Proposito_Seleccionar_Area: string;
    prmtr_fecha_inicio: string;
    prmtr_fecha_fin: string;
    prmtr_cod_tipo_de_hora: number;
    prmtr_str_tipo_de_hora: string;

    slidemenu_option_elegida: any;

    //para mostrar el resultado en pantalla
    horas:string = "0";
    minutos:string = "0";
    segundos:string = "0";


    constructor(@Inject(LOCALE_ID) private locale: string
                , private ModalController: ModalController
                , private NavParams: NavParams
                , private DepuracionService: DepuracionService
                , private GeneralService: GeneralService
                , private bbddRegistrosHorariosService: bbddRegistrosHorariosService
                ) { 

        moment.locale('es');

        this.prmtr_usuario_o_area = this.NavParams.get('prmtr_usuario_o_area');
        this.prmtr_Proposito_Seleccionar_Area = this.NavParams.get('prmtr_Proposito_Seleccionar_Area');
        this.prmtr_fecha_inicio = this.NavParams.get('prmtr_fecha_inicio');
        this.prmtr_fecha_fin = this.NavParams.get('prmtr_fecha_fin');
        this.prmtr_cod_tipo_de_hora = this.NavParams.get('prmtr_cod_tipo_de_hora');
        this.prmtr_str_tipo_de_hora = this.NavParams.get('prmtr_str_tipo_de_hora');
        this.slidemenu_option_elegida = slidemenu_options.find(element => element.id === this.prmtr_Proposito_Seleccionar_Area);


        let intCodArea, intCodUsuario
        
        if ( this.GeneralService.EstaVacia(this.prmtr_usuario_o_area.COD_USUARIO) ) {
            intCodArea = this.prmtr_usuario_o_area.COD_AREA;
            intCodUsuario = null;
            }
        else {
            intCodArea = null;
            intCodUsuario = this.prmtr_usuario_o_area.COD_USUARIO;
            }
        
        this.Cargar_Registros_Horarios_Acumulados(intCodArea, intCodUsuario, this.prmtr_fecha_inicio, this.prmtr_fecha_fin, this.prmtr_cod_tipo_de_hora)

        }

    ngOnInit() {
        }

    Cargar_Registros_Horarios_Acumulados (prmtrCodArea: number, prmtrCodUsuario:number, prmtrFechaHoraDesde:string, prmtrFechaHoraHasta:string, prmtrCodTipoDeHora: number) {

        this.bbddRegistrosHorariosService.getRegistrosHorariosAcumulados (prmtrCodArea, prmtrCodUsuario, prmtrFechaHoraDesde, 
                                                                          prmtrFechaHoraHasta, prmtrCodTipoDeHora).subscribe(result => {
            this.DepuracionService.console_log ("Cargar_Registros_Horarios_Acumulados > result", result, "mostrar Informe Computo de Horas")
            if (!this.GeneralService.EstaVacia(result.data)) {
                this.horas = result.data[0].HORAS
                this.minutos = result.data[0].MINUTOS
                this.segundos = result.data[0].SEGUNDOS
                }

            });
        
        };

    close(prmtrDismiss) {
        this.ModalController.dismiss(prmtrDismiss);
        }


}
