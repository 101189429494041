import { Component, OnInit }                                from '@angular/core';
import { Validators, FormBuilder, FormGroup }               from "@angular/forms"; // don't forget to import FormGroup
import { Router }                                           from '@angular/router';
import { ModalController, NavParams, PopoverController}     from '@ionic/angular';
import { GeneralService }                                   from 'src/app/services/general.service';
import { DepuracionService }                                from 'src/app/services/depuracion.service';
import { ToastService }                                     from 'src/app/services/toast.service';
import { bbddUsuariosService }                              from 'src/app/services/bbdd-usuarios.service'
import { environment, modelos_de_datos, slidemenu_options } from 'src/environments/environment';
import { PopoverBorrarUsuarioComponent }                    from 'src/app/components/popovers/popover-borrar-usuario/popover-borrar-usuario.component';  



@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.page.html',
  styleUrls: ['./usuario.page.scss'],
})
export class UsuarioPage implements OnInit {

    prmtr_usuario:any;
    prmtr_Proposito_Seleccionar_Area: string;
    title_usuario:any; //para guardar la información que muestro en el title sin modificar prmtr_usuario ya que si 
                       //doy de alta 2 veces un usuario nuevo la segunda dicha información modificada de prmtr_usuario
                       //apareceria modificada en el formulario
    usuario: any;

    slidemenu_option_elegida: any;

    formGroup: FormGroup;
    //se_ha_intentado_guardar: boolean; //para controlar si muesetro o no posibles mensajes de error

    data_prueba_ngif = 'dentro de usuario';

    constructor(
        private DepuracionService: DepuracionService
        , private GeneralService: GeneralService
        , private ModalController: ModalController
        , private NavParams: NavParams
        , private Router: Router
        , public FormBuilder: FormBuilder
        , public PopoverController: PopoverController
        , private bbddUsuariosService: bbddUsuariosService
        , private ToastService: ToastService
        ) { 
            //OBSOLETO...
            /*
            if (this.Router.getCurrentNavigation().extras.state) {
                const state = this.Router.getCurrentNavigation().extras.state;
                this.usuario = state.prmtr_usuario ? JSON.parse(state.prmtr_usuario) : '';
                }
            */

            //ya que es una ventan modal y se hace con...
            this.prmtr_usuario = this.NavParams.get('prmtr_usuario');
            this.prmtr_Proposito_Seleccionar_Area = this.NavParams.get('prmtr_Proposito_Seleccionar_Area');
            this.slidemenu_option_elegida = slidemenu_options.find(element => element.id === this.prmtr_Proposito_Seleccionar_Area);
            //this.title_usuario = this.NavParams.get('prmtr_usuario');
            this.title_usuario = {...this.prmtr_usuario}; //para "clonar" el objeto y que sea uno nuevo
            this.DepuracionService.console_log ("this.prmtr_usuario", this.prmtr_usuario, "ver usuario")
            this.usuario = this.prmtr_usuario; // este se recarga dentro de Cargar_y_Formatear_Usuario > this.bbddUsuariosService.getUsuarios
                                               // pero lo inicializamos aqui  para que el Configurar_Datos_y_Validacion_Formulario no casque
                                               // ya que si tiene que esperar a eso no va tal como está ahora.
                                               // PERO esto no vale cuando estamos visualizando un usuario a través de la lista de TODOS LOS USUARIOS
                                               // Lo soluciono poniendo
                                               //       this.Configurar_Datos_y_Validacion_Formulario ();
                                               // dentro de 
                                               //       this.Cargar_y_Formatear_Usuario(this.prmtr_usuario)
                                               // asi que 
                                               //       this.usuario = this.prmtr_usuario
                                               // no creo que haga falta, aunque como no pide pan ahí se queda
            //en caso de que se haya pasado un usuario lo obtenermos de la base de datos
            //si estuviera vacio se supone que es porque estamos creando un usuario nuevo
            if (this.GeneralService.EstaVacia(this.usuario.COD_USUARIO)) {
                this.Configurar_Datos_y_Validacion_Formulario ();
                }
            else {
                this.Cargar_y_Formatear_Usuario(this.prmtr_usuario);
                }
            
            //this.Configurar_Datos_y_Validacion_Formulario ();

            }

    ngOnInit() {
        }

    close(prmtrDismiss) {
        this.ModalController.dismiss(prmtrDismiss);
        }

    Cargar_y_Formatear_Usuario(prmtrUsuario) {
        //aunque el mismo parametro prmtrUsuario tiene la información de usuario vuelvo a obtenerlo 
        //para evitar sorpresas
        this.bbddUsuariosService.getUsuarios(prmtrUsuario.COD_USUARIO, null, null, null).subscribe(result => {
            var array_data = result.data;               
            
            /*
            var array_data = array_data.map(function(item, index){ 
                return item;
                }, this);    
            */

            this.usuario = array_data[0];
            this.DepuracionService.console_log ("this.usuario", this.usuario, "ver usuario")
            //ahora que ya se ha cargado se puede cargar en el formulario y definir su validación
            this.Configurar_Datos_y_Validacion_Formulario ();
            });
        }




    Guardar_Usuario () {
        //this.se_ha_intentado_guardar = true;
        this.DepuracionService.console_log ("this.prmtr_usuario", this.prmtr_usuario, "ver usuario")
        this.DepuracionService.console_log ("this.usuario", this.usuario, "ver usuario")
        this.DepuracionService.console_log ("this.formGroup.value", this.formGroup.value, "ver usuario")
        //this.formGroup.value
        //this.formGroup.controls.Nombre.value
        this.bbddUsuariosService.saveUsuario(this.usuario, this.formGroup.value).subscribe(result => {
            this.DepuracionService.console_log("guardar_usuario > result", result, "ver usuario");
            if (result.resultado == "OK") {
                //para actualizar el header a los nuevos datos y por lo que se ve tb en 
                //el listado de usuarios de este area
                this.title_usuario.NOMBRE_USUARIO = result.data_OUT.campos[0].NOMBRE_USUARIO
                this.title_usuario.APELLIDO1_USUARIO = result.data_OUT.campos[0].APELLIDO1_USUARIO
                this.title_usuario.APELLIDO2_USUARIO = result.data_OUT.campos[0].APELLIDO2_USUARIO
                this.ToastService.show("El usuario ha sido guardado correctamente")
                this.close({actualizar_listado_usuarios: true})//inidico que se recargen el listado de usuarios
                this.usuario = modelos_de_datos.usuario; //reseteo el usuario por si vuelve a crearse un usuario que esté vacio.
                }
            else {
                this.ToastService.show("Se ha producido un error al guardar el usuario.")
                }
            
            });


        }


    // para que sea mas intuitivo en el html hacer los condicionales que muestran los errores en los inputs...
    get input() { return this.formGroup.controls; }
    Configurar_Datos_y_Validacion_Formulario () {
        //this.se_ha_intentado_guardar = false;
        //var pattern_nombreapellido = "[a-zA-ZñÑáéíóú ]*";
        //this.DepuracionService.console_log("pattern var" + pattern_nombreapellido, null, "ver usuario")
        /*
        var usuario = modelos_de_datos.usuario;
        if (!this.GeneralService.EstaVacia(this.usuario)) {
            usuario = this.usuario
            }
        */

        this.formGroup = this.FormBuilder.group({
                                            Nombre:     [this.usuario.NOMBRE_USUARIO
                                                        , Validators.compose([
                                                                            Validators.required,
                                                                            Validators.minLength(3),
                                                                            Validators.maxLength(100),
                                                                            Validators.pattern(environment.patterns.nombre_apellido),
                                                                            ])
                                                            ],
                                            Apellido1:  [this.usuario.APELLIDO1_USUARIO
                                                        , Validators.compose([
                                                                            Validators.required,
                                                                            Validators.minLength(2),
                                                                            Validators.maxLength(100),
                                                                            Validators.pattern(environment.patterns.nombre_apellido),
                                                                            ])
                                                            ],
                                            Apellido2:  [this.usuario.APELLIDO2_USUARIO
                                                        , Validators.compose([
                                                                            Validators.required,
                                                                            Validators.minLength(2),
                                                                            Validators.maxLength(100),
                                                                            Validators.pattern(environment.patterns.nombre_apellido),
                                                                            ])
                                                            ],
                                            Telefono:   [this.usuario.TELEFONO_USUARIO
                                                        , Validators.compose([
                                                                            Validators.required,
                                                                            Validators.minLength(9),
                                                                            Validators.maxLength(15),
                                                                            Validators.pattern(environment.patterns.telefono),
                                                                            ])
                                                            ],
                                            Email:      [this.usuario.EMAIL_USUARIO
                                                        , Validators.compose([
                                                                            Validators.required,
                                                                            Validators.minLength(8), //lo comento para hacer pruebas
                                                                            Validators.maxLength(100),
                                                                            Validators.pattern(environment.patterns.email), //lo comento para hacer pruebas
                                                                            ])
                                                            ],
                                            Password:   [this.usuario.CLAVE_USUARIO
                                                        , Validators.compose([
                                                                            Validators.required,
                                                                            Validators.minLength(4),
                                                                            Validators.maxLength(10),
                                                                            Validators.pattern(environment.patterns.password),
                                                                            ])
                                                            ],
                                
                                            });

        //this.DepuracionService.console_log ("this.formGroup.controls.Nombre.errors", this.formGroup.controls.Nombre.errors, "ver usuario")
                /*
                formControl_Titulo: [
                                    "",
                                    Validators.compose([
                                        Validators.minLength(4),
                                        Validators.maxLength(30),
                                        Validators.pattern("[0-9a-z-A-Z-_]*"),
                                        Validators.required
                                        ])
                                 ]
                , */
        }


    async AbrirPopover_Validacion_Borrar_Usuario (event: any) {
        //this.DepuracionService.console_log("prmtr_usuario", this.prmtr_usuario, "borrar usuario")
        const popover = await this.PopoverController.create({
            component: PopoverBorrarUsuarioComponent,
            //cssClass: 'my-custom-class',
            event: event,
            //translucent: true, //lo comento para evitar que el popover sea transparente
            //para que aparezca con flecha y posicionado donde he pulsado en la pantalla y no en el centro...
            //...https://www.youtube.com/watch?v=_roU5wAyaug
            //mode: 'ios', //para que muestre una flecha pero el trasnparente del fondo es menos acuciado
            //backdropDismiss: false //para que obligue a interactuar con el popover si se quiere cerrar
            componentProps: {
                'prmtr_usuario': this.prmtr_usuario
                }
            });

        popover.onDidDismiss().then((result) => {
            this.DepuracionService.console_log("popover.onDidDismiss > result", result, "borrar usuario")
            //si se ha borrado el usuario 
            // - cierro la ventana modal del usuario
            // - muestro un toast
            // - mando lo necesario para que la pagina de usuarios se recarge
            if (!this.GeneralService.EstaVacia(result.data)) {
                if (result.data.resultado == "OK") {
                    // - cierro la ventana modal del usuario y mando lo necesario para que la pagina de usuarios se recarge
                    //NOTA: hay que devolver result.data y no result como pensaba inicialmente porque lo encapsula dentro de data a su vez
                    this.ModalController.dismiss(result.data); //propago hasta la pagina de usuarios lo devuelto por el popup
                                                               //que al tener la propiedad "actualizar_listado_usuarios: true" provoca que se recargen los usuarios.
                    // - muestro un toast
                    let str_mensaje = "El usuario '" 
                                    + result.data.usuario_borrado.NOMBRE_USUARIO + " " + result.data.usuario_borrado.APELLIDO1_USUARIO 
                                    + "' ha sido borrado correctamente"
                    this.ToastService.show(str_mensaje);
                    }
                }

            
            });

        return await popover.present();
        }




}
