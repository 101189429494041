import { Component, OnInit }                            from '@angular/core';
import { ModalController}                               from '@ionic/angular';
import { environment, slidemenu_options }               from 'src/environments/environment';
import { GeneralService }                               from 'src/app/services/general.service';
import { DepuracionService }                            from 'src/app/services/depuracion.service';
//import { AppComponent }                                 from 'src/app/app.component';

import { SeleccionarAreaPage }          from 'src/app/pages/seleccionar-area/seleccionar-area.page';

@Component({
  selector: 'app-informes',
  templateUrl: './informes.page.html',
  styleUrls: ['./informes.page.scss'],
})
export class InformesPage implements OnInit {

    slidemenu_option_elegida: any;

    constructor(private ModalController: ModalController
                , private DepuracionService: DepuracionService
                , private GeneralService: GeneralService
                //, private AppComponent: AppComponent
                ) { 

        //para buscar un elemento de un array por una propiedad de los objetos de dicho elemento, encontrado en...
        //...https://www.codegrepper.com/code-examples/elixir/typescript+search+array+of+objects
        this.slidemenu_option_elegida = slidemenu_options.find(element => element.id === "informes");
        }

    ngOnInit() {
        }

    close() {
        this.ModalController.dismiss();
        }

    Abrir_Informe (prmtrTipoInforme) {
        this.DepuracionService.console_log ("Abrir_Informe(...)", prmtrTipoInforme, "ver informes");
        
        if (prmtrTipoInforme == "situación actual") {
            //*NOTA evitar Circular dependency detected
            this.GeneralService.AbrirModal_Seleccionar_Area(SeleccionarAreaPage, 'informe situación actual');
            }
        if (prmtrTipoInforme == "computo de horas") {
            //*NOTA evitar Circular dependency detected
            this.GeneralService.AbrirModal_Seleccionar_Area(SeleccionarAreaPage, 'informe computo de horas');
            }
        
        }

    }
